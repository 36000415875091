const _ = require('lodash');
const ServerConfig = require('../ServerConfig');
const {isWebGL2Supported} = require('./WebglSupport');
const Options = require('../Options');
const ApplicationConfig = require('../app/ApplicationConfig');

const ZONE_POLYGON_COLOR = '#ffb82f';
const POLYGON_PROPERTIES = {
    'fill-color': ZONE_POLYGON_COLOR,
    'fill-opacity': 0.3,
    'line-width': 2,
    'line-color': ZONE_POLYGON_COLOR,
};

let isWebGL2SupportedCache;

const MAX_EXTENT = 20037508.34;
const M_TO_DEG = 180 / MAX_EXTENT;
const DEG_TO_M = MAX_EXTENT / 180;
const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;
const mercatorProjection = {
    convertLat900913To4326(lat) {
        lat = lat * M_TO_DEG;
        return 2 * Math.atan(Math.exp(lat * DEG_TO_RAD)) * RAD_TO_DEG - 90;
    },
    convertLon900913To4326(lon) {
        return lon * M_TO_DEG;
    },
    convertLon4326To900913(lon) {
        return lon * DEG_TO_M;
    },
    convertLat4326To900913(lat) {
        lat = Math.log(Math.tan((90 + lat) * DEG_TO_RAD / 2)) * RAD_TO_DEG;
        lat = Math.max(-MAX_EXTENT, Math.min(MAX_EXTENT, lat));
        return lat * DEG_TO_M;
    },
    convertLonLat4326To900913(lon, lat) {
        const originShift = 2 * Math.PI * 6378137 / 2.0;
        const x = lon * originShift / 180.0;
        let y = Math.log(Math.tan((90 + lat) * Math.PI / 360.0)) / (Math.PI / 180.0);
        y = y * originShift / 180.0;
        return {lon: x, lat: y};
    },
};

module.exports = {
    isKartoEnabled,
    getBoundsZoomLevel,
    addZonePolygons,
    calculateCenterFromBounds,
    convertLatLngToF4Format,
    computeDistanceBetweenTwoPoints,
    removeDuplicateVertices,
    getFirstPathnameParam,
    forceKartoToBeDisabled,
    mercatorProjection,
    POLYGON_PROPERTIES,
};

let prevPathNameParam;
let disableKarto = false;

/**
 * Force Karto to be disabled
 */
function forceKartoToBeDisabled(disable) {
    disableKarto = disable;
}

/**
 * Checks if Karto is enabled for the given page.
 *
 * @param {string} page - The page to check.
 * @returns {boolean} True if Karto is enabled, false otherwise.
 */
function isKartoEnabled(page) {
    const enabledPages = ServerConfig.config.enabledPagesForKarto;
    if (isWebGL2SupportedCache === undefined) {
        isWebGL2SupportedCache = isWebGL2Supported();
    }
    return !disableKarto && _.includes(enabledPages, page) && searchResultsPageExtraVerification(page) && isWebGL2SupportedCache
        && typeof globalThis !== 'undefined' && typeof Promise.allSettled == 'function'
        && Options.get('useKartoIfAvailable');
}

/**
 * Calculates the zoom level required to fit the given bounds on the map.
 *
 * @param {object} map - The map object.
 * @param {object} bounds - The bounds to fit.
 * @returns {number} The zoom level.
 */
function getBoundsZoomLevel(map, bounds) {
    return Math.floor(map.cameraForBounds(bounds).zoom);
}

/**
 * Adds zone polygons to the map.
 *
 * @param {object} map - The map object.
 * @param {object} zone - The zone containing the geometry coordinates.
 */
function addZonePolygons(map, zone) {
    const coordinates = _.get(zone, 'geometry.coordinates', []);
    const type = coordinates.length > 1 ? 'MultiPolygon' : 'Polygon';
    addFeature(map, type, coordinates, POLYGON_PROPERTIES);
}

/**
 * Adds a feature to the map.
 *
 * @param {object} map - The map object.
 * @param {string} type - The type of the feature ('Polygon' or 'MultiPolygon').
 * @param {Array} coordinates - The coordinates of the feature.
 * @param {object} properties - The properties of the feature.
 */
function addFeature(map, type, coordinates, properties) {
    if (map.isInitialized()) {
        const shapeGroup = map.createShapeGroup({position: kartoEngine.LayerPosition.BELOW_3D_POINTS_AND_MARKERS});
        shapeGroup.addFeature(new kartoEngine.Feature({
            type: 'Feature',
            geometry: {
                type,
                coordinates,
            },
            properties,
        }));
    }
}

/**
 * Calculates the center point from the given bounds.
 *
 * @param {number} west - The westernmost point.
 * @param {number} south - The southernmost point.
 * @param {number} east - The easternmost point.
 * @param {number} north - The northernmost point.
 * @returns {Array<number>} The center point as [longitude, latitude].
 */
function calculateCenterFromBounds(west, south, east, north) {
    const lat = (south + north) / 2;
    const lng = (west + east) / 2;
    return [lng, lat];
}

/**
 * Converts latitude and longitude to F4 format.
 *
 * @param {number} lat - The latitude.
 * @param {number} lng - The longitude.
 * @returns {object} The F4 format object with methods to get latitude and longitude.
 */
function convertLatLngToF4Format(lat, lng) {
    return {
        _lat: lat,
        _lng: lng,
        lat() {
            return this._lat;
        },
        lng() {
            return this._lng;
        },
    };
}

/**
 * Converts degrees to radians.
 *
 * @param {number} deg - The degrees to convert.
 * @returns {number} The radians.
 */
function toRad(deg) {
    return deg * (Math.PI / 180);
}

/**
 * Computes the distance between two points on the Earth's surface.
 *
 * @param {object} from - The starting point with `lat` and `lng` properties.
 * @param {object} to - The ending point with `lat` and `lng` properties.
 * @param {number} [radius=6378137] - The radius of the Earth in meters.
 * @returns {number} The distance between the points in meters.
 */
function computeDistanceBetweenTwoPoints(from, to, radius) {
    const radFromLat = toRad(from.lat);
    const radFromLng = toRad(from.lng);
    const radToLat = toRad(to.lat);
    const radToLng = toRad(to.lng);
    radius = radius || 6378137;
    return radius * (
        2 * Math.asin(
            Math.sqrt(
                Math.pow(Math.sin((radFromLat - radToLat) / 2), 2) +
                    Math.cos(radFromLat) *
                    Math.cos(radToLat) *
                    Math.pow(Math.sin((radFromLng - radToLng) / 2), 2)
            )
        )
    );
}

/**
 * Remove duplicate vertices from a GeoJSON Polygon or MultiPolygon object.
 *
 * @param {Object} geoJson - A GeoJSON Polygon or MultiPolygon object.
 * @returns {Object} - The GeoJSON object without duplicate vertices.
 */
function removeDuplicateVertices(geoJson) {
    if (geoJson.type !== 'Polygon' && geoJson.type !== 'MultiPolygon') {
        throw new Error('Input must be a GeoJSON Polygon or MultiPolygon object.');
    }

    if (geoJson.type === 'Polygon') {
        geoJson.coordinates = removeDuplicatesFromPolygon(geoJson.coordinates);
    } else if (geoJson.type === 'MultiPolygon') {
        geoJson.coordinates = geoJson.coordinates.map(polygon => removeDuplicatesFromPolygon(polygon));
    }

    return geoJson;
}

/**
 * Remove duplicate vertices from a polygon's coordinates.
 *
 * @param {Array} coordinates - The coordinates of the polygon.
 * @returns {Array} - The coordinates with duplicate vertices removed.
 */
function removeDuplicatesFromPolygon(coordinates) {
    return coordinates.map(ring => {
        const uniqueRing = [];
        const seen = new Set();
        ring.forEach(point => {
            const key = point.join(',');
            if (!seen.has(key)) {
                uniqueRing.push(point);
                seen.add(key);
            }
        });
        // Ensure the ring is closed by making the last vertex equal to the first vertex
        if (uniqueRing.length && !arraysEqual(uniqueRing[0], uniqueRing[uniqueRing.length - 1])) {
            uniqueRing.push(uniqueRing[0]);
        }
        return uniqueRing;
    });
}

/**
 * Check if two arrays are equal.
 *
 * @param {Array} arr1 - First array.
 * @param {Array} arr2 - Second array.
 * @returns {boolean} - True if arrays are equal, otherwise false.
 */
function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

/**
 * Retrieves the first parameter from the current URL pathname.
 * @returns {string} The first parameter of the pathname or an empty string if no parameters exist.
 */
function getFirstPathnameParam() {
    const pathname = window.location.pathname;
    const params = pathname.split('/').filter(param => param.length > 0);
    return params.length > 0 ? params[0] : '';
}

/**
 * Checks if the first parameter of the pathname is "annonce" and if a previous pathname parameter starts with any of the defined pages.
 * @returns {boolean} True if the conditions are met, false otherwise.
 */
function containsPages() {
    const pages = ['annonces-', 'modifier-une-annonce'];
    const firstPathnameParam = getFirstPathnameParam();
    const pathname = window.location.pathname;
    const checkCondition = param => _.some(pages, page => _.startsWith(param, page));
    const allowedPathnames = ['annonce', 'suggestion'];
    const allowedSubPaths = ['temps-de-transport-', 'dessin-', 'cercle-'];
    if (_.includes(allowedPathnames, firstPathnameParam) && checkCondition(prevPathNameParam)) {
        return true;
    }
    if (_.some(allowedSubPaths, keyword => _.includes(pathname, keyword) && checkCondition(prevPathNameParam))) {
        return true;
    }
    prevPathNameParam = firstPathnameParam;
    return checkCondition(firstPathnameParam);
}

/**
 * Performs extra verification for the search results page.
 * @param {string} page - The name of the page to verify.
 * @returns {boolean} True if the page is valid and passes extra verification, false otherwise.
 */
function searchResultsPageExtraVerification(page) {
    if (page === 'searchResultsPage') {
        return ApplicationConfig.applicationPro || containsPages();
    } else {
        return true;
    }
}
