const template = require('./ContactLink.jade');

module.exports = function (url) {
    // @vue/component
    return {
        computed: {
            url() {
                return url;
            },
        },
        template: template(),
    };
};
