const PhoneNumberFormatter = require('@bienici/phone-number-formatter');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');
const {isFiltered} = require('./filteredFields');

module.exports = {
    formatPhoneToCall,
    getPhoneNumberToDisplay,
};

//phoneToCall is with +33 instead of 0
function formatPhoneToCall(rawPhoneNumber) {
    const phoneNumberFormatter = new PhoneNumberFormatter(rawPhoneNumber);
    if (UserAgentHelper.shouldEnableFeatureForIos('phoneNumberToCallWithSpace')) {
        return phoneNumberFormatter.phoneNumberToCall;
    } else {
        return phoneNumberFormatter.internationalPhoneNumber;
    }
}

function getPhoneNumberToDisplay(phoneToFormat) {
    if (!isFiltered(phoneToFormat)) {
        phoneToFormat = new PhoneNumberFormatter(phoneToFormat).phoneNumberToDisplay;
    }
    return phoneToFormat;
}
