const _ = require('lodash');
const ContactAdOverview = require('./AdOverview');
const ContactBlockWithTitle = require('./BlockWithTitle');
const ContactCall = require('./Call');
const ContactText = require('./ContactText');
const RentalApplicationDownloadApplicationContainer = require('../../rentalApplication/components/DownloadApplicationContainer');
const SenderActions = require('./SenderActions');
const SenderDetails = require('./SenderDetails');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./ContactDetails.jade');
const {hasRole, isAgency} = require('../../authentication/Account');

const INTENTION_KEYS = ['isInvestmentProject', 'hasPropertyToSell'];

// @vue/component
module.exports = {
    components: {
        ContactAdOverview,
        ContactBlockWithTitle,
        ContactCall,
        ContactText,
        RentalApplicationDownloadApplicationContainer,
        SenderActions,
        SenderDetails,
    },
    mixins: [
        i18nMixin({
            prefix: 'ContactDetails.',
            keys: [
                'messageTitle',
                'rentalApplicationTitle',
                'additionalInformationTitle',
            ],
        }),
    ],
    constants: {
        SENDER_ACTION_TYPES: ['applications', 'views'],
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
        displayAdDetails: Boolean,
    },
    computed: {
        realEstateAd() {
            return this.contact.realEstateAd;
        },
        isCall() {
            return this.contact.type == 'calls';
        },
        message() {
            return this.contact.message;
        },
        additionalInformationMessage() {
            const context = _(this.contact).pick(INTENTION_KEYS).pickBy().keys().first();
            return this.t('additionalInformation', {context});
        },
        hasAdditionalInformation() {
            const {contact} = this;
            const additionalInfoType = _(contact).pick(INTENTION_KEYS).pickBy().keys().first();
            if (additionalInfoType === 'hasPropertyToSell') {
                return contact.hasPropertyToSell &&
                    _.get(contact, 'fromAgencyWithEnabledHasPropertyToSellOption', true);
            } else {
                return contact[additionalInfoType];
            }
        },
        displayRealEstateAd() {
            const {displayAdDetails, realEstateAd} = this;
            return displayAdDetails && realEstateAd;
        },
        canSeeRentalApplicationDownload() {
            return (hasRole('adContactDossierViewer') || isAgency()) && this.contact.rentalApplication;
        },
    },
    template: template(),
};
