const _ = require('lodash');
const template = require('./RentalApplicationManagement.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Application = require('../Application');
const GoogleTagManager = require('../../stats/GoogleTagManager');
const {getDossierLabel} = require('./helper');
const Views = require('../../views/Views');
const TRANSLATIONS_PREFIX = 'RentalApplicationManagement';

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: `${TRANSLATIONS_PREFIX}.`,
            keys: [
                'gettingDossiers',
                'defaultErrorMessage',
                'deleteDossiersDescription',
                'modalDeleteOneTitle',
                'modalAllOneTitle',
                'modalDescription',
                'confirmMessage',
                'deleteOneDossier',
                'deleteAllDossiers',
                'modalAllDossierTitle',
                'modalDeleteOneDossierTitle',
                'retryButton',
                'emptyDossiers',
            ],
        }),
    ],
    props: {
        dossiers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            dossiersManagement: this.dossiers,
            dossiersToDelete: [],
            modalDisplayed: false,
            errorTranslationKey: undefined,
        };
    },
    computed: {
        modalTitle() {
            return this.dossiersToDelete.length > 1 ?
                this.modalDeleteOneDossierTitle : this.modalAllDossierTitle;
        },
        hasRentalApplications() {
            return this.dossiersManagement.length > 0;
        },
        selectedDossierToDelete() {
            return _.some(this.dossiersManagement, {checked: true});
        },
    },
    methods: {
        getDossierLabel(dossier) {
            return getDossierLabel(dossier);
        },
        deleteDossiers(deleteAllDossiers = false) {
            if (deleteAllDossiers) {
                this.dossiersManagement = _.map(this.dossiersManagement, dossier =>
                    _.assign({}, dossier, {checked: true}));
            }
            this.dossiersToDelete = _.map(_.filter(this.dossiersManagement, 'checked'), 'id');
            this.modalDisplayed = true;
        },
        deleteRentalApplication(cb) {
            this.errorTranslationKey = undefined;
            Application.deleteRentalApplication(
                this.dossiersToDelete,
                (err, res) => {
                    if (err || !res.success) {
                        this.errorTranslationKey = `${TRANSLATIONS_PREFIX}.defaultErrorMessage`;
                    } else {
                        this.modalDisplayed = false;
                        this.dossiersManagement = _.reject(this.dossiersManagement, (dossier) => {
                            return _.includes(this.dossiersToDelete, dossier.id);
                        });
                        Views.volatileFeedback.showSuccess(`${TRANSLATIONS_PREFIX}.deletedDossiersSuccess`);
                        GoogleTagManager.sendEvent('rentalApplicationDeleted');
                    }
                    cb(err);
                }
            );
        },
        onCloseModal() {
            this.dossiersToDelete = [];
        },
    },
    template: template(),
};
