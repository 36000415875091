const template = require('./SimilarAds.jade');
const i18nMixin = require('../../../vue/components/mixins/i18n');
const Account = require('../../../authentication/Account');
const ApplicationConfig = require('../../../app/ApplicationConfig');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'SimilarAds.',
            keys: [
                'backToSearch',
            ],
        }),
    ],
    props: {
        similarAds: {
            type: Array,
            default: () => [],
        },
        closePageUrl: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        title() {
            return this.t('similarAdsTitle', {count: this.similarAds.length});
        },
        hasSimilarAds() {
            return this.similarAds.length > 0;
        },
        disableAlreadySeen() {
            return ApplicationConfig.disableAlreadySeen || Account.isShowRoom();
        },
        enableLastContactDateTag() {
            return ApplicationConfig.enableLastContactDateTag;
        },
    },
    template: template(),
};
