const _ = require('lodash');
const LocalExpertBanner = require('../../agency/components/LocalExpertBanner');
const LocalBanner = require('../../agency/components/LocalBanner');
const DateRangePicker = require('../../form/components/DateRangePicker');
const BaseStat = require('./BaseStat');
const template = require('./LocalBannersStats.jade');
const StatsHelper = require('../StatsHelper');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {STATS_COLORS_PALETTE} = require('../StatsConfig');
const {searchAgencyForLocalExpertBanner} = require('../../agency/LocalBannersHelper');
const MomentValidator = require('../../vue/validators/Moment');

// @vue/component
module.exports = {
    components: {
        LocalExpertBanner,
        LocalBanner,
        DateRangePicker,
        BaseStat,
    },
    mixins: [
        i18nMixin({
            prefix: 'LocalBannersStats.',
            keys: [
                'errorMessage',
                'bannerSelectionLabel',
                'analysisPeriodLabel',
                {
                    'bannerFooter.prefix': 'bannerFooterPrefix',
                    'bannerFooter.suffix': 'bannerFooterSuffix',
                },
            ],
        }),
    ],
    constants: {
        STATS_MODE: 'dashboard-pro',
        FILTER_TYPE: ['buy'],
    },
    props: {
        agency: {
            type: Object,
            required: true,
        },
        proView: Boolean,
        startDate: {
            type: Object,
            required: false,
            default: null,
            validator: MomentValidator,
        },
        endDate: {
            type: Object,
            required: false,
            default: null,
            validator: MomentValidator,
        },
    },
    data() {
        return {
            collapsedBlock: false,
            selectedPeriod: StatsHelper.getDefaultPeriod(),
            isLoading: true,
            statsError: false,
            localBannersOptions: [],
            selectedBannerTheme: null,
            localBannersStats: null,
            agencyForLocalExpertBanner: null,
        };
    },
    computed: {
        hasLocalExpertStats() {
            const {agency: {localFameBanner, isExternalAdvertisementEnabled}} = this;
            return localFameBanner || isExternalAdvertisementEnabled;
        },
        hasLocalBannersStats() {
            const {agency: {proOptions}} = this;
            const localBannersConfiguration = _.get(proOptions, 'localBannersConfiguration', {});
            return localBannersConfiguration.enabled && !_.isEmpty(localBannersConfiguration.banners);
        },
        showLocalBannersStats() {
            return this.hasLocalExpertStats || this.hasLocalBannersStats;
        },
        showLocalBannerDisplay() {
            const {isExpert, agencyForLocalExpertBanner, agency, selectedBannnerConfiguration} = this;
            return (isExpert && agencyForLocalExpertBanner) || (!isExpert && agency && selectedBannnerConfiguration);
        },
        isExpert() {
            return this.selectedBannerTheme === 'expert';
        },
        stats() {
            const {
                selectedBannerStats: {
                    internalPrints: {total: internalValue},
                    adplorerPrints: {total: externalValue},
                },
                isExpert,
                t,
            } = this;
            const statsArray = [{
                name: 'localExpertInternalDisplay.total',
                value: internalValue || 0,
                color: STATS_COLORS_PALETTE.blue,
                label: t('localBannersStats.label', {count: internalValue}),
                description: t('localBannersStats.internalDataDescription'),
            }];
            if (isExpert) {
                statsArray.push({
                    name: 'localExpertExternalDisplay.total',
                    value: externalValue || 0,
                    color: STATS_COLORS_PALETTE.red,
                    label: t('localBannersStats.label', {count: externalValue}),
                    description: t('localBannersStats.externalDataDescription'),
                });
            }
            return statsArray;
        },
        totalStats() {
            const {
                selectedBannerStats: {
                    internalPrints: {total: internalValue},
                    adplorerPrints: {total: externalValue},
                },
                isExpert,
                t,
            } = this;
            const totalStatsCount = internalValue + externalValue;
            const totalLabelSuffix = isExpert ? 'totalDisplayNumberLabel' : 'displayNumberLabel';
            return {label: t('localBannersStats.' + totalLabelSuffix, {count: totalStatsCount})};
        },
        title() {
            const {proView, t} = this;
            const context = proView ? 'pro' : '';
            return t('localBannersStats.title', {context});
        },
        selectedBannnerConfiguration() {
            const {agency, selectedBannerTheme} = this;
            const localBannersConfiguration = _.get(agency, 'proOptions.localBannersConfiguration.banners', []);
            return _.find(localBannersConfiguration, {theme: selectedBannerTheme});
        },
        bannerFooterText() {
            return this.bannerFooterPrefix + this.t('bannerFooter.' + this.selectedBannerTheme) + this.bannerFooterSuffix;
        },
        selectedBannerStats() {
            const {localBannersStats, selectedBannerTheme} = this;
            return {
                internalPrints: _.get(localBannersStats, ['internalPrints', selectedBannerTheme], 0),
                adplorerPrints: _.get(localBannersStats, 'adplorerPrints', 0),
            };
        },
    },
    watch: {
        startDate() {
            this.periodChanged();
        },
        endDate() {
            this.periodChanged();
        },
        agency() {
            if (this.showLocalBannersStats) {
                this.loadData();
            }
        },
    },
    mounted() {
        if (this.showLocalBannersStats) {
            this.loadData();
        }
    },
    methods: {
        loadData() {
            const {agency, FILTER_TYPE, hasLocalExpertStats} = this;
            this.isLoading = true;
            this.initLocalBannersOptions();
            this.fetchStats((err, stats) => {
                this.isLoading = false;
                this.statsError = false;
                if (err) {
                    this.statsError = true;
                } else {
                    this.localBannersStats = stats;
                }
            });
            if (hasLocalExpertStats) {
                searchAgencyForLocalExpertBanner({
                    agencyId: agency.id,
                    filterType: FILTER_TYPE,
                    disableCalltracking: true,
                }, (err, agencyForLocalExpertBanner) => {
                    if (err || !agencyForLocalExpertBanner) {
                        console.error('[LocalBannersStats] An error occurred while fetching agency for local expert banner: ',
                            err || 'No agency found');
                    } else {
                        this.agencyForLocalExpertBanner = agencyForLocalExpertBanner;
                    }
                });
            }
        },
        periodChanged(newVal) {
            if (!this.proView) {
                this.selectedPeriod.startDate = this.startDate;
                this.selectedPeriod.endDate = this.endDate;
            } else {
                this.selectedPeriod = newVal;
            }
            this.isLoading = true;
            this.statsError = false;
            this.fetchStats((err, stats) => {
                this.isLoading = false;
                if (err) {
                    this.statsError = true;
                } else {
                    this.localBannersStats = stats;
                }
            });
        },
        fetchStats(cb) {
            const {agency: {id}, selectedPeriod: {startDate, endDate}} = this;
            const data = {
                accountId: id,
                dateFrom: startDate.toISOString(),
                dateTo: endDate.toISOString(),
            };
            StatsHelper.getLocalBannersStats(data, cb);
        },
        initLocalBannersOptions() {
            const {hasLocalExpertStats, hasLocalBannersStats, agency: {proOptions}, t} = this;
            const localBannersOptions = [];
            if (hasLocalExpertStats) {
                localBannersOptions.push({label: t('themes.expert'), value: 'expert'});
            }
            if (hasLocalBannersStats) {
                const localBannersConfiguration = _.get(proOptions, 'localBannersConfiguration.banners');
                localBannersOptions.push(...(_.map(localBannersConfiguration, ({theme: value}) => ({
                    label: t('themes.' + value),
                    value,
                }))));
            }
            this.localBannersOptions = localBannersOptions;
            this.selectedBannerTheme = _.get(_.first(localBannersOptions), 'value');
        },
    },
    template: template(),
};
