const ContactBlockWithTitle = require('./BlockWithTitle');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ContactText = require('./ContactText');
const ContactPhone = require('./ContactPhone');
const ContactEmail = require('./ContactEmail');
const template = require('./SenderDetails.jade');

// @vue/component
module.exports = {
    components: {
        ContactBlockWithTitle,
        ContactText,
        ContactPhone,
        ContactEmail,
    },
    mixins: [
        i18nMixin({
            prefix: 'SenderDetails.',
            keys: [
                'title',
                'phoneTitle',
                'emailTitle',
            ],
        }),
    ],
    props: {
        name: {
            type: [String, Object],
            required: true,
        },
        sender: {
            type: Object,
            required: true,
        },
    },
    computed: {
        phone() {
            return this.sender.phone;
        },
        phoneUrl() {
            return `tel:${this.phone}`;
        },
        email() {
            return this.sender.email;
        },
        emailUrl() {
            return `mailto:${this.email}`;
        },

    },
    template: template(),
};
