const _ = require('lodash');
const async = require('async');
const Account = require('../authentication/Account');
const {resourceUrl} = require('fack');
const {getEncodeLimitFromBoundsForKarto} = require('../LimitHelper');
const {IMPORT_ACCOUNT_TYPE, NETWORK_ACCOUNT_TYPE} = require('../../common/AccountTypes');

function getOptionsForPoiAgencyRequest(tile, accountFilterAgency) {
    const nw = {lat: tile.bounds.getNorthWest().lat, lng: tile.bounds.getNorthWest().lng};
    const se = {lat: tile.bounds.getSouthEast().lat, lng: tile.bounds.getSouthEast().lng};
    const ne = {lat: tile.bounds.getNorthEast().lat, lng: tile.bounds.getNorthEast().lng};
    const sw = {lat: tile.bounds.getSouthWest().lat, lng: tile.bounds.getSouthWest().lng};
    const limit = getEncodeLimitFromBoundsForKarto(nw, ne, se, sw);
    const searchCriteria = {
        size: 1000,
        limit,
        'is-pro': true,
        closed: false,
        disabled: false,
        precision: ['rooftop', 'street'],
        visibleOnMap: true,
    };
    if (!Account.hasAnyRole()) {
        searchCriteria['max-age'] = 24 * 3600;
    }
    if (accountFilterAgency) {
        let nameQueryString;
        if (accountFilterAgency.accountType == IMPORT_ACCOUNT_TYPE) {
            nameQueryString = 'importAccountId';
        } else if (accountFilterAgency.accountType == NETWORK_ACCOUNT_TYPE) {
            nameQueryString = 'ownerIds';
        }
        if (nameQueryString) {
            searchCriteria.querystring = nameQueryString + ':"' + accountFilterAgency.id + '"';
        }
    }
    return searchCriteria;
}

function requestAgencyPois(searchCriteria, callback) {
    let xhrAccount = null;
    let xhrAdminAccount = null;
    let isAborted = false;
    async.waterfall([
        cb => {
            xhrAccount = Account.find(searchCriteria, cb);
        },
        (users, cb) => {
            xhrAdminAccount = Account.getRelatedAccounts(users.accounts, (err, relatedAccounts) => {
                users.relatedAccounts = relatedAccounts;
                cb(err, users);
            });
        },
    ], (err, results) => {
        if (!isAborted) {
            callback(err, results);
        }
    });
    return {
        abort: () => {
            isAborted = true;
            if (xhrAccount) {
                xhrAccount.abort();
            }
            if (xhrAdminAccount) {
                xhrAdminAccount.abort();
            }
        },
    };
}

class AgencyPoisHelper {
    /**
     *
     * @param {kartoEngine.Engine.Map} map
     */
    constructor(map) {
        if (AgencyPoisHelper.instance) {
            return AgencyPoisHelper.instance;
        }
        this.agenciesMarkers = [];
        this.customTilesData = null;
        this.map = map;
        this._accountFilterAgency = null;
        AgencyPoisHelper.instance = this;
    }

    _addProcessTiles(tiles) {
        for (const tile of tiles) {
            const tileStatus = tile.getStatus();
            const {map} = this;
            const removedStatuses = [
                kartoEngine.TileUtils.TileStatus.REMOVED,
                kartoEngine.TileUtils.TileStatus.CANCELED,
            ];
            if (_.includes(removedStatuses, tileStatus)) {
                continue;
            }
            const options = getOptionsForPoiAgencyRequest(tile);
            tile.userData.markers = [];
            tile.request = requestAgencyPois(options, (err, results) => {
                if (!err) {
                    if (_.includes(removedStatuses, tileStatus)) {
                        return;
                    }
                    results.accounts.forEach((agency) => {
                        const marker = map.createMarker3D(
                            {
                                position: [agency.position.lon, agency.position.lat],
                                altitude: 'auto',
                                altitudeOffset: 10,
                                scale: agency.highlightedOnMap ? {width: 1.75, height: 1.75} : null,
                                line: {
                                    enable: true,
                                },
                                icon: {
                                    url: resourceUrl('images/poi/agency.svg'),
                                    size: {height: 20, width: 20},
                                },
                                zIndex: 1,
                            }
                        );
                        const markerInfowindow = map.createInfoWindow({
                            opened: false,
                            closeButton: false,
                            maxWidth: '400px',
                            maxHeight: '750px',
                        });
                        marker.setInfoWindow(markerInfowindow);
                        marker.agencyInfo = agency;
                        marker.relatedAccounts = results.relatedAccounts;
                        tile.userData.markers.push(marker);
                        this.agenciesMarkers.push(marker);
                    });
                }
            });
        }
    }

    _removeProcessTile(tile) {
        tile.request.abort();
        tile.request = null;
        tile.userData.markers.forEach(marker => {
            _.remove(this.agenciesMarkers, currentMarker => currentMarker._id === marker._id);
            this.map.removeMarker3D(marker);
        });
    }

    displayAgencyPoisPerTile() {
        this.customTilesData = new kartoEngine.SimpleCustomTilesData({
            map: this.map,
            minZoom: 15,
            maxZoom: 21,
            maxTileDistance: 5,
            maxTilePerIteration: 20,
            addTilesProcessor: {
                processTiles: this._addProcessTiles.bind(this),
            },
            removeTilesProcessor: {
                processTile: this._removeProcessTile.bind(this),
            },
        });
        this.map.addCustomTilesData(this.customTilesData);
    }

    static setAccountFilterAgency(map, accountFilterAgency) {
        const agencyPoiInstance = AgencyPoisHelper.getInstance(map);
        if (!_.isEqual(agencyPoiInstance._accountFilterAgency, accountFilterAgency)) {
            agencyPoiInstance._accountFilterAgency = accountFilterAgency;
        }
    }

    static getInstance(map) {
        if (!AgencyPoisHelper.instance) {
            new AgencyPoisHelper(map);
        } else {
            AgencyPoisHelper.instance.map = map;
        }
        return AgencyPoisHelper.instance;
    }

    disableAgencyPois() {
        this.map.removeCustomTilesData(this.customTilesData);
        this.agenciesMarkers.forEach((marker) => {
            this.map.removeMarker3D(marker);
        });
    }
}

module.exports = AgencyPoisHelper;
