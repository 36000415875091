const EventEmitter = require('events');

class StorageManager {
    constructor(storageType) {
        this.storageType = storageType;
        this.emitter = new EventEmitter();
        this.parsers = {};
        this.setupStorageEventListener();
    }

    setValue(key, value) {
        try {
            if (this.isAvailable()) {
                const storage = this._getStorage();
                const currentStoredValue = storage.getItem(key);
                const newStoredValue = JSON.stringify(value);
                storage.setItem(key, newStoredValue);
                if (currentStoredValue !== newStoredValue) {
                    this.emitter.emit(key, value);
                }
            }
        } catch (e) {
            console.error(`Could not set item in ${this.storageType}`, e);
        }
    }

    getValue(key) {
        if (this.isAvailable()) {
            const storage = this._getStorage();
            const item = storage.getItem(key);
            try {
                const parse = this.parsers[key];
                if (parse) {
                    return parse(item);
                } else {
                    return JSON.parse(item);
                }
            } catch (e) {
                console.error(`Could not parse ${item} from ${key} in ${this.storageType}`, e);
            }
        }
    }

    remove(key) {
        try {
            if (this.isAvailable()) {
                const storage = this._getStorage();
                const currentValue = storage.getItem(key);
                storage.removeItem(key);
                if (currentValue != null) {
                    this.emitter.emit(key, null);
                }
            }
        } catch (e) {
            console.error(`Could not remove item from ${this.storageType}`, e);
        }
    }

    watch(key, handler) {
        this.emitter.on(key, handler);
    }

    setupStorageEventListener() {
        // triggered only when the storage has been modified in the context of another document
        window.addEventListener('storage', event => {
            const {key} = event;
            if (this.emitter.listenerCount(key) > 0) {
                const value = this.getValue(key);
                this.emitter.emit(key, value);
            }
        });
    }

    _getStorage() {
        return window[this.storageType];
    }

    //from https://developer.mozilla.org/fr/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    isAvailable() {
        try {
            const storage = this._getStorage();
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            console.error(`${this.storageType} is unavailable`);
            return false;
        }
    }

    registerParser(key, parse) {
        this.parsers[key] = parse;
    }

    waitForItem(key, intervalId, cb, interval = 200) {
        try {
            if (this.isAvailable()) {
                if (this.getValue(key)) {
                    cb(null, this.getValue(key));
                } else {
                    window[intervalId] = setInterval(() => {
                        const item = this.getValue(key);
                        if (item) {
                            cb(null, item);
                            clearInterval(window[intervalId]);
                        }
                    }, interval);
                }
            }
        } catch (error) {
            cb(error, null);
        }
    }
}

module.exports = StorageManager;
