const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const template = require('../templates/formContactView.jade');

const CompositeVueView = require('../vue/CompositeVueView');
const Views = require('./Views');
const Account = require('../authentication/Account');
const formConfig = require('../formContact/FormContactConfig');
const FormUtils = require('../fields/FormUtils');
const GoogleTagManager = require('../stats/GoogleTagManager');
const EventPack = require('../utils/EventPack');
const ContactHelper = require('../utils/ContactHelper');
const sendFormContactMailRequest = require('../formContact/sendFormContactMailRequest');
const getSubjectTranslation = require('../../common/contact/getSubjectTranslation');
const i18nMixin = require('../vue/components/mixins/i18n');

module.exports = class FormContactView extends CompositeVueView {
    constructor() {
        super();
        this.template = template;
        this._fieldValues = {};
        this._eventPack = new EventPack();
    }

    show(options) {
        this._enhanceOptionsAndStoreInView(options);
        const {pageContext, formType} = options;
        const vueData = {
            options: options,
            selectedSubject: '',
        };

        this.vueData = vueData;

        const vueOptions = {
            mixins: [
                i18nMixin({
                    prefix: 'formContact.',
                    keys: [
                        'subjectsLabel_',
                        'selectPlaceholder',
                        'rentalApplicationNotice.title',
                        'rentalApplicationNotice.content',
                    ],
                }),
            ],
            data() {
                return vueData;
            },
            computed: {
                backgroundClass() {
                    return `${pageContext} ${formType}`;
                },
                headerTitle() {
                    return this.formContactTranslate('headerTitle');
                },
                headerSubtitle() {
                    return this.formContactTranslate('headerSubtitle');
                },
                translationContext() {
                    return options.name;
                },
                pageContext() {
                    return options.pageContext;
                },
                subjectsList() {
                    return options.subjectsList;
                },
                subjectsListLabel() {
                    return _.map(this.subjectsList, item => ({
                        label: item.text,
                        value: item.value,
                    }));
                },
                subjectsListDisabled() {
                    return this.subjectsList.length === 0;
                },
                rentalApplicationSelected() {
                    return this.selectedSubject === 'rentalApplication';
                },
                proContext() {
                    return this.pageContext === 'pro';
                },
            },
            methods: {
                formContactTranslate(key) {
                    return translate(`formContact.${formType}.${pageContext}.${key}`);
                },
            },
        };
        this.vueOptions = vueOptions;
        super.show(options, vueOptions);
        this._initForm();
    }

    hide() {
        this._clear();
        super.hide();
    }

    _enhanceOptionsAndStoreInView(options) {
        options.pageContext = _.get(options, 'pageContext', 'public');
        options.senderTypesList = this._getSenderTypesList(options);
        options.subjectsList = this._getSubjectsList(options);
        this.options = options;
        this.pageContext = options.pageContext;
        this.formType = options.formType;
    }

    _initForm() {
        this.$form = this.$element.find('form');
        this._initFormUtils();
        this._initContactUpdater();
        this._bindEvents();
    }

    _clear() {
        this._eventPack.removeAllListeners();
        this._clearFormUtils();
    }

    update(options) {
        this._storePreviousValues();
        this._clear();
        this._enhanceOptionsAndStoreInView(options);
        this._updateDom(options);
        this._initForm();
        this._restorePreviousValues();
    }

    _updateDom(options) {
        const $newContent = this.createElement(options);
        this.injectVueViews($newContent, this.vueOptions);
        this.$element.replaceWith($newContent);
        this.$element = $newContent;
    }

    setDataFromUrl(formData) {
        FormUtils.setValues(this.$form, formData);
    }

    _bindEvents() {
        this._eventPack.on(this.$form, 'change', 'select[name="senderTypes"]', _.bind(this._onSenderTypeChange, this));
    }

    _onSenderTypeChange() {
        const senderType = this._getSenderTypeValue();
        this.update(_.assignIn(this.options, {
            senderTypes: senderType,
            pageContext: senderType == 'pro' ? 'pro' : this.pageContext,
        }));
    }

    _getSenderTypeValue() {
        return this._getFieldValueByName('senderTypes');
    }

    _getSubjectValue() {
        return this._getFieldValueByName('subjects');
    }

    _getFieldValueByName(name) {
        return FormUtils.getInputValueByName(this.$form, name);
    }

    _getSenderTypesList({formType, pageContext}) {
        const senderTypes = formConfig[pageContext].senderTypes;
        const senderTypesConf = _.pickBy(senderTypes, senderType => !_.isNil(this._getSenderTypeSubjects(formType, senderType)));
        return this._convertToOptions(senderTypesConf, 'senderTypes');
    }

    _getSenderTypeSubjects(formType, senderTypeConfig) {
        return _.get(senderTypeConfig, 'subjects') || _.get(senderTypeConfig, [formType, 'subjects']);
    }

    _getSubjectsList({formType, senderTypes, pageContext}) {
        const getter = {
            pro: _.bind(this._getProSubjectsConf, this),
            public: _.bind(this._getPublicSubjectsConf, this),
        };
        const subjectConf = _.get(getter, pageContext, () => ({}))(formType, senderTypes, formConfig[pageContext]);
        return this._convertToOptions(_.mapKeys(subjectConf), 'subjects');
    }

    _convertToOptions(values, translationContext) {
        this[`${translationContext}Conf`] = values;
        return _.map(_.keys(values), value => ({value, text: this._getFieldTranslation(translationContext, value)}));
    }

    _getProSubjectsConf(formType, senderType, proFormConfig) {
        const senderTypeConfig = proFormConfig.senderTypes[senderType];
        return formType == 'opinion' ? proFormConfig.opinionSubjects : this._getSenderTypeSubjects(formType, senderTypeConfig);
    }

    _getPublicSubjectsConf(formType, senderType, publicFormConfig) {
        const senderTypeConfig = publicFormConfig.senderTypes[senderType];
        return this._getSenderTypeSubjects(formType, senderTypeConfig);
    }

    _storePreviousValues() {
        this._fieldValues = this._getFieldValues();
    }

    _restorePreviousValues() {
        FormUtils.setValues(this.$form, this._fieldValues);
    }

    _initFormUtils() {
        FormUtils.init({
            $form: this.$form,
            submit: _.bind(this._submitForm, this),
            enableScrollOnError: true,
        });
    }

    _initContactUpdater() {
        this._contactUpdater = ContactHelper.getUpdater({
            $form: this.$form,
            paths: {
                email: 'mailField',
                firstName: 'firstNameField',
                lastName: 'lastNameField',
                phone: 'phoneField',
                postalCode: 'postalCodeField',
            },
        });
    }

    _clearFormUtils() {
        FormUtils.clear({
            $form: this.$form,
        });
    }

    _getFieldValues() {
        return FormUtils.readValues(this.$form);
    }

    _submitForm(callback) {
        this.asyncHelper.doAsync({
            func: cb => sendFormContactMailRequest(this._getDataToSend(), cb),
            callback: _.bind(this._onSubmitForm, this, callback),
            name: 'sendFormContactMail',
        });

        this._contactUpdater.updateAccount(function (err) {
            if (err) {
                console.log('contactForm could not update account contact data :', err);
            }
        });
    }

    _onSubmitForm(cb, err) {
        if (err) {
            this._onSubmitError(err);
        } else {
            this._onSubmitSuccess();
        }
        cb();
    }

    _onSubmitSuccess() {
        this.sendGTMTracking();
        this.emit('closePage');
        Views.volatileFeedback.showSuccess('formContact.modalMessages.success');
    }

    _onSubmitError(err) {
        console.error('error sending contact mail', err);
        Views.volatileFeedback.showError('formContact.modalMessages.error');
    }

    _getDataToSend() {
        const fieldValues = this._getFieldValues();
        const {senderTypes, subjects} = fieldValues;
        const data = {
            formType: this.formType,
            pageContext: this.pageContext,
            recipientMailKey: `${senderTypes}.${subjects}`,
            subjectTranslationKey: subjects,
            values: _.assignIn(fieldValues, {
                senderTypeTranslation: this._getSenderTypeTranslation(senderTypes),
            }),
        };
        const referrer = Account.getReferrer();
        if (referrer) {
            data.values.referer = referrer;
        }
        //TODO add wegGL info if available ( map._renderer.getRenderEngine()._webGL.glInfo )
        return data;
    }

    sendGTMTracking() {
        GoogleTagManager.sendEvent('contactUsForm', {
            formType: this.formType,
            senderType: this._getSenderTypeTranslation(),
            subject: getSubjectTranslation(this.formType, this._getSubjectValue()),
        });
    }

    _getSenderTypeTranslation(fieldValue = this._getSenderTypeValue()) {
        return this._getOptionText(fieldValue, 'senderTypesList');
    }

    _getOptionText(value, type) {
        return _.get(_.find(this.options[type], option => option.value == value), 'text');
    }

    _getFieldTranslation(context, fieldValue) {
        return translate(`formContact.${context}.${fieldValue}`);
    }
};
