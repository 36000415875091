const template = require('../templates/DrawingView.jade');
const View = require('../views/View');
const Vue = require('vue');
const _ = require('lodash');
const MapApi = require('../MapApi');
const MapCreation = require('../utils/MapCreation');
const $ = require('jquery');
const SearchZoneManager = require('./SearchZoneManager');
const kartoTemplate = require('./components/drawingMap.jade');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const Account = require('../authentication/Account');
const BrowserDetect = require('browser-detect');
const {'blue-grey': BLUE_GREY} = require('../colors');
const {isKartoEnabled} = require('../utils/Karto');

const POLYGON_OPTIONS = {
    strokeColor: BLUE_GREY,
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: BLUE_GREY,
    fillOpacity: 0,
    zIndex: 10,
    editable: true,
};

const SELECTED_POLYGON_OPTIONS = {
    strokeColor: BLUE_GREY,
    strokeOpacity: 1,
    strokeWeight: 4,
    fillColor: BLUE_GREY,
    fillOpacity: 0.5,
    zIndex: 15,
};

module.exports = class DrawingView extends View {
    constructor() {
        super({template});
        this.isDrawing = false;
        this.hasStartedToDraw = false;
        this.editableOverlays = [];
        this.drawingManager = null;
        this.$modal = null;
        this.hiddenListener = _.bind(this.hide, this);
    }

    _onMapClicked() {
        if (this.isShown() && this._onMapClickedListener) {
            this.$element.find('.modal-header').addClass('reduce');
            this._unselectAllOverlays();
            if (!this.isDrawing) {
                this._setDrawing(true);
            }
        }
    }

    initKartoMap() {
        const self = this;
        if (!this.map) {
            const center = SideMapViewSingleton.get().map.getCenter();
            const zoom = SideMapViewSingleton.get().map.getZoom();
            this.kartoVueInstance = new Vue({
                el: '#drawingMap',
                data: () => ({
                    mapOptions: {
                        graphic: {
                            enable3d: true,
                            pois: {enable: false},
                        },
                        center,
                        zoom,
                        keepMap: true,
                        extraMapOptions: {
                            disablePoiMenu: true,
                        },
                    },
                }),
                methods: {
                    handleMapCreated(map) {
                        Object.seal(map._getInternalMap().style);
                        self.map = map;
                        self._handleMapCreated(map);
                        setTimeout(() => {
                            map.set3DGraphics(false);
                        });
                    },
                },
                template: kartoTemplate(),
            });
        }
    }

    _onPolygonClicked(poly) {
        if (!this.hasStartedToDraw) {
            this._unselectAllOverlays();
            poly.isSelected = true;
            poly.setOptions(SELECTED_POLYGON_OPTIONS);
            this._setDrawing(false);
            this.$element.find('.drawingTools').toggleClass('isOpen', true);
        }
    }

    _unselectAllOverlays() {
        _(this.editableOverlays)
            .filter('isSelected')
            .each(this.editableOverlays, function (overlay) {
                overlay.setOptions(POLYGON_OPTIONS);
                overlay.isSelected = false;
            });
    }

    _clearToggleMapTimeout() {
        if (this._toggleTimeOutId != null) {
            clearTimeout(this._toggleTimeOutId);
            this._toggleTimeOutId = null;
        }
    }

    _toggleMap(visible, callback) {
        this._clearToggleMapTimeout();
        const sideMapView = SideMapViewSingleton.get();
        if (!visible) {
            $('.modalLoadingCover').fadeIn('slow');
            sideMapView.toggleMap(true);
            /*todo: manage fadeout in .styl
             this._toggleTimeOutId = setTimeout(() => {
             if (callback)
             callback();
             }, 1000);*/
            if (callback) {
                callback();
            }
        } else {
            this._toggleTimeOutId = setTimeout(() => {
                if (callback) {
                    callback();
                }
                sideMapView.toggleMap(false);
            }, 1000);
            $('.modalLoadingCover').fadeOut('slow');
        }
    }

    _saveDrawing(multiPolygon, callback) {
        this.asyncHelper.doAsync({
            func: cb => this._requestSaveDrawing(multiPolygon, cb),
            callback,
            name: 'saveDrawing',
        });
    }

    _requestSaveDrawing(multiPolygon, callback) {
        return Account.postJson({
            url: '/saveDrawnZone',
            timeout: 30000, //30 seconds
            data: {
                polygon: multiPolygon,
            },
            serverErrorMessage: 'saveDrawing',
            callback: (err, data) => {
                callback(err, data);
            },
        });
    }

    _handlePolygonDrawCompleteKarto(polygon) {
        if (this.isDrawing) {
            const {$element} = this;
            $element.find('#explanation').toggleClass('isVisible', false);
            $element.find('#saving').toggleClass('isVisible', true);
            $element.find('.modal-header').removeClass('reduce');
            this._toggleMap(true);
            const geoJson = polygon.data._geometry;
            this._saveDrawing(geoJson, (err, data) => {
                if (!err) {
                    SideMapViewSingleton.get().setGeoLocationCircleAndMarker();
                    SearchZoneManager.removeSearchZone();
                    this.emit('zoneSaved', data);
                    this.hide();
                }
            });
        }
    }

    _handlePolygonDrawComplete(polygon) {
        if (this.isDrawing) {
            this.editableOverlays.push(polygon);
            const onPolygonClickedListener = _.bind(this._onPolygonClicked, this, polygon);
            polygon._onPolygonClickedListener = onPolygonClickedListener;
            polygon.setMap(null);
            polygon.on('click', onPolygonClickedListener);
            if (this._hasDrawingEnoughPoints(polygon)) {
                this._setDrawing(false);
                this._removeDrawingListener();
                const {$element} = this;
                $element.find('#explanation').toggleClass('isVisible', false);
                $element.find('#saving').toggleClass('isVisible', true);
                $element.find('.modal-header').removeClass('reduce');
                const geoJson = polygon.toGeoJSON({removeIntersection: true});
                this._saveDrawing(geoJson, (err, data) => {
                    this._addDrawingListener();
                    this._clearCurrentDrawing();
                    if (!err) {
                        SideMapViewSingleton.get().setGeoLocationCircleAndMarker();
                        SearchZoneManager.removeSearchZone();
                        this._setEditableOverlaysClickable(true);
                        const {poiInterface} = this;
                        if (poiInterface) {
                            poiInterface.setClickEnabled(true);
                        }
                        this.emit('zoneSaved', data);
                        this.hide();
                    }
                });
            }
        }
    }

    _hasDrawingEnoughPoints(polygon) {
        return polygon.getPath().getLength() >= 3;
    }

    _createDrawingManager(map) {
        if (this.useKarto) {
            this._createKartoDrawingManager(map);
        } else {
            this.map = map;
            const drawingManager = new MapApi.api.drawing.DrawingManager({
                map,
                drawingControl: false,
                minMouseMoveBeforeDragInPixels: 30,
                polygonOptions: POLYGON_OPTIONS,
            });
            this.drawingManager = drawingManager;
            this.polygonCompleteListener = _.bind(this._handlePolygonDrawComplete, this);
            drawingManager.on('polygoncomplete', this.polygonCompleteListener);
            this.drawingStartedListener = _.bind(this._handleDrawingStarted, this);
            drawingManager.on('drawvertex', this.drawingStartedListener);
        }
    }

    _createKartoDrawingManager(map) {
        const drawingManager = new kartoEngine.drawing.DrawingManager({
            drawingMode: kartoEngine.drawing.OverlayType.POLYGON,
            drawingControl: false,
            polygonOptions: {
                'fill-color': '000000',
                'fill-opacity': 0.6,
                'line-color': '000000',
                'line-width': 2,
            },
        });
        this.drawingManager = drawingManager;
        this.drawingManager.setMap(map);
        this.drawingManager.on('drawing_end', (e) => {
            const coordinates = e.data._geometry.coordinates[0];
            if (coordinates.length >= 4) {
                this._handlePolygonDrawCompleteKarto(e);
            } else {
                this.map.removeAllShapeGroups();
                console.error('Le polygone doit avoir au moins quatre points.');
            }
        });
    }

    _setEditableOverlaysClickable(isClickable) {
        _.each(this.editableOverlays, function (overlay) {
            overlay.setClickable(isClickable);
        });
    }

    _handleDrawingStarted() {
        if (this.isDrawing) {
            this.hasStartedToDraw = true;
            this._setEditableOverlaysClickable(false);
            const {poiInterface} = this;
            if (poiInterface) {
                poiInterface.setClickEnabled(false);
            }
            this.$element.find('.drawingTools').toggleClass('isOpen', true);
        } else {
            console.warn('you started to draw but isDrawing is false... something is wrong');
        }
    }

    _clearDrawingManager() {
        const {drawingManager} = this;
        if (drawingManager) {
            drawingManager.setMap(null);
            if (!this.useKarto) {
                drawingManager.removeListener('polygoncomplete', this.polygonCompleteListener);
                drawingManager.removeListener('drawvertex', this.drawingStartedListener);
                drawingManager.set('drawingMode', null);
                this._clearDrawingOverlays();
            }
            this.drawingManager = null;
            this.polygonCompleteListener = null;
            this.isDrawing = false;
        }
    }

    _clearDrawingOverlays() {
        _.each(this.editableOverlays, function (polygon) {
            polygon.setMap(null);
            polygon.removeListener('click', polygon._onPolygonClickedListener);
        });
        this.editableOverlays = [];
        this._clearCurrentDrawing();
    }

    show(options) {
        this.options = _.extend(options || {}, {
            isTouchDevice: BrowserDetect.isTouchDevice(),
        });
        this.editableOverlays = [];
        this.emit('openDrawingMap');
        super.show(this.options);
        this._openModal();
        const {$element} = this;
        $element.find('#explanation').toggleClass('isVisible', true);
        $element.find('#saving').toggleClass('isVisible', false);
        let mapOptions = {};
        const sideMapView = SideMapViewSingleton.get();
        const mainMap = sideMapView && sideMapView.map;
        if (mainMap) {
            const mapTypeId = mainMap.getMapTypeId();
            mapOptions = {
                zoom: mainMap.getZoom(),
                center: mainMap.getCenter(),
                heading: mainMap.getHeading(),
                tilt: mainMap.getTilt(),
                date: mainMap.getDate(),
                mapTypeId,
            };
            this._mainMapTypeOnDrawingStart = mapTypeId;
        }
        this.useKarto = isKartoEnabled('searchResultsPage');
        if (this.useKarto) {
            this.initKartoMap();
        } else {
            const createMapOptions = {
                onMapCreatedCallback: _.bind(this._handleMapCreated, this),
                view: this,
                $map: $element.find('#drawingMap'),
                showGraphicLevelButton: false,
                showPoisButton: false,
                poisEnabled: {},
                getMapOptionsCallback: function () {
                    return {
                        camera: {
                            clipToScreen: true,
                        },
                        mapTypeControlOptions: {
                            position: MapApi.api.ControlPosition.TOP_LEFT,
                        },
                        ui: {
                            enableLoadingSpinner: false,
                            enableGeoloc: false,
                        },
                    };
                },
                mapOptions,
            };
            MapCreation.createMap(createMapOptions);
        }
    }

    _handleMapCreated() {
        const drawingMap = this.map;
        const sideMapView = SideMapViewSingleton.get();
        const mainMap = sideMapView && sideMapView.map;
        if (mainMap && drawingMap) {
            if (!this.drawingManager) {
                this._createDrawingManager(drawingMap);
            }
            this._setDrawing(true);
            this._toggleMap(true);
            this._addDrawingListener();
            this.map.MapTypeId = {
                'FLAT_TERRAIN': 'flat_terrain',
                'HYBRID': 'hybrid',
                'ROADMAP': 'roadmap',
                'SATELLITE': 'satellite',
                'TERRAIN': 'terrain',
            };
            const mapApi = this.useKarto ? this.map : MapApi.api;
            const {ROADMAP} = mapApi.MapTypeId;
            mainMap.setMapTypeId(ROADMAP);
            drawingMap.setMapTypeId(ROADMAP);
        }
    }

    hide(options, cb = _.noop) {
        if (this.isShown()) {
            this._toggleMap(false, () => {
                this._clearDrawingManager();
                if (this.$element) {
                    const {$modal} = this;
                    $modal.off('hidden.bs.modal', this.hiddenListener); //don't call hide() again
                    $modal.modal('hide');
                    delete this.$modal;
                }
                if (!this.useKarto) {
                    this._removeDrawingListener();
                }
                this.emit('closeDrawingMap');
                if (this.useKarto) {
                    kartoEngine.Engine.getInstance().removeMap(this.map);
                }
                this.map = null;
                super.hide(options, cb);
            });
        } else {
            cb();
        }
    }

    _addDrawingListener() {
        const {map} = this;
        if (map && !this._onMapClickedListener) {
            const onMapClickedListener = _.bind(this._onMapClicked, this);
            this._onMapClickedListener = onMapClickedListener;
            map.on('click', onMapClickedListener);
        }
    }

    _removeDrawingListener() {
        const {map} = this;
        const onMapClickedListener = this._onMapClickedListener;
        if (map && onMapClickedListener) {
            map.removeListener('click', onMapClickedListener);
            delete this._onMapClickedListener;
        }
    }

    _openModal() {
        const {$element} = this;
        const $modal = $element.find('#drawingViewModalPopup');
        this.$modal = $modal;
        $modal.on('hidden.bs.modal', this.hiddenListener);
        $element.appendTo('body');
        $modal.modal({keyboard: true});
        $modal.modal('show');
    }

    _setDrawing(isDrawing) {
        this.isDrawing = isDrawing;
        if (!this.useKarto) {
            this.drawingManager.set('drawingMode', isDrawing ? MapApi.api.drawing.OverlayType.POLYGON : null);
        }
        if (!isDrawing) {
            this.hasStartedToDraw = false;
        }
    }

    _clearCurrentDrawing() {
        const {drawingManager} = this;
        if (drawingManager) {
            drawingManager.cancelCurrentDrawing();
        }
        this.hasStartedToDraw = false;
        this._setEditableOverlaysClickable(true);
        const {poiInterface} = this;
        if (poiInterface) {
            poiInterface.setClickEnabled(true);
        }
        this._setDrawing(true);
    }
};
