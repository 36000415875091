const _ = require('lodash');
const {
    FOR_USER_SEARCH,
    DEFAULT_USER_SEARCH,
} = require('./PropertyTypes');
const {AD_TYPES} = require('../common/constants/AdType');

const defaultSearch = {
    filterType: AD_TYPES.BUY,
    propertyType: DEFAULT_USER_SEARCH,
    page: 1,
    //default sort values in SearchView, so any value after modification appears in url
    sortBy: 'relevance',
    sortOrder: 'desc',
    onTheMarket: [true],
};

module.exports = {
    camera: {
        cameraNotSet: true,
        '3d': true,
        lat: 46.5590038,
        lon: 3.5922550,
        zoom: 6,
        phi: 0,
        theta: 0.9,
        zoomMin: 3,
        zoomMax: 21,
        bounds: {
            sw: {
                lat: 41.515318,
                lng: -5.325194,
            },
            ne: {
                lat: 51.212523,
                lng: 10.077638,
            },
        },
    },
    search: defaultSearch,
    userRealEstateAdsBaseSearch: _.defaults({
        filterType: [AD_TYPES.BUY, AD_TYPES.RENT],
        propertyType: FOR_USER_SEARCH,
    }, defaultSearch),
    allPropertyTypes: FOR_USER_SEARCH,
    userSearch: isAdmin => ({
        'sort-type': isAdmin ? 'score' : 'contractRank',
        currentPage: 1,
    }),
    resultsPerPage: 24,
    maxAuthorizedResults: 2400,
    kartoConfig: {
        publicTransportManager: true,
        controlsPosition: 'top-left',
        minZoom: 2,
        cooperativeGestures: false,
        defaultCenter: {
            lng: '2.349014',
            lat: '48.864716',
        },
        extraMapOptions: {
            enablePoiAgencies: false,
            enableTransportLines: false,
            enableGeoloc: false,
            displayGraphicLevelMenu: false,
        },
    },
};
