const $ = require('jquery');
const _ = require('lodash');

const BrowserDetect = require('browser-detect');
const SideMapViewSingleton = require('./SideMapViewSingleton');
const {isKartoEnabled} = require('../utils/Karto');
const View = require('./View');
const SearchListModeModel = require('../search/SearchListModeModel');
const EventPack = require('../utils/EventPack');
const ViewManager = require('./ViewManager');
const MarkersController = require('../search/MarkersController');
const adsManager = require('../search/adsManager');
const RealEstateAdLoader = require('../RealEstateAdLoader');

const {MIN_WINDOW_WIDTH_TO_DISPLAY_MAP} = require('../map/Constants');
const REQUEST_FULL_AD_NAME = 'requestFullAd';
const REQUEST_AD_OVERLAY_NAME = 'loadHoveredBlurOverlays';
const {
    DISPLAY_MODE_GALLERY,
    DISPLAY_MODE_LIST,
    DISPLAY_MODE_MAP,
} = require('../search/constants');

/**
 * @constructor
 * @augments Page
 * @param {object} [configuration]
 * @param {string} configuration.defaultListMode default mode for map and side list : "list", "gallery" or "map"
 * @param {number} configuration.minWindowWidthToDisplayMap window width limit to allow to display map
 */

module.exports = class SideMapHelperView extends View {
    constructor(configuration) {
        super();
        this.configuration = _.defaults(configuration, {
            minWindowWidthToDisplayMap: MIN_WINDOW_WIDTH_TO_DISPLAY_MAP,
            defaultListMode: DISPLAY_MODE_MAP,
            requestForAdOverlaysCallback: _.bind(this._requestForAdOverlays, this),
            requestFullAdCallback: _.bind(this._requestFullAd, this),
            abortRequestAdCallback: _.bind(this._abortRequestFullAd, this),
            loadAllShrunkAdsCallback: _.noop,
            loadMinimalDataAllShrunkAdsCallback: _.noop,
        });
        this.searchListModeModel = new SearchListModeModel(configuration.defaultListMode);
        this.setListMode(configuration.listMode);
        this._markersControllerEventPack = new EventPack();
        this._adsOnMapIds = {};
        this._currentRealEstateAds = null;
    }

    setListMode(listMode) {
        this._askedListMode = listMode || this.configuration.defaultListMode;
        this.applyListMode();
    }

    getApplicableListMode(listMode) {
        const mapModeAvailable = this._isMapModeAvailable();
        this.setMapModeAvailable(mapModeAvailable);
        if ((listMode == DISPLAY_MODE_MAP && !mapModeAvailable)
            || (BrowserDetect.isMobile() && listMode === DISPLAY_MODE_LIST)) {
            return DISPLAY_MODE_GALLERY;
        } else {
            return listMode;
        }
    }

    applyListMode() {
        const listMode = this.getApplicableListMode(this._askedListMode);
        this.getSearchListModeModel().setMode(listMode);
    }

    _isMapModeAvailable() {
        return this.doesWindowSupportFullScreen() || BrowserDetect.isMobile();
    }

    show(options) {
        this._currentRealEstateAds = [];
        this.options = options;
        this.showMap = false;
        this._isShown = true;
        this._eventsWhileShown = new EventPack();
        this._eventsWhileShown.on(SideMapViewSingleton.get(), {
            mapExpanded: _.bind(this._onMapExpanded, this),
            toggledMap: _.bind(this._onMapToggled, this),
            cameraChanged: eventInfo => {
                this.emit('cameraChanged', eventInfo);
            },
        });
        this.setListMode(options.listMode);
        SideMapViewSingleton.get().resizeMap(); // before handleToggleMap ( for leafLet )
    }

    _onMapToggled(visible) {
        if (visible && this.isMapLoaded()) {
            this._showMarkersController();
        } else {
            this._hideMarkersController();
        }
        this.emit('beforeMapVisibilityChanged', visible);
        if (this._mapToggled != visible) {
            this._mapToggled = visible;
            this.emit('mapVisibilityChanged', visible);
            if (visible && BrowserDetect.isMobile()) {
                this.handleToggleMap(this.options);
            }
        }
        if (isKartoEnabled('searchResultsPage')) {
            SideMapViewSingleton.get().closeAgencyPoi();
        }
        this.emit('toggledMap', visible);
    }

    handleToggleMap(options) {
        const showMap = options.showMap;
        if (showMap != this.isMapShown()) {
            this._updateToggleMap(showMap);
        } else {
            this.emit('toggledMap', showMap);
        }
    }

    _updateToggleMap(showMap) {
        this.showMap = showMap;
        const options = this.options;
        if (options) {
            options.showMap = showMap;
        }
        const sideMapView = SideMapViewSingleton.get();
        const isSideMapViewOpen = ViewManager.isViewOpen(sideMapView);
        if (showMap) {
            if (!isSideMapViewOpen) {
                sideMapView.loadData(options, () => {
                    ViewManager.openView(sideMapView, options);
                });
            } else {
                this._onMapToggled(showMap);
            }
        } else if (isSideMapViewOpen) {
            ViewManager.closeView(sideMapView);
        } else {
            this.emit('toggledMap', showMap);
        }
    }

    _onMapExpanded(fullScreen) {
        this.emit('mapExpanded', fullScreen);
    }

    hide(options, cb = _.noop) {
        if (this._isShown) {
            this._isShown = false;
            this._eventsWhileShown.removeAllListeners();
            this._hideMarkersController();
        }
        cb();
    }

    doesWindowSupportFullScreen() {
        return (this.doesWindowSupportSplitScreen() || SideMapViewSingleton.get().isMapExpanded());
    }

    getSplitScreenStatus() {
        return this.getListMode() == DISPLAY_MODE_MAP && !SideMapViewSingleton.get().isMapExpanded();
    }

    doesWindowSupportSplitScreen() {
        return $(window).width() > this.configuration.minWindowWidthToDisplayMap;
    }

    setDetailedSheetAd(realEstateAd, onClickDetailedSheetMarker) {
        if (this._markersController) {
            this._markersController.setDetailedSheetAd(realEstateAd);
        }
        SideMapViewSingleton.get().initDetailedSheetAdOnMapView(realEstateAd, onClickDetailedSheetMarker);
    }

    clearDetailedSheetAd() {
        if (this._markersController) {
            this._markersController.clearDetailedSheetAd();
        }
        SideMapViewSingleton.get().clearDetailedSheetMap();
    }

    getSetHoverEnabledCallback() {
        const markersController = this._markersController;
        if (markersController) {
            return _.bind(markersController.enableMapHover, markersController);
        }
        return null;
    }

    setMapModeAvailable(mapModeAvailable) {
        this.getSearchListModeModel().setMapModeAvailable(mapModeAvailable);
    }

    getListMode() {
        return this.getSearchListModeModel().getMode();
    }

    getSearchListModeModel() {
        return this.searchListModeModel;
    }

    isMapShown() {
        return this.showMap && SideMapViewSingleton.get().map != null;
    }

    update(options) {
        this.options = options;
    }

    getShowMapOption() {
        return this.options.showMap;
    }

    _showMarkersController() {
        if (!this._markersController) {
            const searchCriteria = this._searchCriteria;
            this._markersController = new MarkersController(_.defaults({}, this.configuration, {
                searchCriteria,
                sideMapView: SideMapViewSingleton.get(),
                useKarto: isKartoEnabled('searchResultsPage'),
            }));
            this._markersControllerEventPack.on(this._markersController, {
                realEstateAdInfoChanged: realEstateAd => this.emit('realEstateAdInfoChanged', realEstateAd),
                adClick: (realEstateAd, scrollTo, openedFromList) => {
                    this.emit('adClick', {realEstateAd, scrollTo, openedFromList});
                },
                realEstateAdHovered: realEstateAd => this.emit('realEstateAdHovered', realEstateAd),
                realEstateAdUnhovered: realEstateAd => this.emit('realEstateAdUnhovered', realEstateAd),
            });
            this._showMarkers(this._currentRealEstateAds, searchCriteria);
        }
    }

    _hideMarkersController() {
        if (this._markersController) {
            this._markersControllerEventPack.removeAllListeners();
            this._markersController.clear();
            delete this._markersController;
        }
    }

    showListMarkers(realEstateAds, searchCriteria) {
        this._listedAdsIds = _.map(realEstateAds, 'id');
        this._showMarkers(realEstateAds, searchCriteria);
    }

    updateSearchCriteria(searchCriteria, limitChanged) {
        this._searchCriteria = searchCriteria;
        if (this._markersController) {
            this._markersController.updateSearchCriteria(searchCriteria, limitChanged);
        }
    }

    _requestFullAd({id: realEstateAdId}, callback) {
        this.asyncHelper.doAsync({
            func: cb => adsManager.loadRealEstateAdById({id: realEstateAdId}, cb),
            callback: (err, realEstateAd) => {
                if (err) {
                    if (err != 'abort') {
                        console.error('Error getting real estate ad ' + realEstateAdId + ': ', err);
                    }
                } else if (!realEstateAd) {
                    console.error('No real estate ad with id ' + realEstateAdId);
                } else {
                    callback(realEstateAd);
                }
            },
            name: REQUEST_FULL_AD_NAME,
        });
    }

    _abortRequestFullAd() {
        return this.asyncHelper.cancel(REQUEST_FULL_AD_NAME);
    }

    _requestForAdOverlays(realEstateAd, callback) {
        const useKarto = isKartoEnabled('searchResultsPage');
        this.asyncHelper.doAsync({
            func: cb => RealEstateAdLoader.loadBlurOverlays(realEstateAd, useKarto, cb),
            callback,
            name: REQUEST_AD_OVERLAY_NAME,
        });
    }

    isMapLoaded() {
        const {map} = SideMapViewSingleton.get();
        const useKarto = isKartoEnabled('searchResultsPage');
        return map && (useKarto || map._renderer);
    }

    _showMarkers(realEstateAds, searchCriteria) {
        const finalNewAds = _.clone(realEstateAds);
        let adsOnMapIds = [];
        _.each(this._adsOnMapIds, function (specificAdsOnMapIds) {
            adsOnMapIds = adsOnMapIds.concat(specificAdsOnMapIds);
        });
        const currentListIds = _.union(adsOnMapIds, this._listedAdsIds);
        _.each(currentListIds, adId => {
            const alreadyNewAd = _.find(finalNewAds, {id: adId});
            if (!alreadyNewAd) {
                const oldAd = _.find(this._currentRealEstateAds, {id: adId});
                if (oldAd) {
                    finalNewAds.push(oldAd);
                }
            }
        });
        this._currentRealEstateAds = finalNewAds;
        if (this._markersController && this.isMapShown()) {
            this._markersController.showMarkersOnMap(this._currentRealEstateAds, searchCriteria);
        }
    }

    showMapMarkers(realEstateAds, searchCriteria, requestName) {
        this._adsOnMapIds[requestName] = _.map(realEstateAds, 'id');
        const map = SideMapViewSingleton.get().map;
        if (isMapMoving(map)) {
            return;
        }
        this._showMarkers(realEstateAds, searchCriteria);
    }

    clearMapMarkers(requestName) {
        this._adsOnMapIds[requestName] = [];
    }

    getCurrentRealEstateAdsToFitCamera() {
        return _.filter(this._currentRealEstateAds, ad => _.includes(this._listedAdsIds, ad.id));
    }

    openDetailedSheetWhenAdClick(ad, event) {
        const markersController = this._markersController;
        if (markersController &&
            (BrowserDetect.isMobile() || SideMapViewSingleton.get().isMapExpanded())) {
            markersController.openDetailedSheetWhenAdClick(ad, null, event);
        }
    }

    onAccountChange() {
        if (this._markersController) {
            this._markersController.onAccountChanged();
        }
    }

    disableSmallMarkers() {
        if (this._markersController) {
            this._markersController.disableSmallMarkers();
        }
    }

    enableSmallMarkers() {
        if (this._markersController) {
            this._markersController.enableSmallMarkers();
        }
    }

    hoverAd(realEstateAd) {
        if (this._markersController) {
            this._markersController.hoverAd(realEstateAd);
        }
    }

    unHoverAd(realEstateAd) {
        if (this._markersController) {
            this._markersController.unHoverAd(realEstateAd);
        }
    }

    updateAdIcon(realEstateAd) {
        if (this._markersController) {
            this._markersController.updateIcon(realEstateAd);
        }
    }

    enableMapHover() {
        if (this._markersController) {
            this._markersController.enableMapHover();
        }
    }

    disableMapHover() {
        if (this._markersController) {
            this._markersController.disableMapHover();
        }
    }

    canClickOnDetailedSheetMarker() {
        return this._markersController
            && (BrowserDetect.isMobile() || SideMapViewSingleton.get().isMapExpanded());
    }
};

function isMapMoving(map) {
    return _.invoke(map, '_renderer.isMoving');
}
