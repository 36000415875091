const DASHBOARD_PRO_MODE = 'dashboard-pro';
const DASHBOARD_AND_STATS_PRO_MOBILE_MODE = 'dashboard-and-stats-pro-mobile';
const SEARCH_RESULT_LIST_MODE = 'search-result-list';

const ACCOUNT_STATS_MODES = [
    DASHBOARD_PRO_MODE,
    DASHBOARD_AND_STATS_PRO_MOBILE_MODE,
];

const REAL_ESTATE_AD_STATS_MODES = [
    SEARCH_RESULT_LIST_MODE,
];

const FETCH_ACCOUNT_STATS_THROTTLE_IN_MS = 50;

module.exports = {
    ACCOUNT_CONTACT_TYPES: [
        'buy',
        'rent',
        'accountContacts',
    ],
    ACCOUNT_NOT_FOUND_ERROR_TRANSLATION_KEY: 'accountNotFoundError',
    ACCOUNT_STATS_MODES,
    BASE_STATS_MODES: [
        ...ACCOUNT_STATS_MODES,
        ...REAL_ESTATE_AD_STATS_MODES,
    ],
    DASHBOARD_PRO_MODE,
    DASHBOARD_AND_STATS_PRO_MOBILE_MODE,
    DEFAULT_ERROR_TRANSLATION_KEY: 'defaultError',
    REAL_ESTATE_AD_STATS_MODES,
    SEARCH_RESULT_LIST_MODE,
    FETCH_ACCOUNT_STATS_THROTTLE_IN_MS,
};
