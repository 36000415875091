const template = require('./InfoPicture.jade');
const Account = require('../../authentication/Account.js');

// @vue/component
module.exports = {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getAccountImageAltText() {
            return `agency-logo-${this.user.display_name}`;
        },
        getAccountImageUrl() {
            return Account.getAccountImageUrl(this.user, 90, 200);
        },
    },
    methods: {
    },
    template: template(),
};
