const template = require('./Map.jade');
const {isKartoEnabled, getBoundsZoomLevel, calculateCenterFromBounds} = require('../../utils/Karto');
const {kartoConfig} = require('../../../common/DefaultConfiguration');

// @vue/component
module.exports = {
    props: {
        placeDetails: {
            type: Object,
            required: true,
        },
    },
    computed: {
        useKarto() {
            return isKartoEnabled('placeDiscoveryPage');
        },
        mapOptions() {
            const boundingBox = this.placeDetails.boundingBox;
            const {east, north, west, south} = boundingBox;
            const center = calculateCenterFromBounds(west, south, east, north);
            return {
                ...kartoConfig,
                center,
                zoom: 11,
                cooperativeGestures: true,
                extraMapOptions: {
                    enablePoiAgencies: true,
                    enableTransportLines: true,
                },
            };
        },
    },
    methods: {
        handleMapCreated(map) {
            map.graphicsPresetsManager.setAutoUpdate(false);
            map.graphicsPresetsManager.setCurrentPresetFromName(kartoEngine.GraphicsPresetName.LOW);
            const boundingBox = this.placeDetails.boundingBox;
            const {west, south, east, north} = boundingBox;
            const sw = new kartoEngine.LngLat(west, south);
            const ne = new kartoEngine.LngLat(east, north);
            const bounds = new kartoEngine.LngLatBounds(sw, ne);
            const center = bounds.getCenter();
            const zoom = getBoundsZoomLevel(map, bounds);
            if (zoom >= 15) {
                map.set3DGraphics(false);
            }
            map.setCenter(center);
            map.setZoom(zoom);
            this.$emit('loadZone', map);
        },
    },
    template: template(),
};
