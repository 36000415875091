const _ = require('lodash');
const PhoneNumberFormatter = require('@bienici/phone-number-formatter');
const PhoneNumberHelper = require('../../../utils/PhoneNumberHelper');
const GoogleTagManager = require('../../../stats/GoogleTagManager');
const template = require('./PhoneContact.jade');
const PhoneContactButton = require('../../../phoneContact/components/PhoneContactButton');
const PhoneContactInfo = require('../../../phoneContact/components/PhoneContactInfo');
const Account = require('../../../authentication/Account');
const RealtimeServer = require('../../../RealtimeServer');
const ConfirmationModal = require('../../../utils/ConfirmationModal');
const karto = require('../../../loadKarto');

// Don't forget to add the following value if component becomes global: fromContactForm
const gtmSources = [
    'fromAgency',
    'fromContactForm',
    'fromDetailedSheetFooter',
    'fromSlideShow',
    'descriptionContent',
    'fromDetailedSheet',
];

const DETAILED_SHEET_DATA_KEYS = ['realEstateAdId', 'realEstateAdReference', 'statsContext'];

// @vue/component
module.exports = {
    components: {
        PhoneContactButton,
        PhoneContactInfo,
    },
    props: {
        agency: {
            type: Object,
            required: true,
        },
        source: {
            type: String,
            required: true,
            validator: source => _.includes(gtmSources, source),
        },
        detailedSheetData: {
            type: Object,
            default: null,
            validator: detailedSheetData => _.every(_.keys(detailedSheetData), key => _.includes(DETAILED_SHEET_DATA_KEYS, key)),
        },
        displayPhoneNumber: Boolean,
    },
    data() {
        return {
            isPhoneNumberHidden: true,
            loading: false,
            calltrack: {
                id: null,
                phoneNumber: null,
                expirationDate: null,
                originalPhoneNumber: null,
            },
        };
    },
    computed: {
        userCanManageCallTracking() {
            return Account.hasRole('callTrackingNumberManager');
        },
        originalPhoneNumber() {
            const {isFromAgency, agency} = this;
            const key = isFromAgency ? 'contact.phone' : 'phoneToDisplay';
            return _.get(agency, key);
        },
        agencyPhoneNumber() {
            return this.calltrack.phoneNumber || this.originalPhoneNumber;
        },
        phoneToCall() {
            return this.formatPhoneNumberForCall(this.agencyPhoneNumber);
        },
        phoneToDisplay() {
            return this.formatPhoneNumberForDisplay(this.agencyPhoneNumber);
        },
        isFromAgency() {
            return this.source === 'fromAgency';
        },
        realEstateAdReference() {
            return _.get(this.detailedSheetData, 'realEstateAdReference');
        },
        detailedSheetStatsContext() {
            return _.get(this.detailedSheetData, 'statsContext');
        },
        internalDisplayPhoneNumber() {
            return this.displayPhoneNumber + this.source;
        },
    },
    watch: {
        internalDisplayPhoneNumber() {
            this.showPhoneNumber();
        },
    },
    methods: {
        formatPhoneNumberForDisplay(phoneNumber) {
            return new PhoneNumberFormatter(phoneNumber).phoneNumberToDisplay;
        },
        formatPhoneNumberForCall(phoneNumber) {
            return PhoneNumberHelper.formatPhoneToCall(phoneNumber);
        },
        formatPhoneNumberForMobile(phoneNumber) {
            return 'tel:' + this.formatPhoneNumberForCall(phoneNumber);
        },
        showPhoneNumber(phoneButton) {
            const {isFromAgency} = this;
            if (!isFromAgency && phoneButton) {
                this.$emit('show-phone-number-from-detailed-sheet', phoneButton);
            }
            this.displayAgencyPhoneNumber();
        },
        setWindowLocationIfMobile() {
            const {isMobile, phoneToDisplay, formatPhoneNumberForMobile} = this;
            if (isMobile) {
                window.location = formatPhoneNumberForMobile(phoneToDisplay);
                karto.setCurrentMap(null);
            }
        },
        displayAgencyPhoneNumber() {
            this.loading = true;
            this.getCallTrackedPhoneNumber((err, {success, localPhoneNumber, expirationDate, callTrackedPhoneNumberId} = {}) => {
                this.loading = false;
                if (!err && success) {
                    this.displayCallTrackedPhoneNumber(localPhoneNumber, expirationDate, callTrackedPhoneNumberId);
                }
                this.setWindowLocationIfMobile();
                this.isPhoneNumberHidden = false;
                this.sendViewPhoneEvent(callTrackedPhoneNumberId);
            });
        },
        displayCallTrackedPhoneNumber(phoneNumber, expirationDate, calltrackId) {
            this.calltrack.phoneNumber = phoneNumber;
            this.userCanManageCallTracking && this.setAdminOptions(expirationDate, calltrackId);
        },
        setAdminOptions(expirationDate, calltrackId) {
            const originalPhoneNumber = this.formatPhoneNumberForDisplay(this.originalPhoneNumber);
            this.calltrack = {
                ...this.calltrack,
                id: calltrackId,
                expirationDate,
                originalPhoneNumber,
            };
        },
        getCallTrackedPhoneNumber(cb) {
            Account.getAccountAndCreateGuestIfNeeded((err, account) => {
                if (err || !account) {
                    console.error('getCallTrackedPhoneNumber: could not create guest account');
                    cb(err);
                } else {
                    const data = {
                        accountId: Account.getAuthenticatedAccountId(),
                    };
                    if (this.isFromAgency) {
                        data.agencyId = this.agency.id;
                    } else {
                        data.adId = this.detailedSheetData.realEstateAdId;
                    }
                    this.requestCallTrackedPhoneNumber(data, cb);
                }
            });
        },
        requestCallTrackedPhoneNumber(data, cb) {
            Account.authAjax({
                url: '/reservePhoneNumber',
                data,
                serverErrorMessage: 'requestCallTrackedPhoneNumber',
                callback: function (err, data) {
                    cb(err, data);
                },
            });
        },
        expirePhoneNumber() {
            ConfirmationModal.showConfirm({
                cb: (cb) => {
                    Account.authAjax({
                        url: '/expirePhoneNumber?id=' + this.calltrack.id,
                        method: 'POST',
                        serverErrorMessage: 'expirePhoneNumber',
                        disableErrorPage: true,
                        callback: (err) => {
                            if (err) {
                                console.error(err);
                            }
                            this.isPhoneNumberHidden = true;
                            cb();
                        },
                    });
                },
                translationsKeysPath: 'expireCallTrackingNumberConfirmationModal',
            });
        },
        sendViewPhoneEvent(calltrackId) {
            if (this.isFromAgency) {
                this.sendViewPhoneGTMEvent();
            } else {
                this.sendViewPhoneRTEvent(calltrackId);
            }
        },
        sendViewPhoneGTMEvent() { // for source fromAgency only
            GoogleTagManager.sendAgencyPhoneNumberShownEvent(this.agency.id, this.source);
        },
        sendViewPhoneRTEvent(calltrackId) { // for source from ad only
            Account.getAccountAndCreateGuestIfNeeded((err, account) => {
                if (err) {
                    console.error(err, 'Could not get account or guest');
                }
                RealtimeServer.emit('ad:phone:display', _.extend({
                    useRedisList: true,
                    type: this.source,
                    access_token: _.get(account, 'access_token'),
                    calltrackId,
                }, this.detailedSheetStatsContext));
            });
        },
    },
    template: template(),
};
