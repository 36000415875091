const _ = require('lodash');
const template = require('./GraphicQualityMenu.jade');
const $ = require('jquery');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'GraphicQualityMenu.',
            keys: [
                'highLevelTitle',
                'highLevelLabel',
                'mediumLevelTitle',
                'mediumLevelLabel',
                'lowLevelTitle',
                'lowLevelLabel',
                'dropdownTitle',
            ],
        }),
    ],
    props: {
        defaultGraphicLevel: {
            type: String,
            default: 'Low',
        },
        map: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            options: [
                {level: 'High', label: this.t('highLevelLabel'), selected: false},
                {level: 'Medium', label: this.t('mediumLevelLabel'), selected: false},
                {level: 'Low', label: this.t('lowLevelLabel'), selected: false},
                {level: 'VeryLow', label: this.t('veryLowLevelLabel'), selected: false},
            ],
        };
    },
    mounted() {
        this.setDefaultOption();
    },
    methods: {
        setDefaultOption() {
            _.forEach(this.options, item => {
                item.selected = (item.level === _.upperFirst(this.defaultGraphicLevel));
            });
            this.setGraphicQuality(_.upperFirst(this.defaultGraphicLevel));
        },
        toggleDropdown() {
            $('#kimono-graphicOptionsMenu').css('z-index', '');
        },
        selectOption(option) {
            _.forEach(this.options, item => {
                item.selected = (item === option);
            });
            this.setGraphicQuality(option.level);
        },
        setGraphicQuality(quality) {
            const {map} = this;
            map.graphicsPresetsManager.setAutoUpdate(false);
            map.graphicsPresetsManager.setCurrentPresetFromName(quality);
            map.triggerRepaint();
        },
    },
    template: template(),
};
