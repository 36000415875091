const namespaceComponents = require('../../vue/components/helpers/namespaceComponents');

const NAMESPACE = 'karto';

module.exports = namespaceComponents(NAMESPACE, {
    'map': require('./Map'),
    'map-tutorial': require('./MapTutorial'),
    'map-tutorial-section': require('./MapTutorialSection'),
    'pois-interface': require('./PoisInterface'),
    'poi-category': require('./PoiCategory'),
    'graphic-quality-menu': require('./GraphicQualityMenu'),
    'sun-animator': require('./SunAnimator'),
});
