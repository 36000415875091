const _ = require('lodash');
const $ = require('jquery');
const {EventEmitter} = require('events');
const {resourceUrl} = require('fack');
const async = require('async');

const MapApi = require('./MapApi');
const MarkerIcon = require('./MarkerIcon');
const PriceFormatter = require('../common/PriceFormatter');
const isValidPosition = require('./utils/isValidPosition');
const BlurHelper = require('./BlurHelper');
const ApplicationConfig = require('./app/ApplicationConfig');
const Account = require('./authentication/Account');
const VirtualTourHelper = require('./virtualTours/VirtualTourHelper');
const PopupLayerHelper = require('./PopupLayerHelper');
const MarkerZIndex = require('./utils/MarkerZIndex');
const MarkerAnimation = require('./MarkerAnimation');
const {loadImage} = require('./utils/ImageLoader');
const {isAdFavorite} = require('./favorites');
const {
    computeDistanceBetweenTwoPoints,
} = require('./utils/Karto');

const emitter = new EventEmitter();
const maxDistanceInMForStack = 1;
const ZOOM_LIMIT_PRICE_RANGE = 13;
const DETAILED_SHEET_TYPE_MARKER = 'detailedSheetMarker';
const SEARCH_RESULTS_TYPE_MARKER = 'searchResultMarker';
const HIGHLIGHTED_3D_MARKER_PATH = 'models/3dHighlightedAd/';
const HIGHLIGHTED_3D_MARKER_MODEL_PATH = HIGHLIGHTED_3D_MARKER_PATH + 'LibOut_Montgolfiere.bis';
const HIGHLIGHTED_3D_MARKER_TEXTURE_PATH = HIGHLIGHTED_3D_MARKER_PATH + 'LibOut_Montgolfiere.png';
const BALLOON_DRAW_ZONE_COORDINATES = {x: 21, y: 86, w: 342, h: 146};
const POD_DRAW_ZONE_COORDINATES = {x: 416, y: 26, w: 74, h: 74};
const ONLINE_BOOKING_ICON_CODE = '\uf073';

module.exports = _.extend(emitter, {
    createMarker,
    toggleHighlight,
    toggleSelected,
    createStackedMarkers,
    deleteStackedMarker,
    clearStackedList,
    showRealEstateAdMarker,
    setMarkerInFront,
    reuseRealEstateAd,
    updateIcon,
    animateScale,
    getHighlightedIconFromInfo,
    setMarkerVisible,
    getMarkerDisplayMode,
    mustUpdateIconOnZoomChanged,
    createDetailedSheetMarker,
    deleteDetailedSheetMarker,
    storeMarkerInAd,
});

let lastHighLightRealEstateAd = null;
let lastSelectedRealEstateAd = null;

function getMarkerDisplayMode(map) {
    return map.getZoom() > ZOOM_LIMIT_PRICE_RANGE ? 'full' : 'small';
}

function mustDisplayPriceInfo(realEstateAd, map) {
    if (map) {
        return !mustHidePriceInfoAtHighZooms(realEstateAd) || map.getZoom() > ZOOM_LIMIT_PRICE_RANGE;
    }
    return false;
}

function mustHidePriceInfoAtHighZooms(realEstateAd) {
    return isNewProperty(realEstateAd) && !realEstateAd.onlineBookingUrl;
}

function mustUpdateIconOnZoomChanged(realEstateAd) {
    return mustHidePriceInfoAtHighZooms(realEstateAd);
}

function isNewProperty(realEstateAd) {
    return realEstateAd && realEstateAd.newProperty;
}

function getMarkerText(realEstateAd) {
    return PriceFormatter.formatForMarker(realEstateAd.price, realEstateAd);
}

function iconFromCanvas(canvas, useKarto) {
    if (canvas) {
        try {
            return {
                url: canvas.toDataURL(),
                size: useKarto ? {width: canvas.width, height: canvas.height} : new MapApi.api.Size(canvas.width, canvas.height),
                anchor: 0,
            };
        } catch (e) {
            console.log('Could not convert canvas data to dataUrl, check crossorigin attribute and headers', e);
            //TODO log to server
            return {};
        }
    } else {
        return {};
    }
}

function createMarker(realEstateAd, map, options, highlightedAds3dMarkers, useKarto) {
    const displayedPosition = BlurHelper.getDisplayedPosition(realEstateAd);
    if (isValidPosition(displayedPosition)) {
        const addToMap = options.addToMap;
        const displayPriceRange = mustDisplayPriceInfo(realEstateAd, map);
        const infoToBuildIcon = getInfoToBuildIcon(realEstateAd, displayPriceRange);
        const icon = getDefaultIconFromInfo(infoToBuildIcon, useKarto);
        storeDefaultIconInAd(realEstateAd, icon);

        let zIndex = MarkerZIndex.normal;
        if (infoToBuildIcon.with3dModel || infoToBuildIcon.with360 || infoToBuildIcon.withVideo) {
            zIndex = MarkerZIndex.highlighted;
        } else if (infoToBuildIcon.alreadySeen) {
            zIndex = MarkerZIndex.alreadySeen;
        }
        MarkerAnimation.updateIconScaledSize(icon, options.initialScale || 0, useKarto);
        const markerOptions = getMarkerOptions(icon, zIndex, displayedPosition, infoToBuildIcon, useKarto);
        if (!realEstateAd.height) {
            markerOptions.disableHeight = true;
        } else {
            markerOptions.height = useKarto ? null : realEstateAd.height;
            markerOptions.altitude = useKarto ? realEstateAd.height : null;
            markerOptions.forceHeight = realEstateAd.height;
        }
        if (options.clickable != null) {
            markerOptions.clickable = options.clickable;
        }
        if (addToMap) {
            markerOptions.map = map;
        }
        const marker = useKarto ? map.createMarker3D(markerOptions) : new MapApi.api.Marker(markerOptions);
        setMarkerInfowindow(map, marker, realEstateAd, useKarto);
        storeMarkerInAd(realEstateAd, marker);
        create3dHighlightedMarker(SEARCH_RESULTS_TYPE_MARKER, realEstateAd, markerOptions, highlightedAds3dMarkers, useKarto);
    }
}

function getMarkerOptions(icon, zIndex, displayedPosition, infoToBuildIcon, useKarto) {
    const baseMarkerOptions = {
        icon,
        visible: false,
        zIndex,
    };
    const specificMarkerOptions = useKarto ? {
        altitude: infoToBuildIcon.hasExactPosition ? 'auto' : 0,
        altitudeOffset: 15,
        position: new kartoEngine.LngLat(displayedPosition.lng || displayedPosition.lon, displayedPosition.lat),
        depthTest: false,
        depthWrite: false,
        line: {enable: infoToBuildIcon.hasExactPosition},
    } : {
        position: new MapApi.api.LatLng(displayedPosition.lat, displayedPosition.lng || displayedPosition.lon),
        alwaysInFront: true,
        disableLine: !infoToBuildIcon.hasExactPosition,
        disableHeight: true,
        forceHeight: null,
    };
    return {...baseMarkerOptions, ...specificMarkerOptions};
}

function getHighlightedIconFromInfo(info, useKarto) {
    return iconFromCanvas(MarkerIcon.drawHoveredCanvas(info), useKarto);
}

function getSelectedIconFromInfo(info, useKarto) {
    return iconFromCanvas(MarkerIcon.drawSelectedCanvas(info), useKarto);
}

function getDefaultIconFromInfo(info, useKarto) {
    return iconFromCanvas(MarkerIcon.drawDefaultCanvas(info), useKarto);
}

// eslint-disable-next-line complexity
function getInfoToBuildIcon(realEstateAd, displayPriceInfo) {
    const nbrStacked = (realEstateAd.marker && realEstateAd.marker.sameLocationMarkers)
        ? realEstateAd.marker.sameLocationMarkers.length : 1;
    const isSelected = realEstateAd.marker && realEstateAd.marker.isSelected;
    const isHighlighted = realEstateAd.marker && realEstateAd.marker.isHighlighted;
    const isStacked = (nbrStacked > 1 && !isSelected && !isHighlighted && displayPriceInfo);
    const text = isStacked ? String(nbrStacked) : displayPriceInfo && getMarkerText(realEstateAd);
    const alreadySeen = !ApplicationConfig.disableAlreadySeen
        && Boolean(realEstateAd.userRelativeData && realEstateAd.userRelativeData.lastViewDate)
        && !Account.isShowRoom();
    realEstateAd = VirtualTourHelper.enhanceAd(realEstateAd);
    const isFavorite = ApplicationConfig.hasToDisplayFavorites && isAdFavorite(realEstateAd.id);
    return {
        text,
        hasExactPosition: BlurHelper.hasExactPosition(realEstateAd),
        alreadySeen,
        isFavorite,
        isStacked,
        textIconCode: realEstateAd.onlineBookingUrl ? ONLINE_BOOKING_ICON_CODE : null,
        isNew: realEstateAd.newProperty,
        with3dModel: realEstateAd.with3dModel,
        with360: realEstateAd.with360 || realEstateAd.hasLotWith360,
        with360BecauseOfVirtualTourTesterRole: realEstateAd.with360BecauseOfVirtualTourTesterRole,
        withVideo: realEstateAd.withHighlightedVideo || realEstateAd.hasLotWithHighlightedVideo,
    };
}

function reuseRealEstateAd(oldRealEstateAd, newRealEstateAd, markersVisible, map, useKarto) {
    const displayPriceRange = mustDisplayPriceInfo(newRealEstateAd, map);
    const newInfoToBuildIcon = getInfoToBuildIcon(newRealEstateAd, displayPriceRange);
    const {marker, highlighted3dMarker} = oldRealEstateAd;
    let defaultIcon;
    if (_.isEqual(getInfoToBuildIcon(oldRealEstateAd, displayPriceRange), newInfoToBuildIcon)) {
        ({defaultIcon} = oldRealEstateAd);
        if (markersVisible && map) {
            setMapForMarkerIfItHasNoMap(newRealEstateAd.marker, map, useKarto);
            if (!useKarto) {
                setMapForMarkerIfItHasNoMap(newRealEstateAd.highlighted3dMarker, map, useKarto);
            }
        }
    } else {
        defaultIcon = getDefaultIconFromInfo(newInfoToBuildIcon, useKarto);
        if (marker && !marker.isHighlighted && !marker.isSelected) {
            updateMarkerIcon(marker, defaultIcon);
        }
    }
    storeMarkerInAd(newRealEstateAd, marker);
    storeHighlighted3dMarkerInAd(newRealEstateAd, highlighted3dMarker);
    storeDefaultIconInAd(newRealEstateAd, defaultIcon);
    //TODO deal with position change
}

function updateIcon(realEstateAd, useKarto) {
    const marker = realEstateAd.marker;
    if (marker) {
        const map = useKarto ? marker.getMap() : marker.map;
        const displayPriceRange = mustDisplayPriceInfo(realEstateAd, map);
        let newInfoToBuildIcon = getInfoToBuildIcon(realEstateAd, displayPriceRange);
        storeDefaultIconInAd(realEstateAd, getDefaultIconFromInfo(newInfoToBuildIcon, useKarto));
        let newIcon;
        if (marker.isSelected) {
            newInfoToBuildIcon = getInfoToBuildIcon(realEstateAd, true);
            newIcon = getSelectedIconFromInfo(newInfoToBuildIcon, useKarto);
        } else if (marker.isHighlighted) {
            newInfoToBuildIcon = getInfoToBuildIcon(realEstateAd, true);
            newIcon = getHighlightedIconFromInfo(newInfoToBuildIcon, useKarto);
        } else {
            newIcon = realEstateAd.defaultIcon;
        }
        MarkerAnimation.updateIconScaledSize(newIcon, marker.icon ? marker.icon.scale : 1, useKarto);
        updateMarkerIcon(marker, newIcon);
    }
}

function animateScale(marker, options, useKarto, onFinishedCbk) {
    MarkerAnimation.animateScale(marker, options, useKarto, onFinishedCbk);
}

function toggleHighlight(realEstateAd, flag, useKarto) {
    const marker = realEstateAd.marker;
    if (!marker) {
        return;
    }
    if (marker.currentTopMarker && marker.currentTopMarker.isSelected) {
        return;
    }
    const isHighlighted = Boolean(marker.isHighlighted);
    if (flag === undefined) {
        flag = !isHighlighted;
    }
    if (isHighlighted != flag) {
        marker.isHighlighted = flag;
        if (flag) {
            if (lastHighLightRealEstateAd && realEstateAd && lastHighLightRealEstateAd != realEstateAd) {
                toggleHighlight(lastHighLightRealEstateAd, false, useKarto);
            }
            setMarkerHighLighted(realEstateAd, useKarto);
            lastHighLightRealEstateAd = realEstateAd;
        } else if (lastHighLightRealEstateAd && lastHighLightRealEstateAd == realEstateAd) {
            lastHighLightRealEstateAd = null;
            setMarkerDefault(realEstateAd, useKarto);
        }
    }
}

function toggleSelected(realEstateAd, flag, useKarto) {
    const marker = realEstateAd.marker;
    if (marker) {
        toggleHighlight(realEstateAd, false, useKarto);
        const isSelected = Boolean(marker.isSelected);
        if (flag === undefined) {
            flag = !isSelected;
        }
        if (isSelected != flag) {
            marker.isSelected = flag;
            if (flag) {
                if (lastSelectedRealEstateAd && realEstateAd && lastSelectedRealEstateAd != realEstateAd) {
                    toggleSelected(lastSelectedRealEstateAd, false, useKarto);
                }
                setMarkerSelected(realEstateAd, useKarto);
                lastSelectedRealEstateAd = realEstateAd;
            } else if (lastSelectedRealEstateAd && lastSelectedRealEstateAd == realEstateAd) {
                lastSelectedRealEstateAd = null;
                setMarkerDefault(realEstateAd, useKarto);
            }
        }
    }
}

function setMarkerDefault(realEstateAd, useKarto) {
    const marker = realEstateAd.marker;
    updateMarkerIcon(marker, realEstateAd.defaultIcon);
    marker.setZIndex(marker.normalZIndex);
    setMarkerVisibleInStack(marker, realEstateAd, useKarto);
}

function setMarkerSelected(realEstateAd, useKarto) {
    const marker = realEstateAd.marker;
    const infoToBuildIcon = getInfoToBuildIcon(realEstateAd, true);
    updateMarkerIcon(marker, getSelectedIconFromInfo(infoToBuildIcon, useKarto));
    setMarkerInFront(realEstateAd, useKarto);
}

function setMarkerHighLighted(realEstateAd, useKarto) {
    const marker = realEstateAd.marker;
    const infoToBuildIcon = getInfoToBuildIcon(realEstateAd, true);
    updateMarkerIcon(marker, getHighlightedIconFromInfo(infoToBuildIcon, useKarto));
    setMarkerInFront(realEstateAd, useKarto);
}

function setMarkerInFront(realEstateAd, useKarto) {
    const marker = realEstateAd.marker;
    marker.normalZIndex = marker.getZIndex();
    marker.setZIndex(MarkerZIndex.max);
    if (!useKarto) {
        marker.set('alwaysInFront', true);
    }
    _.each(marker.sameLocationMarkers, function (otherMarker) {
        otherMarker.currentTopMarker = marker;
    });
    setMarkerVisibleInStack(marker, realEstateAd, useKarto);
}

function setMarkerVisible(realEstateAd, marker, visible, useKarto) {
    marker.mustBeVisible = visible;
    if (visible) {
        _.defer(show);
    } else {
        _.defer(hide);
    }

    function show() {
        if (!marker.mustBeVisible) {
            hide();
        } else {
            updateIcon(realEstateAd, useKarto);
            updateMarkerVisibility(marker, true, useKarto);
            animateScale(
                marker,
                {
                    initialScale: useKarto ? 0 : marker.icon.scale,
                    finalScale: 1,
                    duration: 500,
                    easing: $.easing.easeOutCubic,
                },
                useKarto
            );
        }
        if (marker.popupLayer) {
            PopupLayerHelper.movePopup(marker.popupLayer);
        }
    }

    function hide() {
        if (!marker.mustBeVisible) {
            updateMarkerVisibility(marker, false, useKarto);
        }
    }

}

function setMarkerVisibleInStack(marker, realEstateAd, useKarto) {
    if (marker && marker.sameLocationMarkers && marker.sameLocationMarkers.length) {
        for (let i = 0; i < marker.sameLocationMarkers.length; ++i) {
            if (marker.sameLocationMarkers[i] == marker) {
                setMarkerVisible(realEstateAd, marker, true, useKarto);
            } else {
                setMarkerVisible(realEstateAd, marker.sameLocationMarkers[i], false, useKarto);
            }
        }
    }
}

function clearStackedList(realEstateAds) {
    _.each(realEstateAds, function (realEstateAd) {
        const marker = realEstateAd.marker;
        if (marker) {
            delete marker.sameLocationMarkers;
        }
    });
}

function createStackedMarkers(realEstateAds, useKarto) {
    const computeDistanceBetween = useKarto ? computeDistanceBetweenTwoPoints
        : MapApi.api.geometry.spherical.computeDistanceBetween;
    _.each(realEstateAds, realEstateAd1 => {
        const marker1 = realEstateAd1.marker;
        if (marker1 && marker1.sameLocationMarkers == null) {
            marker1.sameLocationMarkers = [marker1];
            const position1 = useKarto ? marker1.getPosition() : marker1.position;
            _.each(realEstateAds, realEstateAd2 => {
                const marker2 = realEstateAd2.marker;
                if (marker2 && marker2.sameLocationMarkers == null && marker1 != marker2) {
                    const position2 = useKarto ? marker2.getPosition() : marker2.position;
                    if (computeDistanceBetween(position1, position2) <= maxDistanceInMForStack) {
                        marker1.sameLocationMarkers.push(marker2);
                        marker2.sameLocationMarkers = marker1.sameLocationMarkers;
                    }
                }
            });
            if (realEstateAd1.marker.currentTopMarker == null) {
                realEstateAd1.marker.currentTopMarker = realEstateAd1.marker.sameLocationMarkers[0];
            }
            setMarkerVisibleInStack(realEstateAd1.marker.currentTopMarker, realEstateAd1, useKarto);
        }
    });
}

function deleteStackedMarker(realEstateAd, skipAnimation, highlightedAds3dMarkers, useKarto) {
    const marker = realEstateAd.marker;
    const icon = useKarto && marker ? marker.getIcon() : _.get(marker, 'icon');
    if (marker && icon) {
        if (marker.sameLocationMarkers) {
            const idx = marker.sameLocationMarkers.indexOf(marker);
            _.each(marker.sameLocationMarkers, function (otherMarker) {
                otherMarker.currentTopMarker = null;
            });
            marker.sameLocationMarkers.splice(idx, 1);
            marker.sameLocationMarkers = null;
        }
        if (skipAnimation) {
            marker.setMap(null);
        } else {
            animateScale(
                marker,
                {
                    initialScale: useKarto ? 0 : icon.scale,
                    finalScale: 0,
                    duration: 1000,
                    easing: $.easing.easeOutCubic,
                },
                useKarto,
                function () {
                    marker.setMap(null);
                }
            );
        }
    }
    delete3dHighlightedMarker(SEARCH_RESULTS_TYPE_MARKER, realEstateAd, highlightedAds3dMarkers, useKarto);
}

function showRealEstateAdMarker(realEstateAd, map, visible, useKarto) {
    const marker = realEstateAd.marker;
    if (marker) {
        if (visible) {
            marker.setMap(map);
            if (marker.isSelected) {
                toggleSelected(realEstateAd, true, useKarto);
            }
        } else {
            marker.setMap(null);
        }
    }
}

function createCanvas({width, height}) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
}

function drawImageToZoneByScalingAndCentering(context, image, {x, y, w, h}) {
    const {width: sourceWidth, height: sourceHeight} = image;
    const horizontalRatio = w / sourceWidth;
    const verticalRatio = h / sourceHeight;
    const ratio = Math.min(horizontalRatio, verticalRatio);
    const targetWidth = sourceWidth * ratio;
    const targetHeight = sourceHeight * ratio;
    const targetX = (w - targetWidth) / 2 + x;
    const targetY = (h - targetHeight) / 2 + y;
    context.drawImage(
        image,
        0,
        0,
        sourceWidth,
        sourceHeight,
        Math.round(targetX),
        Math.round(targetY),
        Math.round(targetWidth),
        Math.round(targetHeight)
    );
}

function createLogoTextureCanvas(logoAlias, cb) {
    const backgroundUrl = resourceUrl(HIGHLIGHTED_3D_MARKER_TEXTURE_PATH);
    async.parallel({
        backgroundImage: cb => loadImage(backgroundUrl, cb),
        logoImage: cb => {
            if (logoAlias) {
                loadImage('/highlighted3dLogo/' + logoAlias, cb);
            } else {
                setImmediate(cb);
            }
        },
    }, (err, {backgroundImage, logoImage}) => {
        if (err) {
            cb(err);
        } else {
            const canvas = createCanvas(backgroundImage);
            const context = canvas.getContext('2d');
            context.drawImage(backgroundImage, 0, 0);
            if (logoImage) {
                const logoDrawZones = [BALLOON_DRAW_ZONE_COORDINATES, POD_DRAW_ZONE_COORDINATES];
                _.each(logoDrawZones, imageContextZone => {
                    drawImageToZoneByScalingAndCentering(context, logoImage, imageContextZone);
                });
            }
            cb(null, canvas);
        }
    });
}

function create3dHighlightedMarker(type, realEstateAd, {position, map, clickable}, highlightedAds3dMarkers, useKarto) {
    if (realEstateAd.is3dHighlighted) {
        if (useKarto) {
            const adMarkers = highlightedAds3dMarkers[realEstateAd.id] || {};
            map.once('idle', async () => {
                createLogoTextureCanvas(
                    realEstateAd.highlighted3dLogoUrl,
                    async (err) => {
                        if (err) {
                            console.error('Could not create 3d highlighted marker, skipping', err);
                        } else {
                            const map = kartoEngine.Engine.getInstance().getMap();
                            const highlighted3dMarker = adMarkers[type] = await map.addSignboard({
                                position: position,
                                altitude: 80,
                                iconUrl: '/highlighted3dLogo/' + realEstateAd.highlighted3dLogoUrl,
                                text: 'PROGRAMME',
                            });
                            map.triggerRepaint();
                            highlighted3dMarker.ad = realEstateAd;
                            storeHighlighted3dMarkerInAd(realEstateAd, highlighted3dMarker);
                            highlightedAds3dMarkers[realEstateAd.id] = adMarkers;
                            chooseVisibleMarker(adMarkers, useKarto);
                        }
                    }
                );
            });
        } else {
            const adMarkers = highlightedAds3dMarkers[realEstateAd.id] || {};
            let marker = adMarkers[type];
            const options = {position, map, clickable, visible: false};
            if (marker) {
                marker.setOptions(options);
            } else {
                marker = adMarkers[type] = new MapApi.api.Marker(options);
            }
            storeHighlighted3dMarkerInAd(realEstateAd, marker);
            highlightedAds3dMarkers[realEstateAd.id] = adMarkers;
            createLogoTextureCanvas(
                realEstateAd.highlighted3dLogoUrl,
                (err, canvas) => {
                    if (err) {
                        console.error('Could not create 3d highlighted marker, skipping', err);
                    } else {
                        marker.setModel({
                            modelPath: resourceUrl(HIGHLIGHTED_3D_MARKER_MODEL_PATH),
                            texturePath: canvas.toDataURL(),
                            scale: 3,
                        });
                        updateMarkerVisibility(marker, true, useKarto);
                        chooseVisibleMarker(adMarkers, useKarto);
                    }
                }
            );
        }
    }
}

function delete3dHighlightedMarker(type, {id}, highlightedAds3dMarkers, useKarto) {
    const adMarkers = highlightedAds3dMarkers[id];
    if (adMarkers && adMarkers[type]) {
        if (useKarto) {
            const map = kartoEngine.Engine.getInstance().getMap();
            map.removeSignboard(adMarkers[type]);
        } else {
            adMarkers[type].setMap(null);
        }
        delete adMarkers[type];
        chooseVisibleMarker(adMarkers, useKarto);
    }
}

function chooseVisibleMarker(adMarkers, useKarto) {
    const markerDetailedSheet = adMarkers[DETAILED_SHEET_TYPE_MARKER];
    const action = useKarto ? 'setVisibility' : 'setVisible';
    _.invoke(markerDetailedSheet, action, true);
    _.invoke(adMarkers[SEARCH_RESULTS_TYPE_MARKER], action, markerDetailedSheet == null);
}

function setMapForMarkerIfItHasNoMap(marker, map, useKarto) {
    const markerMap = (marker && useKarto) ? marker.getMap() : _.get(map, 'marker');
    if (marker && markerMap == null && marker.setMap) {
        marker.setMap(map);
    }
}

function createDetailedSheetMarker(realEstateAd, map, onClickCallback, highlightedAds3dMarkers, useKarto) {
    let marker;
    const position = realEstateAd.position
        || (realEstateAd.blurInfo && realEstateAd.blurInfo.position);
    if (position && position.lat && (position.lon || position.lng)) {
        const text = getMarkerText(realEstateAd);
        const alreadySeen = (realEstateAd.userRelativeData && realEstateAd.userRelativeData.lastViewDate) ?
            realEstateAd.userRelativeData.lastViewDate : null;
        const isFavorite = isAdFavorite(realEstateAd.id);
        const hasExactPosition = BlurHelper.hasExactPosition(realEstateAd);
        const disableLine = !hasExactPosition;
        const icon = getSelectedIconFromInfo({
            text,
            alreadySeen,
            isFavorite,
            hasExactPosition,
            textIconCode: realEstateAd.onlineBookingUrl ? ONLINE_BOOKING_ICON_CODE : null,
        }, useKarto);
        const markerOptions = getDetailedSheetMarkerOptions({
            useKarto,
            position,
            hasExactPosition,
            disableLine,
            onClickCallback,
            map,
            icon,
        });
        if (realEstateAd.height) {
            markerOptions.forceHeight = realEstateAd.height;
            markerOptions.disableHeight = false;
            markerOptions.fixedHeight = false;
        }
        marker = useKarto ? map.createMarker3D({...markerOptions, metaData: {realEstateAd}}) : new MapApi.api.Marker(markerOptions);
        setMarkerInfowindow(map, marker, realEstateAd, useKarto);
        create3dHighlightedMarker(DETAILED_SHEET_TYPE_MARKER, realEstateAd, markerOptions, highlightedAds3dMarkers, useKarto);
        handleClickCallback(onClickCallback, realEstateAd, marker, useKarto);
    }
    return marker;
}

function getDetailedSheetMarkerOptions({
    useKarto,
    position,
    hasExactPosition,
    disableLine,
    onClickCallback,
    map,
    icon,
}) {
    const positionCoords = useKarto
        ? new kartoEngine.LngLat(position.lon || position.lng, position.lat)
        : new MapApi.api.LatLng(position.lat, position.lon || position.lng);

    const sharedOptions = {
        map,
        clickable: (onClickCallback != null),
        icon,
        zIndex: MarkerZIndex.max,
    };

    return useKarto
        ? {
            ...sharedOptions,
            altitude: hasExactPosition ? 'auto' : 0,
            altitudeOffset: 15,
            visible: false,
            position: positionCoords,
            line: {enable: !disableLine},
            depthTest: false,
            depthWrite: false,
        }
        : {
            ...sharedOptions,
            position: positionCoords,
            visible: true,
            alwaysInFront: true,
            disableLine,
            disableHeight: disableLine,
        };
}

function setMarkerInfowindow(map, marker, realEstateAd, useKarto) {
    if (useKarto) {
        const markerInfowindow = map.createInfoWindow({
            opened: false,
            closeButton: false,
            maxWidth: '400px',
            maxHeight: '750px',
        });
        marker.setInfoWindow(markerInfowindow);
    }
    marker.ad = useKarto ? realEstateAd : null;
}

function handleClickCallback(onClickCallback, realEstateAd, marker, useKarto) {
    if (onClickCallback) {
        setClickCallbackForMarker(marker, onClickCallback, useKarto);
        if (realEstateAd.highlighted3dMarker) {
            setClickCallbackForMarker(realEstateAd.highlighted3dMarker, onClickCallback, useKarto);
        }
    }
}

function deleteDetailedSheetMarker(realEstateAd, marker, highlightedAds3dMarkers, useKarto) {
    if (marker) {
        marker.setMap(null);
        unsetClickCallbackForMarker(marker, useKarto);
    }
    delete3dHighlightedMarker(DETAILED_SHEET_TYPE_MARKER, realEstateAd, highlightedAds3dMarkers, useKarto);
    if (realEstateAd.highlighted3dMarker) {
        unsetClickCallbackForMarker(realEstateAd.highlighted3dMarker, useKarto);
    }
}

function setClickCallbackForMarker(marker, onClickCallback, useKarto) {
    marker.onClickCallback = onClickCallback;
    if (!useKarto) {
        marker.on('click', onClickCallback);
    }
}

function unsetClickCallbackForMarker(marker, useKarto) {
    if (marker.onClickCallback) {
        if (!useKarto) {
            marker.removeListener('click', marker.onClickCallback);
        }
        delete marker.onClickCallback;
    }
}

function storeMapObjectInAd(realEstateAd, key, mapObject) {
    Object.defineProperty(realEstateAd, key, {
        configurable: true,
        value: mapObject,
    });
}

function storeMarkerInAd(realEstateAd, marker) {
    storeMapObjectInAd(realEstateAd, 'marker', marker);
}

function storeHighlighted3dMarkerInAd(realEstateAd, highlighted3dMarker) {
    storeMapObjectInAd(realEstateAd, 'highlighted3dMarker', highlighted3dMarker);
}

function storeDefaultIconInAd(realEstateAd, defaultIcon) {
    storeMapObjectInAd(realEstateAd, 'defaultIcon', defaultIcon);
}

function updateMarkerVisibility(marker, visible, useKarto) {
    if (useKarto) {
        marker.setVisibility(visible);
    } else {
        marker.setVisible(visible);
    }
}

function updateMarkerIcon(marker, icon) {
    if (icon) {
        marker.setIcon(icon);
    }
}
