const Options = require('../../../Options');

const randomValue = Math.random();

// @vue/component
module.exports = {
    data() {
        return {
            adsPrintsActivationRate: Options.get('adsPrintsActivationRate'),
        };
    },
    computed: {
        adPrintCollectEnabled() {
            return randomValue < this.adsPrintsActivationRate;
        },
    },
};
