const $ = require('jquery');
const _ = require('lodash');

const SuperError = require('super-error');
const AjaxError = SuperError.subclass('AjaxError');
const ContentError = SuperError.subclass('ContentError');

const Errors = require('./Errors');
const Error404 = require('../Error404');
const ApiMode = require('../app/ApiMode');

module.exports = {
    withHeaders,
    request,
};

// see https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
const READY_STATE_TO_TEXT = {
    0: 'UNSENT',
    1: 'OPENED',
    2: 'HEADERS_RECEIVED',
    3: 'LOADING',
    4: 'DONE',
};

function overrideHeaders(headers, options) {
    let wrappedOptions = options;
    if (options) {
        wrappedOptions = _.clone(options);
        wrappedOptions.headers = _.extend({}, headers, options.headers);
    }
    return wrappedOptions;
}

function withHeaders(headers, args) {
    const param1 = args[0];
    const param2 = args[1];
    if (_.isString(param1)) {
        return request(param1, overrideHeaders(headers, param2));
    } else {
        return request(overrideHeaders(headers, param1), param2);
    }
}

function request(url, options) {
    if (!_.isString(url)) {
        options = url;
        url = null;
    }
    if (url) {
        options.url = url;
    }
    const callback = options.callback;
    options.error = onError;
    options.success = onSuccess;
    return $.ajax(options);

    function onSuccess(data, textStatus, jqXHR) {
        let err;
        if (options.serverErrorMessage && data && data.error) {
            let logErrorMessage = _.isString(data.error) ? data.error : JSON.stringify(data.error);
            logErrorMessage = `Received error from ${options.url}: ${logErrorMessage}`;
            err = new ContentError(logErrorMessage, {
                status: Errors.ERR_SERVER_DATA,
                headers: jqXHR.getAllResponseHeaders(),
            });
            console.error(err);
            data = null;
            if (!options.disableErrorPage) {
                Errors.showError(err);
                return;
            }
        } else if (data == null && jqXHR.status !== 204) {
            const errorMessage = `Received no error and data is null at ${options.url}`;
            if (!options.type || options.type === 'GET') { //no request type means GET request
                err = new Error404(errorMessage, {
                    headers: jqXHR.getAllResponseHeaders(),
                });
            } else {
                err = new ContentError(errorMessage, {
                    headers: jqXHR.getAllResponseHeaders(),
                });
            }
            console.error(err);
        }
        if (callback) {
            const callbackParams = [err, data];
            if (options.returnJqXHR) {
                callbackParams.push(jqXHR);
            }
            callback(...callbackParams);
        }
    }

    // eslint-disable-next-line complexity
    function onError(jqXHR, textStatus, errorThrown) {
        let jsonError;
        try {
            jsonError = JSON.parse(jqXHR.responseText);
        } catch (e) {
            void(e); // ignore invalid json
        }

        errorThrown = errorThrown || textStatus;
        let errorMessage = (jsonError && jsonError.message) || errorThrown;
        const readyStateText = READY_STATE_TO_TEXT[jqXHR.readyState];
        const jsonErrorMessage = _.get(jsonError, 'error.message');
        if (jsonErrorMessage) {
            errorMessage += ', message: ' + jsonErrorMessage;
        }
        errorMessage += `, ready state: ${readyStateText}, status: ${jqXHR.status} for url ${options.url}`;
        if (!jqXHR.status
            && jqXHR.readyState == 0 //UNSENT
            && (errorThrown == 'error' || !errorThrown)) {
            // error can be : ERR_NAME_NOT_RESOLVED, ERR_CONNECTION_REFUSED, ERR_BLOCKED_BY_CLIENT, ERR_TUNNEL_CONNECTION_FAILED
            errorThrown = Errors.ERR_CONNECTION_REFUSED;
        }

        let error;
        if (jqXHR.status === 404) {
            error = new Error404(errorMessage, {
                headers: jqXHR.getAllResponseHeaders(),
            });
        } else {
            error = new AjaxError(errorMessage, {
                statusCode: jqXHR.status,
                status: errorThrown,
                headers: jqXHR.getAllResponseHeaders(),
            });
        }
        let logErrorMessage = `${options.url}: ${errorMessage}`;
        if (jsonError) {
            _.extend(error, jsonError);
            const jsonErrorObject = jsonError.errors || jsonError.error;
            if (jsonErrorObject) {
                logErrorMessage += ' because: ' + JSON.stringify(jsonErrorObject);
            }
        }
        if (jqXHR.statusText !== 'abort' && !options.disableErrorPage && !ApiMode.isEnabled()) {
            console.error(logErrorMessage);
            Errors.showError(error);
        } else if (callback) {
            callback(error);
        }
    }
}
