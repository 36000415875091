const $ = require('jquery');
const BrowserDetect = require('browser-detect');
const template = require('../templates/searchFilterView.jade');
const CompositeVueView = require('../vue/CompositeVueView');
const _ = require('lodash');

const Account = require('../authentication/Account');
const DrawingView = require('./DrawingView');
const GeolocationHelper = require('../geolocation/GeolocationHelper');
const {i18n: {translate}} = require('fack');
const ApplicationConfig = require('../app/ApplicationConfig');
const SearchFieldsHandler = require('./SearchFieldsHandler');
const SearchFiltersHelper = require('../../common/SearchFiltersHelper');
const EventPack = require('../utils/EventPack');
const SearchByTravelTimeView = require('../views/SearchByTravelTimeView');
const PageManager = require('../pages/PageManager');
const PagesFactories = require('../pages/PagesFactories');
const FindProAdsOnThePublicSiteBlock = require('../pro/components/FindProAdsOnThePublicSiteBlock');
const {isKartoEnabled, forceKartoToBeDisabled} = require('../utils/Karto');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');

module.exports = class SearchFilterView extends CompositeVueView {
    constructor(options) {
        super({
            template,
        });
        this.searchFieldsHandler = null;
        const defaultAroundMeEnabled = GeolocationHelper.geolocationSupported()
            && BrowserDetect.isMobile();
        this.options = _.defaultsDeep(options || {}, {
            naturalLanguageEnabled: false,
            moreFiltersEnabled: true,
            hideableMoreFiltersEnabled: false,
            advancedSearchBtn: false,
            searchWithoutAgencyBtnEnabled: false,
            isMyRealEstateAds: false,
            lastSearch: {},
            locationsField: {
                aroundMeEnabled: defaultAroundMeEnabled,
            },
            validatorOptions: {
                feedbackIcons: {},
                fields: SearchFiltersHelper.getIgnoredFiltersInValidator(),
            },
        });

        this.options.fields = _.defaults(this.options.fields || {}, {
            filterType: true,
            location: true,
            propertyType: false,
            minPrice: false,
            maxPrice: false,
            minRooms: false,
            maxRooms: false,
            minArea: false,
            maxArea: false,
            newProperty: false,
            onTheMarket: false,
            reference: false,
            geocoding: false,
            extensionType: false,
            searchBtn: true,
            exclusiveMandate: true,
        });
        if (this.options.fields.location) {
            this.options.useLocation = true;
            delete this.options.fields.location;
        }
        //pick only non-falsy key+values so we can iterate on this.options.fields without always checking the value
        this.options.fields = _.pickBy(this.options.fields, Boolean);
        this.useKarto = isKartoEnabled('searchResultsPage');
        this._createDrawingView();
        this._eventsFieldsHandler = new EventPack();
        this._eventsTravelTimeHandler = new EventPack();
        this._eventsWhileShown = new EventPack();
    }

    _createDrawingView() {
        this._eventsDrawingView = new EventPack();
        this._drawingView = new DrawingView();
        this._eventsDrawingView.on(this._drawingView, 'closeDrawingMap', () => {
            this.emit('closeDrawingMap');
        });
        this._eventsDrawingView.on(this._drawingView, 'openDrawingMap', () => {
            this.emit('openDrawingMap');
        });
        this._eventsDrawingView.on(this._drawingView, 'zoneSaved', drawnZone => {
            this.setDrawnZone(drawnZone);
            this.emit('zoneSaved');
        });
    }

    show(options) {
        super.show(options, this.getVueOptions(options));
        this.init();
        if (!_.isEmpty(this.options.lastSearch)) {
            this.setSearchForm(this.options.lastSearch);
        }
    }

    hide() {
        this._reset();
        super.hide();
    }

    init() {
        this._initFieldsHandler();
        this._bindEvents();
    }

    _reset() {
        this._unbindEvents();
        this._resetFieldsHandler();
        this._hideDrawingMap();
    }

    createElement(options) {
        const filterTypeMultipleEnabled = this.options.fields.filterType && this.options.filterTypeMultiple;
        //TODO use Account instead of isAdmin, isCatalogueViewer
        this.$element = this.renderTemplate(this.template, _.extend({
            isMobile: BrowserDetect.isMobile(),
            isAdmin: Account.isAdmin(),
            isPro: ApplicationConfig.applicationPro,
            buyNewAllowed: !filterTypeMultipleEnabled,
            canMarkAdsAsLeading: ApplicationConfig.applicationPro && Account.canMarkAdsAsLeading(),
            canHighlightAds: ApplicationConfig.applicationPro && Account.canHighlightAds(),
            canFindYourAdsOnThePublicSite: ApplicationConfig.applicationPro,
            account: (options && options.author) || Account.getAuthenticatedAccount(),
            isCatalogueViewer: Account.isCatalogueViewer(),
            Account,
        }, this.options));
        //todo move this in the jade--[
        this.$element.toggleClass('naturalLanguage', this.options.naturalLanguageEnabled);
        if (this.options.style) {
            this.$element.addClass(this.options.style);
        }
        if (filterTypeMultipleEnabled) {
            const $filterType = this.$element.find('.searchTypePicker');
            $filterType.attr({multiple: true, title: 'Type de recherche'});
            $filterType.find('option').attr('selected', true);
        }
        //--]
        this.$searchButton = this.$element.find('#doSearch');

        if (this.options.moreFiltersEnabled && !this.options.hideableMoreFiltersEnabled) {
            this.showMoreFilter();
        }
        this.isMoreFiltersOpen = false;
        this._$morePropertyTypesLink = this.$element.find('#morePropertyTypesLink');
        this._$moreFilterBtn = this.$element.find('.moreFilterBtn');

        return this.$element;
    }

    _initFieldsHandler() {
        const fieldsHandlerOptions = _.extend(_.pick(this.options, ['locationsField', 'validatorOptions']), {
            submit: _.bind(this._submit, this),
            $button: this.$element.find('#doSearch'),
        });
        this.searchFieldsHandler = new SearchFieldsHandler(this.$element, fieldsHandlerOptions);
        this._bindFieldsHandler();
    }

    _resetFieldsHandler() {
        this._unbindFieldsHandler();
        this._unbindTravelTimeHandler();
        this.searchFieldsHandler.destroyFields();
    }

    selectAroundMe() {
        this.searchFieldsHandler.setAroundMeSearch();
    }

    launchSearchAroundMe(cb) {
        this.selectAroundMe();
        this.once('submit', err => {
            let location;
            // submit from homepage changes page and delete suggestion dom elements. fixes bug #3686
            if (this.isShown()) {
                const locations = this.searchFieldsHandler.getSelectedSuggestions();
                if (locations && locations.length) {
                    location = locations[0];
                }
            }
            cb(err, location);
        });
        this.searchFieldsHandler.submit();
    }

    openTravelTimeSearch({closeCallback = _.noop} = {}) {
        SearchByTravelTimeView.open({
            submitCallback: travelTimeZone => {
                this.searchFieldsHandler.setTravelTimeSearch(travelTimeZone);
                if (BrowserDetect.isMobile()) {
                    this.search();
                }
            },
            defaultItem: this.searchFieldsHandler.getFirstTravelTimeItem(),
            saveZoneOnSubmit: true,
            closeCallback,
            context: 'directSearch',
            parentView: this,
        });
    }

    showDrawingMap() {
        this._drawingView.show();
    }

    _hideDrawingMap() {
        this._drawingView.hide();
    }

    update() {
        if (!_.isEmpty(this.options.lastSearch)) {
            this.setSearchForm(this.options.lastSearch);
        }
    }

    resetSearch() {
        this.searchFieldsHandler.resetFields();
        this.updateFiltersVisibility();
    }

    setSearchForm(search) {
        const newSearch = _.omit(search, 'limit');
        if (!this._search || !_.isEqual(this._search, newSearch)) {
            this._search = newSearch;
            this.searchFieldsHandler.setFieldsValuesFromSearch(search);
            this.updateFiltersVisibility();

            if (this.vueData) {
                this.vueData.searchCriteria = newSearch;
            }
        }
    }

    setDrawnZone(drawnZone) {
        this.searchFieldsHandler.setDrawZoneSearch(drawnZone);
    }

    /**
     * @access public
     */
    getLocations() {
        return this.searchFieldsHandler.getSelectedLocations();
    }

    /**
     * @access public
     */
    getSearchLocationIds() {
        return this.searchFieldsHandler.getLocationIds();
    }

    /**
     * @access public
     */
    getSearchLocationInfos() {
        return this.searchFieldsHandler.getLocationsZoneInfos();
    }

    updateOpenMoreFiltersButton(isMoreFiltersOpen) {
        this._$moreFilterBtn.find('.bubble').toggleClass('moreFiltersOpen', isMoreFiltersOpen);
        const translationKey = `searchFilterView.${isMoreFiltersOpen ? 'close' : 'open'}AdditionalFilters`;
        this._$moreFilterBtn.find('.btnTitle').text(translate(translationKey));
    }

    toggleMoreFilters(flag) {
        //todo do wait search side view to call it, do it
        this.isMoreFiltersOpen = flag;
        this.updateOpenMoreFiltersButton(flag);
        this.$element.find('.moreFilterContainer').toggle(flag);
        this.$element.find('.moreFilterContainerFooter').toggle(flag);
        this.$element.find('.moreFilterOnly').toggle(flag);

        if (!this.isMoreFiltersOpen
            && this.dataAfterMoreFiltersOpen
            && !_.isEqual(this.dataBeforeMoreFiltersOpen, this.dataAfterMoreFiltersOpen)) {
            this.dataBeforeMoreFiltersOpen = this.dataAfterMoreFiltersOpen;
            this.emit('resultsShown', this.dataBeforeMoreFiltersOpen);
        }
    }

    getVueOptions(options, isHomePage) {
        this.isHomePage = isHomePage;
        const searchCriteria = options.search || {};
        const isAdmin = Account.isAdmin();
        this.vueData = {
            displayAboutPropertyGroup: true,
            disableRemoveAlertButton: false,
            searchCriteria,
            searchDefaults: options.searchDefaults,
            isAdmin: isAdmin,
            propertyTypeFields: {
                parking: this.options.fields.parkingBox,
                shop: this.options.fields.shop,
                building: this.options.fields.building,
                premises: this.options.fields.premises,
                castle: this.options.fields.castle,
                office: this.options.fields.office,
                townhouse: this.options.fields.townhouse,
                others: this.options.fields.others,
                terrain: this.options.fields.terrain,
                loft: this.options.fields.loft,
                annexe: Boolean(this.options.fields.annexe),
                morePropertyTypesLink: true,
            },
        };

        if (options.search && options.search.propertyType) {
            this.vueData.displayAboutPropertyGroup =
                SearchFiltersHelper.shouldDisplayAboutPropertyGroup(options.search.propertyType);
        }
        // @vue/component
        return {
            components: {
                FindProAdsOnThePublicSiteBlock,
            },
            mixins: [
                require('./components/mixins/rangeInputMixin'),
            ],
            data: () => this.vueData,
            computed: {
                account() {
                    return (options && options.author) || Account.getAuthenticatedAccount();
                },
                fieldsVisibility() {
                    const {propertyType, filterType} = this.searchCriteria;
                    return SearchFiltersHelper.getFilterFieldsVisibility(propertyType, filterType, this.isAdmin);
                },
            },
            methods: {
                saveSearchClick: () => {
                    this.emit('saveSearchClick');
                },
                removeAlertClick: (matchingSavedSearchId) => {
                    this.emit('removeAlertClick', matchingSavedSearchId);
                },
                goToAlertPage: function (matchingSavedSearchId) {
                    PageManager.openPage(PagesFactories.savedSearchAdsPageFactory, {
                        savedSearch: {
                            _id: matchingSavedSearchId,
                        },
                    });
                },
                onInputChange: (fieldName) => this.searchFieldsHandler._onChange(fieldName),
            },
        };
    }

    resetFields(dataObject, fieldsToReset) {
        fieldsToReset.forEach(field => {
            dataObject[field] = null;
        });
    }

    _toggleMorePropertyTypes(flag) {
        this.isMorePropertyTypesOpen = flag;
        this._$morePropertyTypesLink.find('i').toggleClass('fa-plus-square', !this.isMorePropertyTypesOpen);
        this._$morePropertyTypesLink.find('i').toggleClass('fa-minus-square', this.isMorePropertyTypesOpen);
        this.$element.find('#morePropertyTypesLink .morePropertyTypesText').text(this.isMorePropertyTypesOpen ?
            translate('searchFilterView.seeLessPropertyTypes') : translate('searchFilterView.seeMorePropertyTypes'));
        this.$element.find('.additionalPropertyType').toggleClass('hiddenPropertyType', !flag);
    }

    toggleShowMoreFilter() {
        if (this.isMoreFiltersOpen) {
            this.closeMoreFilter();
        } else {
            this.showMoreFilter();
        }
    }

    toggleShowMorePropertyTypes() {
        if (this.isMorePropertyTypesOpen) {
            this._toggleMorePropertyTypes(false);
        } else {
            this._toggleMorePropertyTypes(true);
        }
    }

    showMoreFilter() {
        this.emit('moreFilterOpen');
    }

    closeMoreFilter() {
        this.emit('moreFilterClose');
    }

    updateFiltersVisibility() {
        this.updateFilters();
        this.updateMorePropertyTypesVisibility();
    }

    updateFilters() {
        const searchCriteria = this.getRawFilters();
        const displayAboutPropertyGroup =
            SearchFiltersHelper.shouldDisplayAboutPropertyGroup(searchCriteria.propertyType);
        const fieldsToReset = [
            'minRooms',
            'maxRooms',
            'minBedrooms',
            'maxBedrooms',
            'minGardenSurfaceArea',
            'maxGardenSurfaceArea',
        ];

        if (this.vueData) {
            this.vueData.displayAboutPropertyGroup = displayAboutPropertyGroup;
        }

        if (!displayAboutPropertyGroup) {
            this.resetFields(searchCriteria, fieldsToReset);
            this.onSearchCriteriaUpdated(searchCriteria);
        }

        const $filterBlocks = this.$element.find('.showOnlyIfNotEmpty');
        _.each($filterBlocks, function (block) {
            $(block).toggle($(block).find('div').not('.hidden').filter(function () {
                return $(this).data('field');
            }).length > 0);
        });
    }

    onSearchCriteriaUpdated(searchCriteria) {
        this.refreshCounterCriteria(searchCriteria);
        if (this.vueData) {
            this.vueData.searchCriteria = searchCriteria;
        }
    }

    hideElementIfTerrainSearchOnly(element, terrainOnly) {
        element.toggleClass('hidden', terrainOnly);
    }

    refreshCounterCriteria(searchCriteria) {
        const nbCriteriaSelected = SearchFiltersHelper.getAdvancedFiltersCount(searchCriteria);
        const $nbCriteriaSelected = this.$element.find('.nbCriteriaSelected');
        const criteriaText = translate('searchFilterView.counterCriteria', {count: nbCriteriaSelected});
        $nbCriteriaSelected.html(nbCriteriaSelected ? criteriaText : '');
    }

    updateMorePropertyTypesVisibility() {
        const $additionalPropertyTypes = this.$element.find(".additionalPropertyType input[type='checkbox']");
        if ($additionalPropertyTypes.length) {
            const showMorePropertyTypes = _.some($additionalPropertyTypes, propertyTypeField => {
                return $(propertyTypeField).prop('checked');
            });
            this._toggleMorePropertyTypes(showMorePropertyTypes);
        }
    }

    toggleNaturalLanguageStyleOnLocationElement(isVisible) {
        this.$element.find('.searchLocationContainer').toggleClass('naturalLanguageStyleHidden', !isVisible);
    }

    _bindEvents() {
        //auto update visibility depending on filters
        this._eventsWhileShown.on(this._$moreFilterBtn, 'click', _.bind(this.toggleShowMoreFilter, this));
        this._eventsWhileShown.on(this._$morePropertyTypesLink, 'click', _.bind(this.toggleShowMorePropertyTypes, this));
        this._bindAndroidTablet();
        this._eventsWhileShown.on(this.$element.find('.searchWithoutAgency'), 'click', () => {
            forceKartoToBeDisabled(true);
            SideMapViewSingleton.get().map = null;
            SideMapViewSingleton.get().toggleMap(false);
            this.emit('searchWithoutAgency', this.getFilters());
        });
    }

    _unbindEvents() {
        this._eventsWhileShown.removeAllListeners();
    }

    _bindAndroidTablet() {
        if (BrowserDetect.isTablet() && !BrowserDetect.isIOS()) {
            const $body = $('body');
            this._eventsWhileShown.on($body, 'focus', '.searchLocationContainer input', event => {
                const $target = $(event.target);
                //give some time for keyboard to be displayed, try every 100ms during 700ms
                let afterDelayWait = 0;
                scrollToTarget();

                function scrollToTarget() {
                    if ($target.is(':focus')) {
                        $body.scrollTo($target.offset().top - 60, 0);
                        afterDelayWait += 100;
                        if (afterDelayWait <= 700) {
                            _.delay(scrollToTarget, 100);
                        }
                    }
                }
            });
        }
    }

    emitChanges(changes) {
        this.emit('change', changes);
    }

    _submit(err, cb) {
        if (_.get(err, 'isTravelTime')) {
            SearchByTravelTimeView.showError(err);
        }
        this.search(err, cb);
        this.emit('submit', err);
    }

    getVueFilters() {
        return SearchFiltersHelper.getVueFilters(this);
    }

    getFilters() {
        return SearchFiltersHelper.getFilters(this);
    }

    getRawFilters() {
        return SearchFiltersHelper.getRawFilters(this);
    }

    search(err, cb) {
        if (!err) {
            const filters = this.getFilters();
            this.emit('search', filters);
        }
        if (cb) {
            cb(err);
        }
    }

    /**
     * @access public
     */
    startSpinner() {
        this.$searchButton.find('i').remove();
        this.$searchButton.append($('<i>').addClass('md md-rotate-right md-spin'));
        this.$searchButton.addClass('disabled');
    }

    /**
     * @access public
     */
    stopSpinner() {
        this.$searchButton.find('i').remove();
        this.$searchButton.removeClass('disabled');
    }

    /**
     * @access public
     */
    updateAdvancedFilterCounts(counts) {
        this.searchFieldsHandler.updateAdvancedFilterCounts(counts);
    }

    hasLocationTagsTypes(types) {
        return this.searchFieldsHandler.hasLocationTagsTypes(types);
    }

    _unbindFieldsHandler() {
        this._eventsFieldsHandler.removeAllListeners();
    }

    _unbindTravelTimeHandler() {
        this._eventsTravelTimeHandler.removeAllListeners();
    }

    _bindFieldsHandler() {
        this._unbindFieldsHandler();
        this._unbindTravelTimeHandler();
        this._eventsTravelTimeHandler.on(this.searchFieldsHandler, 'travelTimeSelected', () => {
            this.openTravelTimeSearch();
        });
        this._eventsFieldsHandler.on(this.searchFieldsHandler, 'change', changes => {
            this.updateFilters();
            this.emitChanges(changes);
        });
        this.searchFieldsHandler.preventLetterTypingInNumberPlaceholder();
    }

    useDebouncedChange() {
        this._unbindFieldsHandler();
        const emitChangesDebounced = _.debounce(_.bind(this.emitChanges, this), 100);
        this._eventsFieldsHandler.on(this.searchFieldsHandler, 'change', changes => {
            this.updateFilters();
            emitChangesDebounced(changes);
        });
    }

    refreshAlert() {
        if (this.vueData) {
            this.vueData.disableRemoveAlertButton = false;
        }
    }
};
