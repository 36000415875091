const CompositeVueView = require('../vue/CompositeVueView');
const _ = require('lodash');
const template = require('../templates/admin/sendWelcomeEmail.jade');
const Account = require('../authentication/Account');
const $ = require('jquery');
const errorMessageModalTemplate = require('../templates/errorMessageModal.jade');
const {i18n: {translate}} = require('fack');
const FormUtils = require('../fields/FormUtils');

module.exports = class SendWelcomeEmailView extends CompositeVueView {
    constructor() {
        super({
            template,
        });
        this._hideListener = _.bind(this.hide, this);
    }

    show(options) {
        super.show(options);
        this.options = options;
        this.$sendMailButton = this.$element.find('.sendWelcomeAgencyEmail');
        this._openSendWelcomeEmailPopup();
        this.$form = this.$element.find('form');
        FormUtils.init({
            $form: this.$form,
            submit: _.bind(this.sendWelcomeAgencyEmail, this),
        });
    }

    sendWelcomeAgencyEmail() {
        this.$sendMailButton.attr('disabled', 'disabled');
        this.$sendMailButton.prepend('<span class="spinner btnSpinner"><i class="md-rotate-right md-spin"></span>');
        const $sendWelcomeAgencyEmailRecipient = this.$element.find('input[name=welcomeAgencyRecipient]');
        const recipientEmail = $sendWelcomeAgencyEmailRecipient.val() || null;
        Account.sendWelcomeAgencyEmail({
            accountId: this.options.userId,
            recipientEmail,
        }, _.bind(this._onWelcomeAgencyMailSent, this));
    }

    _onWelcomeAgencyMailSent(err) {
        if (err) {
            this.hide();
            console.error('Send welcome agency email error: ', err);
            const $modal = $(errorMessageModalTemplate({
                errorTitle: translate('welcomeEmail.errorTitle'),
                errorMessage: translate('welcomeEmail.errorMessage'),
            }));
            $('body').append($modal);
            $modal.modal('show');
            $modal.on('hidden.bs.modal', function () {
                $modal.remove();
                $('body').removeClass('modal-open'); //this class should be removed on modal hide, but it is not
            });
        } else {
            this._hideSendWelcomeEmailPopup();
            this._openSuccessPopup();
        }
    }

    _openModal(selector) {
        const $modal = this.$element.find(selector);
        $modal.on('hidden.bs.modal', this._hideListener);
        $modal.modal({
            keyboard: false,
            show: true,
        });
    }

    _hideModal(selector) {
        const $modal = this.$element.find(selector);
        $modal.off('hidden.bs.modal', this._hideListener); //don't call hide() again
        $modal.modal('hide');
    }

    _openSendWelcomeEmailPopup() {
        this._openModal('#sendWelcomeEmailPopup');
    }

    _hideSendWelcomeEmailPopup() {
        this._hideModal('#sendWelcomeEmailPopup');
    }

    _openSuccessPopup() {
        this._openModal('.successModal');
    }

    _hideSuccessPopup() {
        this._hideModal('.successModal');
    }

    hide() {
        if (this.isShown()) {
            FormUtils.clear({
                $form: this.$form,
            });
            this._hideSuccessPopup();
            this._hideSendWelcomeEmailPopup();
            super.hide();
        }
    }
};
