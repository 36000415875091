const template = require('./DiscoverSplitTextContainer.jade');

// @vue/component
module.exports = {
    props: {
        textFirst: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modifiers() {
            const {textFirst} = this;
            return {textFirst};
        },
    },
    template: template(),
};
