const _ = require('lodash');
const $ = require('jquery');
const template = require('./search/components/SearchPageMap.jade');
const {isKartoEnabled} = require('./utils/Karto');
const karto = require('./loadKarto');
const Errors = require('./utils/Errors');
const MarkerIcon = require('./MarkerIcon');
const ApplicationConfig = require('./app/ApplicationConfig');
const Account = require('./authentication/Account');
const renderTemplate = require('./views/utils/renderTemplate');
const expandMapButtonTemplate = require('./templates/expandMapButton.jade');
const CircleZoneAroundMeHelper = require('./geolocation/CircleZoneAroundMeHelper');
const DetailedSheetAdOnMapView = require('./DetailedSheetAdOnMapView');
const KartoCameraManger = require('./utils/KartoCameraManager');
const BrowserDetect = require('browser-detect');
const CircleZoneHelper = require('../common/CircleZoneHelper');
// const ProgrammeModelsManager = require('./ProgrammeModelsManager');
const LimitHelper = require('./LimitHelper');
const MarkerUtil = require('./MarkerUtil');
const EventPack = require('./utils/EventPack');
const VueView = require('./vue/VueView');
const AgencyPoisHelper = require('./utils/AgencyPoisHelper');

module.exports = class KartoMap extends VueView {
    constructor() {
        super();
        this.useKarto = isKartoEnabled('searchResultsPage');
        this.mapEnabled = false;
        this.$map = null;
        this._isMapExpanded = false;
        const highlightedAds3dMarkers = this._highlightedAds3dMarkers = {};
        this._detailedSheetAdOnMapView = new DetailedSheetAdOnMapView({highlightedAds3dMarkers});
        this.mapIdleCallback = _.bind(this._handleMapIdle, this);
        this._onZoomChangedHandler = _.bind(this._zoomChanged, this);
        this._cameraManager = new KartoCameraManger();
        this._programmeModelsManagerEventPack = new EventPack();
        this._onCameraChangedHandler = _.bind(this._onCameraChanged, this);
        this.eventPack = null;
    }

    loadData(options, cb) {
        this.initKartoMap(options, cb);
    }

    initKartoMap(options, cb) {
        const self = this;
        if (BrowserDetect.isMobile()) {
            $('.mainPageContainer').addClass('isLoading');
        }
        // @vue/component
        const vueOptions = {
            data: () => ({
                mapOptions: {
                    graphic: {
                        enable3d: true,
                        points3d: {enable: true},
                    },
                    extraMapOptions: {
                        enablePoiAgencies: !Account.isShowRoom() && !ApplicationConfig.applicationPro,
                        enableTransportLines: false,
                        enableGeoloc: BrowserDetect.isMobile(),
                        displayGraphicLevelMenu: true,
                    },
                    center: [2.19, 48.52],
                    zoom: 5,
                    minZoom: 2,
                    zoomRate: {
                        wheel: 1 / 333,
                        trackpad: 1 / 100,
                    },
                    enableSunAnimator: true,
                },
            }),
            mounted() {
                self.kartoInstance = this.$refs.kartoMap;
            },
            methods: {
                onGeolocButtonClick() {
                    self._geolocationClickedHandler = _.bind(self._onGeolocationClicked, self);
                    self._geolocationClickedHandler();
                },
                handleMapCreated: (map) => {
                    self.map = map;
                    this._updateAccountFilterForPoiAgency();
                    self._cameraManager.setMap(map);
                    self.$map = $('#map');
                    self.$centerOnAdMapButton = $("<div id='centerOnAdMapButton' "
                        + "class='kimono-mapSimpleButton'><span>Centrer sur le bien</span></div>");
                    self.$map.append(self.$centerOnAdMapButton);
                    self.handleWindowResizeCallback = _.bind(self.handleWindowResize, self);
                    self._initEvents = _.bind(self._addEventsListeners, self);
                    self._initEvents();
                    if (!BrowserDetect.isMobile()) {
                        self._createExpandMapButton(map);
                    }
                    self.emit('mapCreated');
                    self.$element = $(map.getContainer());
                    self.mapInitialized = true;
                    self._bindProgrammeModelsManager();
                    self.resizeMap();
                    MarkerIcon.init((err) => {
                        if (err) {
                            Errors.showError(err);
                        } else {
                            self.emit('toggledMap', true);
                            if (BrowserDetect.isMobile()) {
                                $('.mainPageContainer').removeClass('isLoading');
                            }
                            cb();
                        }
                    });
                },
                handleMapCreationError: () => {
                    const mapContainer = document.getElementById('map');
                    if (mapContainer) {
                        $('#map').css('display', 'none');
                    }
                    cb();
                },
            },
            template: template(),
        };
        if (!this.map || !karto.currentMap) {
            const mapContainer = document.getElementById('map');
            if (mapContainer) {
                document.getElementById('map').remove();
            }
            super.show(null, vueOptions);
        } else {
            if (BrowserDetect.isMobile()) {
                $('.mainPageContainer').removeClass('isLoading');
            }
            cb();
        }
    }

    _addEventsListeners() {
        if (!this.eventPack) {
            this.eventPack = new EventPack();
            this.eventPack.on(this._cameraManager, {
                cameraChanged: this._onCameraChangedHandler,
            });
            this.eventPack.on(this.map, {
                click: () => {
                    if (!this.isSelectionEvent && !BrowserDetect.isMobile()) {
                        this.emit('mapClick');
                    }
                },
                zoom: this._onZoomChangedHandler,
                idle: () => {
                    if (this._isMoving) {
                        this._isMoving = false;
                        this.mapIdleCallback();
                    }
                },
                move: () => {
                    if (!this._isMoving) {
                        this._isMoving = true;
                    }
                },
                selection: () => {
                    // on marker or 3d point selection, the click event is fired with selection
                    this.isSelectionEvent = true;
                },
                deselection: () => {
                    this.isSelectionEvent = false;
                    this.emit('mapClick');
                },
            });
            this.eventPack.on($(window), {
                resize: this.handleWindowResizeCallback,
            });
        }
    }

    _onCameraChanged(eventInfo) {
        this.emit('cameraChanged', eventInfo);
    }

    createMarker(realEstateAd, options) {
        MarkerUtil.createMarker(realEstateAd, this.map, options, this._highlightedAds3dMarkers, true);
    }

    showRealEstateAdMarker(realEstateAd, visible) {
        MarkerUtil.showRealEstateAdMarker(realEstateAd, this.map, visible, true);
    }

    deleteMarker(realEstateAd, skipAnimation) {
        MarkerUtil.deleteStackedMarker(realEstateAd, skipAnimation, this._highlightedAds3dMarkers, this.useKarto);
    }

    setBlurOverlaysVisible(visible) {
        this._detailedSheetAdOnMapView.setBlurOverlaysVisible(visible);
    }

    addOverlay(overlay) {
        overlay.setMap(this.map);
    }

    removeOverlay(overlay) {
        overlay.setMap(null);
    }

    initDetailedSheetAdOnMapView(realEstateAd, onClickCallback) {
        if (this.map) {
            this.closeAgencyPoi();
            this._detailedSheetAdOnMapView.show({
                map: this.map,
                realEstateAd,
                onClickCallback,
                displayBlurOverlays: this._canShowBlurOverlays(realEstateAd),
                openContactForm: () => this.emit('openContactSectionFromMap'),
            });
        } else {
            $('#map').css('display', '');
        }
    }

    _canShowBlurOverlays(realEstateAd) {
        return !this._programmeModelsManager || !this._programmeModelsManager.isBlurOverlayHidden(realEstateAd);
    }

    resizeMap() {
        if (karto.currentMap) {
            karto.currentMap.resize();
        }
    }

    show() {
        this.toggleMap(true);
    }

    toggleMap(enabled) {
        if (enabled === undefined) {
            enabled = !this.mapEnabled;
        }
        if (enabled !== this.mapEnabled) {
            this.closeMapExpand();
            this.mapEnabled = enabled;
            this.$map = $('#map');
            this.$map.toggleClass('disabled', !enabled);
            this._fixHeightOnIPadIOS7Landscape();
            if (enabled) {
                if (this.map) {
                    karto.setCurrentMap(this.map);
                    this._bindProgrammeModelsManager();
                    this.resizeMap();
                    this.emit('toggledMap', enabled);
                    this.emit('cameraChanged', {skipCameraUrl: false, isHuman: false});
                    this._updateGeoLocationCircleAndMarker(true);
                    this._addEventsListeners();
                }
            } else {
                _.invoke(this.watcher, 'destroy');
                if (this.eventPack) {
                    this.eventPack.removeAllListeners();
                    this.eventPack = null;
                }
                CircleZoneAroundMeHelper.abortGeolocation();
                this._updateGeoLocationCircleAndMarker(false);
                this._unbindProgrammeModelsManager();
                this.emit('toggledMap', false);
                if (this._geolocationClickedHandler) {
                    this._geolocationClickedHandler = null;
                }
            }
        } else {
            this.emit('toggledMap', enabled);
        }
        return this.map;
    }

    hide(options, cb = _.noop) {
        this.toggleMap(false);
        cb();
    }

    _zoomChanged() {
        if (this.map) {
            this.emit('zoomChanged', this.map.getZoom());
        }
    }

    _bindProgrammeModelsManager() {
        if (this._programmeModelsManager) {
            this._programmeModelsManagerEventPack.on(this._programmeModelsManager, {
                closeLot: (lot, event) => {
                    this.emit('closeLot', lot, event);
                },
                lotClick: (lot, event) => {
                    this.emit('lotClick', lot, event);
                },
                lotMouseOver: lot => {
                    this.emit('lotMouseOver', lot);
                },
                lotMouseOut: lot => {
                    this.emit('lotMouseOut', lot);
                },
                lotModelLoaded: lot => {
                    if (this.realEstateAd && this.realEstateAd.id == lot.id) {
                        this.updateLotPositionAndHeightFromModel(this.realEstateAd);
                        this._detailedSheetAdOnMapView.setMarkerPositionAndHeight(lot.position, lot.height);
                    }
                },
                cameraMarkerClick: facadeCameraInfo => {
                    const cameraConfig = facadeCameraInfo.cameraConfig;
                    cameraConfig.position = {
                        lat: cameraConfig.lat,
                        lon: cameraConfig.lon,
                    };
                    this.moveCameraToPosition(cameraConfig);
                },
                programmeModelSelected: (selected) => {
                    this.emit('programmeModelSelected', selected);
                },
            });
        }
    }

    _unbindProgrammeModelsManager() {
        this._programmeModelsManagerEventPack.removeAllListeners();
    }

    _handleMapIdle() {
        this._updateCameraFromMap({skipCameraUrl: false, isHuman: true});
        this.triggerMapIdle();
    }

    triggerMapIdle() {
        if (this.map) {
            this.emit('mapIdle');
        }
    }

    handleWindowResize() {
        this.resizeMap();
        this._cameraManager.triggerCameraChanged({});
    }

    triggerCameraChanged(eventInfo) {
        this._cameraManager.triggerCameraChanged(eventInfo);
    }

    isMapShown() {
        return this.showMap && this.sideMapViewSingleton.get().map != null;
    }

    initDetailedSheetMap(realEstateAd) {
        if (this.map) {
            if (this.realEstateAd != realEstateAd) {
                if (this.$centerOnAdMapButton) {
                    this.$centerOnAdMapButton.toggle(true);
                    if (!this.handleCenterOnAdClick) {
                        this.handleCenterOnAdClick = _.bind(this._onClickCenterOnAd, this);
                        this.$centerOnAdMapButton.on('click', this.handleCenterOnAdClick);
                    }
                }
                this.realEstateAd = realEstateAd;
            }
        } else {
            $('#map').css('display', '');
        }
    }

    _onClickCenterOnAd(event) {
        event.preventDefault();
        this.centerOnAd();
    }

    _createExpandMapButton(map) {
        const $expandMapButton = renderTemplate(expandMapButtonTemplate);
        $expandMapButton.appendTo($(map.getContainer()));
        $expandMapButton.on('click', function () {
            toggleExpand(!self._isMapExpanded);
        });
        $expandMapButton.toggle(true);
        this.handleMapExpandCallback = _.bind(this._handleMapExpanded, this);
        this.eventPack.on(this, {
            toggledMap: onMapToggled,
            closeMapExpand: onCloseMapExpand,
            openMapExpand: onOpenMapExpand,
            mapExpanded: this.handleMapExpandCallback,
        });
        function onOpenMapExpand() {
            toggleExpand(true);
        }
        function onCloseMapExpand() {
            toggleExpand(false);
        }
        function onMapToggled(enabled) {
            $expandMapButton.toggle(enabled);
        }
        function toggleExpand(fullScreen) {
            if (self._isMapExpanded != fullScreen) {
                self._isMapExpanded = fullScreen;
                $('body').toggleClass('kimono-mapFullscreen', fullScreen);
                if (self.emit) {
                    self.emit('mapExpanded', fullScreen);
                }
                if (fullScreen) {
                    $(document).on('keyup', onEscKey);
                } else {
                    $(document).off('keyup', onEscKey);
                }
            }
        }
        function onEscKey(event) {
            if (event.keyCode == 27/*esc*/) {
                toggleExpand(false);
            }
        }
    }

    centerOnAd() {
        if (this._programmeModelsManager && this._programmeModelsManager.isCameraAlreadyHandled(this.realEstateAd)) {
            this._programmeModelsManager.centerOnAd(this.realEstateAd);
        } else if (this.realEstateAd.blurInfo && this.realEstateAd.blurInfo.bbox) {
            const bbox = this.realEstateAd.blurInfo.bbox;
            const sw = new kartoEngine.LngLat(bbox[0], bbox[1]);
            const ne = new kartoEngine.LngLat(bbox[2], bbox[3]);
            const bounds = new kartoEngine.LngLatBounds(sw, ne);
            this.moveCameraToBounds(bounds, {
                zoom: 18,
                theta: 45,
                phi: 0,
            });
        } else {
            const ad = this.realEstateAd || this.incompleteRealEstateAd;
            if (ad && ad.position) {
                _.defer(() => {
                    this.moveCameraToPosition({
                        zoom: 18,
                        theta: 45,
                        phi: 0,
                        position: ad.position,
                    });
                });
            }
        }
    }

    getCameraManager() {
        return this._cameraManager;
    }

    moveCameraToRealEstateAd(realEstateAd, cameraOptions) {
        if (!this._programmeModelsManager || !this._programmeModelsManager.isCameraAlreadyHandled(realEstateAd)) {
            this._cameraManager.moveCameraToRealEstateAd(realEstateAd, cameraOptions);
        }
    }

    moveCameraToBounds(latLngBounds, cameraOptions) {
        this._cameraManager.moveCameraToBounds(latLngBounds, cameraOptions);
    }

    moveCameraToPosition(cameraOptions) {
        this._cameraManager.moveCameraToPosition(cameraOptions);
    }

    getCameraFromMap() {
        return this.mapEnabled ? this._cameraManager.getCameraFromMap() : null;
    }

    setDefaultBounds(cameraOptions) {
        this._cameraManager.setDefaultBounds(cameraOptions);
    }

    setCamera(camera, options) {
        this._cameraManager.setCamera(camera, options);
    }

    isCameraMoving() {
        return this._cameraManager.isCameraMoving(this._isMoving);
    }

    _updateCameraFromMap(eventInfo) {
        this._cameraManager.updateCameraFromMap(eventInfo);
    }

    moveCameraToNeighborhoodZone(overlays, teleport) {
        this._cameraManager.moveCameraToNeighborhoodZone(overlays, teleport);
    }

    fitCameraToBounds(latLngBounds, options) {
        this._cameraManager.fitCameraToBounds(latLngBounds, options);
    }

    fitCameraToRealEstateAds(realEstateAds, options) {
        this._cameraManager.fitCameraToRealEstateAds(realEstateAds, options);
    }

    clearDetailedSheetMap() {
        if (this.$centerOnAdMapButton) {
            this.$centerOnAdMapButton.toggle(false);
            this.$centerOnAdMapButton.off('click', this.handleCenterOnAdClick);
            this.handleCenterOnAdClick = null;
        }
        this._detailedSheetAdOnMapView.hide();
        delete this.realEstateAd;
    }

    mustShowUndisclosedAddressMessage(realEstateAd) {
        return this._detailedSheetAdOnMapView.mustShowUndisclosedAddressMessage(realEstateAd);
    }

    canDisplayMarkerForAd(realEstateAd) {
        return !this._programmeModelsManager || this._programmeModelsManager.canDisplayMarkerForAd(realEstateAd);
    }

    getEncodedLimit() {
        const maxMarkerIconSize = MarkerIcon.getMaxSize();
        return LimitHelper.getEncodedLimitForKarto(this.map, maxMarkerIconSize);
    }

    setAccountFilterForPoiAgency({accountType, id} = {}) {
        this._accountFilterForPoiAgency = {accountType, id};
        this._updateAccountFilterForPoiAgency();
    }

    _updateAccountFilterForPoiAgency() {
        if (this.map) {
            AgencyPoisHelper.setAccountFilterAgency(this.map, this._accountFilterForPoiAgency);
            delete this._accountFilterForPoiAgency;
        }
    }

    _getCameraFromMap() {
        const map = this.map;
        const center = map.getCenter();
        return _.extend(
            {
                '3d': map.isIn3d(),
                zoom: map.getZoom() + 1,
            },
            {
                lat: center.lat,
                lon: center.lng,
            }
        );
    }

    limitToBounds(limit) {
        return LimitHelper.limitToBoundsForKarto(limit);
    }

    isShown() {
        return Boolean(this.map);
    }

    toggleGeolocButton(show) {
        if (this.kartoInstance) {
            this.kartoInstance.displayGeoloc = show;
        }
    }

    closeMapExpand() {
        this.emit('closeMapExpand');
    }

    _handleMapExpanded() {
        this.handleWindowResize();
    }

    isMapExpanded() {
        return this._isMapExpanded;
    }

    openMapExpand() {
        this.emit('openMapExpand');
    }

    setLotHovered(realEstateAd, hovered) {
        if (this._programmeModelsManager) {
            this._programmeModelsManager.setLotHovered(realEstateAd, hovered);
        }
    }

    setSelectedLot(realEstateAd, selected) {
        if (this._programmeModelsManager) {
            this._programmeModelsManager.setSelectedLot(realEstateAd, selected);
        }
    }

    setSelectedProgramme(realEstateAd, selected) {
        if (this._programmeModelsManager) {
            this._programmeModelsManager.setSelectedProgramme(realEstateAd, selected);
        }
    }

    updateLotPositionAndHeightFromModel() {}

    _fixHeightOnIPadIOS7Landscape() {
        if (navigator.userAgent.match(/iPad;.*CPU.*OS 7_\d/i)) {
            if (this.mapEnabled) {
                $('body').css({
                    position: 'fixed',
                    height: window.innerHeight,
                });
                window.scrollTo(0, 0);
            } else {
                $('body').css({
                    position: '',
                    height: '',
                });
            }
        }
    }

    showDetailedSheetContactInfo(contact) {
        this._detailedSheetAdOnMapView.showContactInfo(contact);
    }

    _startGeolocButtonSpinner() {
        if (this.map) {
            const $mapElement = $(this.map.getContainer());
            if ($mapElement) {
                this.$geolocButton = $mapElement.find('.karto-map__geoloc');
                if (this.$geolocButton.length) {
                    this.$geolocButton.addClass('loading');
                    this.$geolocButton.find('i').remove();
                    this.$geolocButton.append($('<i>').addClass('md md-rotate-right md-spin'));
                    this.$geolocButton.attr('disabled', true);
                }
            }
        }
    }

    _stopGeolocButtonSpinner() {
        if (this.$geolocButton && this.$geolocButton.length) {
            this.$geolocButton.removeClass('loading');
            this.$geolocButton.find('i').remove();
            this.$geolocButton.removeClass('disabled');
            this.$geolocButton.attr('disabled', false);
        }
    }

    _onGeolocationClicked() {
        this._startGeolocButtonSpinner();
        this.emit('geolocationClicked', _.bind(this._onGeolocEnded, this));
    }

    _onGeolocEnded(err, circleLocationItem) {
        if (err) {
            console.warn(err, 'error on geolocation');
        } else {
            this.setGeoLocationCircleAndMarker(circleLocationItem, true);
        }
        this._stopGeolocButtonSpinner();
    }

    setGeoLocationCircleAndMarker(geolocationCircleZone, moveCameraOnCircle) {
        this._hideGeoLocationCircleAndMarker();
        this._showGeoLocationCircleAndMarker(geolocationCircleZone, moveCameraOnCircle);
    }

    _showGeoLocationCircleAndMarker(geolocationCircleZone, moveCameraOnCircle) {
        if (geolocationCircleZone) {
            this._geolocationCircles = {
                circles: [],
                moveCameraOnCircle,
                geolocationCircleZone,
            };
        }
        this._updateGeoLocationCircleAndMarker(true);
    }

    _updateGeoLocationCircleAndMarker(visible) {
        if (this.mapInitialized) {
            let map = null;
            if (this.mapEnabled && visible) {
                map = this.map;
            }
            if (this._geolocationCircles) {
                if (this._geolocationCircles.moveCameraOnCircle) {
                    const boundingBox = this._getCircleZoneBoundingBox();
                    const sw = new kartoEngine.LngLat(boundingBox.west, boundingBox.south);
                    const ne = new kartoEngine.LngLat(boundingBox.east, boundingBox.north);
                    this._cameraManager.fitBounds(new kartoEngine.LngLatBounds(sw, ne), {isHuman: true});
                    delete this._geolocationCircles.moveCameraOnCircle;
                }
                if (map) {
                    const circle = this._geolocationCircles.geolocationCircleZone.geometry;
                    this._geolocationCircles.circles =
                        CircleZoneAroundMeHelper.createGeolocationCircles(circle, map, true);
                } else if (this._geolocationCircles.circles) {
                    _.forEach(this._geolocationCircles.circles, (circle) => {
                        circle.setMap(null);
                    });
                }
            }

            if (map) {
                if (!this._scaleCirclesGeolocHandle) {
                    this._scaleCirclesGeolocHandle = _.bind(this._scaleCirclesGeoloc, this);
                    map.on('zoom', this._scaleCirclesGeolocHandle);
                } else {
                    map.off('zoom', this._scaleCirclesGeolocHandle);
                }
            } else if (this._scaleCirclesGeolocHandle) {
                delete this._scaleCirclesGeolocHandle;
            }
        }
    }

    _getCircleZoneBoundingBox() {
        let boundingBox = this._geolocationCircles.geolocationCircleZone.boundingBox;
        if (!boundingBox) {
            boundingBox = CircleZoneHelper.getCircleBbox({
                point: this._geolocationCircles.geolocationCircleZone.geometry.point,
                radius: this._geolocationCircles.geolocationCircleZone.geometry.accuracy,
            });
        }
        return boundingBox;
    }

    onSearchPageOpen() {
        if (BrowserDetect.isMobile()) {
            this.toggleGeolocButton(true);
        }
    }

    _scaleCirclesGeoloc() {
        if (this._geolocationCircles && this._geolocationCircles.geolocationCircleZone) {
            CircleZoneAroundMeHelper.scaleGeolocationCircles(this._geolocationCircles.circles, this.map, true);
        }
    }

    closeAgencyPoi() {
        if (karto.agencyPoi) {
            karto.agencyPoi.close();
            karto.agencyPoi = null;
        }
    }

    _hideGeoLocationCircleAndMarker() {
        this._updateGeoLocationCircleAndMarker(false);
        this._geolocationCircles = null;
    }
};
