const template = require('./SortSelector.jade');

// @vue/component
module.exports = {
    props: {
        sortBy: {
            type: String,
            required: true,
        },
        sortOrder: {
            type: String,
            required: true,
        },
        selectSortOptions: {
            type: Array,
            required: true,
        },
    },
    computed: {
        sortSelected() {
            return this.sortBy + '-' + this.sortOrder;
        },
    },
    methods: {
        emitNewSort(newSortSelected) {
            if (newSortSelected && this.sortSelected !== newSortSelected) {
                const [sortBy, sortOrder] = newSortSelected.split('-');
                this.$emit('sort-changed', sortBy, sortOrder);
            }
        },
    },
    template: template(),
};
