const $ = require('jquery');
const _ = require('lodash');
const {EventEmitter} = require('events');
const Popup = require('./Popup');
const PopupLayerHelper = require('./PopupLayerHelper');
const dialogTemplate = require('./templates/dialog.jade');
const Tooltip = require('./Tooltip');
const SideMapViewSingleton = require('./views/SideMapViewSingleton');

module.exports = class MarkerInfo extends EventEmitter {
    constructor(realEstateAd, options) {
        super();
        this.realEstateAd = realEstateAd;
        this.onSelectedCallback = options.onSelectedCallback;
        this.searchCriteria = options.searchCriteria;
        this.useKarto = options.useKarto;
        const realEstateAdStackedList = options.realEstateAdStackedList;
        if (realEstateAdStackedList) {
            this.realEstateAdStackedList = realEstateAdStackedList;
            this.currentIndex = _.indexOf(realEstateAdStackedList, _.find(realEstateAdStackedList, {id: realEstateAd.id}));
        }
        this.isPopup = options.isPopup;
        if (this.isPopup) {
            this._initPopup();
        } else {
            this._initTooltip();
        }
        if (!this.useKarto) {
            PopupLayerHelper.openPopup(SideMapViewSingleton.get().popupLayer, this.realEstateAd.marker, this.$templateInfo);
        }
    }

    updateMarkerInfo(realEstateAd) {
        this.oldRealEstateAd = this.realEstateAd;
        this.realEstateAd = realEstateAd;
        const $oldDetailsTemplate = this.$templateDetails;
        this._initPopupDetails();
        const $infoDetails = this.useKarto ? $(this.realEstateAd.marker.getInfoWindow().getDOMContent()).find('.infoDetails')
            : this.$templateContainer.find('.infoDetails');
        $infoDetails.append(this.$templateDetails);
        this._updatePopupId();
        $oldDetailsTemplate.remove();
        if (!this.useKarto) {
            PopupLayerHelper.setMarker(SideMapViewSingleton.get().popupLayer, this.realEstateAd.marker);
        }
    }

    _initPopup() {
        this.$templateContainer = Popup.getRealEstateAdPopupContainerHTML();
        this._initPopupHeader();
        this._initPopupDetails();
        this.$templateFooter = Popup.getRealEstateAdPopupFooterHTML(this.realEstateAd, this.currentIndex);
        this._initHtml();
        if (this.$templateFooter) {
            this._bindPopupFooter();
        }
    }

    _initTooltip() {
        this.$templateContainer = Tooltip.getRealEstateAdTooltipContainerHTML();
        this._initTooltipDetails();
        this.$templateFooter = Tooltip.getRealEstateAdTooltipFooterHTML(this.realEstateAd);
        this._initHtml();
    }

    _initHtml() {
        this.$templateInfo = this.$templateContainer;
        if (this.$templateHeader) {
            this.$templateInfo.find('.infoHeader').append(this.$templateHeader);
        }
        if (!this.useKarto) {
            this._appendDetailsSection();
        }
        if (this.$templateFooter) {
            this.$templateInfo.find('.infoFooter').append(this.$templateFooter);
        }
        this._updatePopupId();
    }

    _appendDetailsSection() {
        if (this.$templateDetails) {
            const $infoDetails = this.useKarto ? $(this.realEstateAd.marker.getInfoWindow().getDOMContent()).find('.infoDetails')
                : this.$templateInfo.find('.infoDetails');
            $infoDetails.append(this.$templateDetails);
            const isUndisclosedAddress = SideMapViewSingleton.get().mustShowUndisclosedAddressMessage(this.realEstateAd);
            if (isUndisclosedAddress) {
                const $dialogDialog = $(dialogTemplate({isPopup: true}));
                $infoDetails.append($dialogDialog);
            }
        }
    }

    _initPopupHeader() {
        if (this.$templateHeader) {
            this.$templateHeader.remove();
        }
        this.$templateHeader = Popup.getRealEstateAdPopupHeaderHTML(this.realEstateAd);
        this._bindPopupHeader();
    }

    _initPopupDetails() {
        this.$templateDetails = Popup.getRealEstateAdPopupDetailsHTML(this.realEstateAd);
        this._bindPopupDetails();
    }

    _bindPopupDetails() {
        const {onSelectedCallback} = this;
        if (onSelectedCallback) {
            const $infoDetails = this.$templateDetails;
            setTimeout(() => {
                $infoDetails.on('click', event => {
                    onSelectedCallback(event);
                });
            }, 1);
        }
    }

    _initTooltipDetails() {
        this.$templateDetails = Tooltip.getRealEstateAdTooltipDetailsHTML(this.realEstateAd);
    }

    _bindPopupFooter() {
        this._bindNavigationButton('.previousPopupAd', -1);
        this._bindNavigationButton('.nextPopupAd', 1);
    }

    _bindNavigationButton(selector, offset) {
        const $navButton = this.useKarto ? $(this.realEstateAd.marker.getInfoWindow().getDOMContent()).find(selector)
            : this.$templateFooter.find(selector); // next or previous
        $navButton.on('click', () => {
            const {length: numberOfAds} = this.realEstateAdStackedList;
            const adIndexToDisplay = (numberOfAds + this.currentIndex + offset) % numberOfAds;
            this._updatedAdInfoAndPopupTemplate(adIndexToDisplay);
        });
    }

    _updatedAdInfoAndPopupTemplate(adIndexToDisplay) {
        const {realEstateAdStackedList} = this;
        const realEstateAdToDisplay = realEstateAdStackedList[adIndexToDisplay];
        this.updateInfoContent(realEstateAdToDisplay, realEstateAdStackedList);
        const {oldRealEstateAd, realEstateAd} = this; // they might have been updated by updateInfoContent
        this.emit('realEstateAdInfoChanged', oldRealEstateAd, realEstateAd);
        this._updatePopupTemplateContainer();
    }

    _bindPopupHeader() {
        const $closePopup = this.useKarto ? $(this.realEstateAd.marker.getInfoWindow().getDOMContent()).find('.closePopup')
            : this.$templateHeader.find('.closePopup');
        $closePopup.on('click touchstart', event => {
            this.emit('infoClosedClicked', this.realEstateAd);
            this.closeInfo(event);
            event.stopPropagation();
            event.preventDefault();
        });
    }

    _updatePopupId() {
        this.$templateContainer.find('.kimono-popup').attr('data-id', this.realEstateAd.id);
    }

    _updatePopupDetails(adIndexToDisplay) {
        this.oldRealEstateAd = this.realEstateAd;
        this.realEstateAd = this.realEstateAdStackedList[adIndexToDisplay];
        this._updatePopupTemplateContainer();
        this._updatePopupId();
    }

    _updatePopupTemplateContainer() {
        const $oldDetailsTemplate = this.$templateDetails;
        this._initPopupDetails();
        const $infoDetails = this.useKarto ? $(this.realEstateAd.marker.getInfoWindow().getDOMContent()).find('.infoDetails')
            : this.$templateContainer.find('.infoDetails');
        $infoDetails.append(this.$templateDetails);
        $oldDetailsTemplate.remove();
    }

    _updatePopupHeader() {
        this.oldHeaderTemplate = this.$templateHeader;
        this._initPopupHeader();
        this.$templateContainer.find('.infoHeader').append(this.$templateHeader);
        this.oldHeaderTemplate.remove();
    }

    updatePopup(adIndexToDisplay) {
        if (this.realEstateAd != this.realEstateAdStackedList[adIndexToDisplay]) {
            this._updatePopupDetails(adIndexToDisplay);
            this._updatePopupHeader();
        }
    }

    _updateNavigationCounter(realEstateAdStackedList) {
        if (this.$templateFooter) {
            if (realEstateAdStackedList && this.realEstateAdStackedList != realEstateAdStackedList) {
                this.realEstateAdStackedList = realEstateAdStackedList;
            }
            const $nbPopupTotal = this.$templateFooter.find('.nbPopupTotal strong');
            const $popupCounter = this.$templateFooter.find('.popupCounter');
            $nbPopupTotal.text(this.realEstateAdStackedList.length);
            $popupCounter.text((this.currentIndex + 1) + '/' + this.realEstateAdStackedList.length);
        }
    }

    setMarker(realEstateAd) {
        if (!this.useKarto) {
            PopupLayerHelper.setMarker(SideMapViewSingleton.get().popupLayer, realEstateAd.marker);
        } else {
            realEstateAd.marker.getInfoWindow().setContent(this.$templateInfo.html());
            this._initPopupHeader();
            this._appendDetailsSection();
            this._bindPopupFooter();
            realEstateAd.marker.getInfoWindow().open();
            if (this.oldRealEstateAd) {
                this.oldRealEstateAd.marker.getInfoWindow().setContent(null);
                this.oldRealEstateAd.marker.getInfoWindow().close();
            }
        }
    }

    updateInfoContent(realEstateAd, realEstateAdStackedList, searchCriteria) {
        const currentIndex = _.indexOf(realEstateAdStackedList, _.find(realEstateAdStackedList, {id: realEstateAd.id}));
        if (searchCriteria && !_.isEqual(this.searchCriteria, searchCriteria)) {
            this.searchCriteria = searchCriteria;
        }
        if (realEstateAd != this.realEstateAd) {
            this.updatePopup(currentIndex);
        }
        if (currentIndex != this.currentIndex || realEstateAdStackedList != this.realEstateAdStackedList) {
            this.realEstateAdStackedList = realEstateAdStackedList;
            this.currentIndex = currentIndex;
            this._updateNavigationCounter(realEstateAdStackedList);
        }
    }

    updateInfoPosition() {
        if (!this.useKarto) {
            PopupLayerHelper.movePopup(SideMapViewSingleton.get().popupLayer);
        }
    }

    closeInfo() {
        if (this.useKarto) {
            this.realEstateAd.marker.getInfoWindow().close();
        } else {
            PopupLayerHelper.closePopup(SideMapViewSingleton.get().popupLayer);
        }
    }
};
