const _ = require('lodash');
const template = require('./Map.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const karto = require('../../loadKarto');
const EventPack = require('../../utils/EventPack');
const SideMapViewSingleton = require('../../views/SideMapViewSingleton');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'Map.',
            keys: [
                'zoomIn',
                'zoomOut',
                'geoloc',
                'pitch',
                'compass',
                'noWebglMessage',
                'zoomWheelWindowsLinuxMessage',
                'zoomWheelMacMessage',
                'zoomWheelMobileMessage',
            ],
        }),
    ],
    props: {
        options: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            noWebgl: false,
            displayTutorial: false,
            displayNav: false,
            displayFooter: false,
            displayPoiMenu: false,
            displayGeoloc: false,
            eventPack: new EventPack(),
            karto3dmode: Math.random(),
            displayGraphicLevelMenu: false,
            defaultGraphicLevel: 'Low',
            displaySunAnimator: false,
        };
    },
    computed: {
        optionsForKarto() {
            const {options, zoomWheelWindowsLinuxMessage, zoomWheelMacMessage, zoomWheelMobileMessage} = this;
            const cooperativeGesturesMessages = {
                windowslinux: zoomWheelWindowsLinuxMessage,
                mac: zoomWheelMacMessage,
                mobile: zoomWheelMobileMessage,
            };
            _.omit(options, 'extraMapOptions');
            return _.extend(options, {cooperativeGesturesMessages});
        },
        controlsPosition() {
            const {options} = this;
            return _.get(options, 'controlsPosition', 'top-left');
        },
        positionModifiers() {
            const {controlsPosition} = this;
            return {
                [controlsPosition]: true,
            };
        },
        enableGeoloc() {
            return _.get(this.options.extraMapOptions, 'enableGeoloc') || this.displayGeoloc;
        },
        enablePoiAgencies() {
            return _.get(this.options.extraMapOptions, 'enablePoiAgencies');
        },
        enableTransportLines() {
            return _.get(this.options.extraMapOptions, 'enableTransportLines');
        },
    },
    mounted() {
        if (SideMapViewSingleton.get().map && !this.optionsForKarto.keepMap && karto.currentMap) {
            karto.currentMap.dispose();
            SideMapViewSingleton.get().map = null;
        }
        karto.init(this.$refs.map, this.optionsForKarto, (map) => {
            if (!this.destroying) {
                if (map) {
                    this.map = map;
                    this.setGraphicLevel();
                    const {enableSunAnimator} = this.optionsForKarto;
                    this.enableSunAnimator = enableSunAnimator;
                    this.displayNav = true;
                    this.$emit('created', map);
                    this.displayPoiMenu = !_.get(this.options.extraMapOptions, 'disablePoiMenu');
                    this.displayGraphicLevelMenu = _.get(this.optionsForKarto.extraMapOptions, 'displayGraphicLevelMenu');
                    this.addNavigation();
                    this.displayTutorial = map.is3dEnabled();
                    const zoom3dThreshold = map.getInitialOptions().zoom3dThreshold;
                    this.eventPack.on(map, {
                        zoom: () => {
                            this.handleViewChange(map.isIn3d() && map.is3dEnabled() && (map.getZoom() >= zoom3dThreshold));
                        },
                        '2d_to_3d': () => {
                            this.handleViewChange(map.is3dEnabled());
                        },
                        '3d_to_2d': () => {
                            this.handleViewChange(false);
                        },
                    });
                } else {
                    this.noWebgl = karto.noWebGl;
                    this.$emit('error');
                }
            } else {
                kartoEngine.Engine.getInstance().removeMap(map);
            }
        });
    },
    beforeDestroy() {
        // to know the component is being destroyed for the result ofasync map initilization above
        this.destroying = true;
        if (this.map) {
            this.eventPack.removeAllListeners();
            karto.setCurrentMap(null);
            kartoEngine.Engine.getInstance().removeMap(this.map);
        }
    },
    methods: {
        addNavigation() {
            const nav = this.$refs.nav;
            this.map.ui.addNavigation(nav);
            this.addZoomEvent();
            if (this.enableGeoloc) {
                this.addGeolocateEvent();
            }
            this.addPitchEvent();
            this.addCompassEvent();
            this.addMode();
            this.addFooter();
        },
        addZoomEvent() {
            const {zoom, zoomIn, zoomOut} = this.$refs;
            this.map.ui.getNavigation().add({
                type: 'Zoom',
                container: zoom,
                zoomIn: {htmlButton: zoomIn},
                zoomOut: {htmlButton: zoomOut},
            });
        },
        addPitchEvent() {
            const {pitch, pitchUp, pitchDown} = this.$refs;
            this.map.ui.getNavigation().add({
                type: 'Pitch',
                container: pitch,
                pitchUp: {htmlButton: pitchUp},
                pitchDown: {htmlButton: pitchDown},
            });
        },
        addCompassEvent() {
            const {compass, compassNeedle, compassLeft, compassRight} = this.$refs;
            this.map.ui.getNavigation().add({
                type: 'Compass',
                container: compass,
                compassLeft: {htmlButton: compassLeft},
                compassNeedle: {htmlButton: compassNeedle},
                compassRight: {htmlButton: compassRight},
            });
        },
        addGeolocateEvent() {
            const {geoloc} = this.$refs;
            this.map.ui.getNavigation().add({
                type: 'Geolocate',
                container: geoloc,
                geolocate: {htmlButton: geoloc},
            });
        },
        addMode() {
            const {mode, modeCheckbox, modeLabel} = this.$refs;
            this.map.ui.addMode(mode);
            this.map.ui.getMode().addInput(modeCheckbox);
            this.map.ui.getMode().addLabel(modeLabel);
        },
        addFooter() {
            this.displayFooter = true;
            const {footer, scale} = this.$refs;
            this.map.ui.addFooter(footer);
            this.map.ui.getFooter().add('Scale', scale);
        },
        onGeolocButtonClick() {
            this.$emit('onGeolocButtonClick');
        },
        setGraphicLevel() {
            if (this.options.displayGraphicLevelMenu) {
                this.map.graphicsPresetsManager.setAutoUpdate(false);
                this.map.graphicsPresetsManager.setCurrentPresetFromName(_.upperFirst(this.defaultGraphicLevel));
            } else {
                this.map.graphicsPresetsManager.setAutoUpdate(true);
            }
            this.map.triggerRepaint();
        },
        handleViewChange(is3D) {
            this.displaySunAnimator = is3D && this.enableSunAnimator;
            this.displayTutorial = is3D;
        },
    },
    template: template(),
};
