const _ = require('lodash');
const UrlFormatter = require('./UrlFormatter');
const DefaultConfiguration = require('./DefaultConfiguration');
const UrlHelper = require('./UrlHelper');
const UrlQueryHelper = require('./UrlQueryHelper');
const LocationsParser = require('./LocationsParser');

const SAVE_SEARCH_URL_OPTION = 'creer-une-alerte';

const searchOptionNameToQueryName = {
    minPrice: 'prix-min',
    maxPrice: 'prix-max',
    minArea: 'surface-min',
    maxArea: 'surface-max',
    page: 'page',
    camera: 'camera',
    newProperty: 'neuf',
    onTheMarket: 'disponible',
    isPreview: 'en-avant-premiere',
    listMode: 'mode',
    mapMode: 'carte',
    limit: 'limite',
    isOnLastFloor: 'dernier-etage',
    has3DModel: 'modelisation-3d',
    isLeading: 'a-la-une',
    highlighted: 'recommande',
    isGroundFloor: 'rez-de-chaussee',
    isNotGroundFloor: 'pas-au-rez-de-chaussee',
    hasPool: 'piscine',
    hasBalcony: 'balcon',
    hasTerrace: 'terrasse',
    hasBalconyOrTerrace: 'balcon-ou-terrasse',
    hasCellar: 'cave',
    hasParking: 'parking',
    hasFirePlace: 'cheminee',
    hasDoorCode: 'digicode',
    hasIntercom: 'interphone',
    hasGarden: 'jardin',
    hasElevator: 'ascenseur',
    workToDo: 'avec-travaux',
    noWorkToDo: 'sans-travaux',
    singleStoreyHouse: 'plain-pied',
    isDisabledPeopleFriendly: 'pmr',
    flatSharing: 'colocation-autorisee',
    isBuildingPlot: 'constructible',
    isExclusiveSaleMandate: 'exclusif',
    hasCaretaker: 'gardien',
    isDuplex: 'duplex',
    hasSeparateToilet: 'toilettes-separees',
    isFurnished: 'meuble',
    isNotFurnished: 'non-meuble',
    hasPhoto: 'photo',
    geocoding: 'geocoding',
    reference: 'reference',
    queryString: 'query-string',
    adsNestedQueryString: 'nested-query-string',
    contactRequestsNestedQueryString: 'contacts-nested-query-string',
    isEligibleForPinelLaw: 'eligible-loi-pinel',
    isEligibleForDenormandieLaw: 'eligible-loi-denormandie',
    energyClassification: 'classification-energetique',
    exportableAd: 'multidiffusable',
    extensionType: 'recherche-etendue',
    isInStudentResidence: 'residence-etudiants',
    isInSeniorResidence: 'residence-seniors',
    isInTourismResidence: 'residence-tourisme',
    isInManagedResidence: 'residence-geree',
    isNotInResidence: 'pas-en-residence',
    isNotLifeAnnuitySale: 'viagers-exclus',
    isLifeAnnuitySaleOnly: 'viagers-uniquement',
    isNotPropertyWithoutUsufruct: 'nue-propriétés-exclus',
    isPropertyWithoutUsufructOnly: 'nue-propriétés-uniquement',
    excludeAgencyNew: '8',
    needProfessionalPict: 'amelioration-photos-professionnelles',
    needVirtualTour: 'amelioration-visites-virtuelles',
    needHomeStaging: 'amelioration-home-staging-virtuels',
    hasNoPhoto: 'annonces-sans-photo',
    hasNoAddress: 'annonces-sans-adresse',
    hasAddress: 'annonces-avec-adresse',
    hasNoPrice: 'annonces-sans-prix',
    minBedrooms: 'chambres-min',
    maxBedrooms: 'chambres-max',
    minGardenSurfaceArea: 'surface-terrain-min',
    maxGardenSurfaceArea: 'surface-terrain-max',
    isPromotedAsExclusive: 'avant-premiere-bienici',
    is3dHighlighted: 'en-avant-en-3d',
    isSold: 'biens-vendus',
    hasToBeBuilt: 'maisons-a-construire',
    hasNotToBeBuilt: 'maisons-a-construire-exclues',
    opticalFiberStatus: 'fibre',
    chargingStations: 'recharge-vehicule-electrique',
    openSaveSearch: SAVE_SEARCH_URL_OPTION,
};
const searchQueryNameToOptionName = UrlQueryHelper.swapKeysValues(searchOptionNameToQueryName);

const sortQueryName = 'tri';
const sortOptionValueToQueryValue = {
    price: 'prix',
    pricePerSquareMeter: 'prixm2',
    surfaceArea: 'surface',
    roomsQuantity: 'pieces',
    relevance: 'pertinence',
    relevanceDev: 'pertinencedev',
    publicationDate: 'publication',
    modificationDate: 'modification',
    views: 'vue',
    followers: 'favoris',
    contactRequests: 'contacts',
    phoneDisplays: 'appels',
    dateOfSale: 'datedevente',
};
const sortQueryValueToOptionValue = UrlQueryHelper.swapKeysValues(sortOptionValueToQueryValue);

const arrayValuesFor = [
    'energyClassification',
];

const numberValuesFor = [
    'minPrice',
    'maxPrice',
    'minBedrooms',
    'maxBedrooms',
    'minArea',
    'maxArea',
    'minGardenSurfaceArea',
    'maxGardenSurfaceArea',
];

const booleanValuesFor = {
    newProperty: true,
    isOnLastFloor: true,
    isGroundFloor: true,
    isNotGroundFloor: true,
    hasPool: true,
    hasBalcony: true,
    hasTerrace: true,
    hasBalconyOrTerrace: true,
    hasCellar: true,
    hasParking: true,
    hasFirePlace: true,
    hasDoorCode: true,
    hasIntercom: true,
    hasGarden: true,
    hasElevator: true,
    workToDo: true,
    noWorkToDo: true,
    singleStoreyHouse: true,
    has3DModel: true,
    flatSharing: true,
    isBuildingPlot: true,
    isExclusiveSaleMandate: true,
    hasCaretaker: true,
    isDuplex: true,
    hasSeparateToilet: true,
    isFurnished: true,
    isNotFurnished: true,
    hasPhoto: true,
    onTheMarket: true,
    isPreview: true,
    isEligibleForPinelLaw: true,
    isEligibleForDenormandieLaw: true,
    exportableAd: true,
    isInStudentResidence: true,
    isInSeniorResidence: true,
    isInTourismResidence: true,
    isInManagedResidence: true,
    isNotInResidence: true,
    isNotLifeAnnuitySale: true,
    isLifeAnnuitySaleOnly: true,
    isNotPropertyWithoutUsufruct: true,
    isPropertyWithoutUsufructOnly: true,
    needProfessionalPict: true,
    needVirtualTour: true,
    needHomeStaging: true,
    hasNoPhoto: true,
    hasNoAddress: true,
    hasAddress: true,
    hasNoPrice: true,
    isPromotedAsExclusive: true,
    hasToBeBuilt: true,
    hasNotToBeBuilt: true,
    opticalFiberStatus: 'deploye',
    chargingStations: true,
    isDisabledPeopleFriendly: true,
    isSold: true,
};
const jsonifyValuesFor = {
    geocoding: true,
};

const searchValueToQueryValue = {
    listMode: {
        list: 'liste',
        gallery: 'galerie',
        map: 'carte',
    },
    mapMode: {
        enabled: 'visible',
        disabled: 'invisible',
    },
    extensionType: {
        none: 'non-etendue',
        extended: 'etendue',
        extendedOnly: 'etendue-seulement',
        extendedIfNoResult: 'etendue-si-pas-de-resultat',
    },
    excludeAgencyNew: {
        true: 'D',
    },
};

const MIN_ROOMS_REGEXP = new RegExp('(\\d+)-pi[èe]ces?-et-plus');
const MAX_ROOMS_REGEXP = new RegExp('(\\d+)-pi[èe]ces?-et-moins');
const MIN_MAX_ROOMS_REGEXP = new RegExp('de-(\\d+)-a-(\\d+)-?pi[èe]ces?');
const SAME_ROOMS_REGEXP = new RegExp('(\\d+)-pi[èe]ces?');

module.exports = {
    makeSearchUrl,
    booleanValuesFor,
    parseSearchLocation,
    sortToUrlParameter,
    getSearchOptionNameFromQueryName,
};

function getSearchOptionNameFromQueryName(queryName) {
    return searchOptionNameToQueryName[queryName];
}

function fromBoolean(value, isForward) {
    if (!isForward) {
        value = !value;
    }

    if (_.isArray(value)) {
        return _.map(value, function (subValue) {
            return fromBoolean(subValue, isForward);
        }).join('-');
    } else if (value) {
        return 'oui';
    } else {
        return 'non';
    }
}

function sortToUrlString(search) {
    const sortBy = sortOptionValueToQueryValue[search.sortBy];
    if (search.sortBy && search.sortOrder && sortBy) {
        return sortBy + '-' + search.sortOrder;
    } else {
        return null;
    }
}

function sortToUrlParameter(search) {
    const sortUrlString = sortToUrlString(search);
    if (sortUrlString) {
        return {[sortQueryName]: sortUrlString};
    } else {
        return null;
    }
}

function parseSortFromUrl(sortParameter) {
    if (_.isArray(sortParameter)) { //multiple sort on url
        sortParameter = _.first(sortParameter);
    }
    if (_.isString(sortParameter)) {
        const sortArray = sortParameter.split('-');
        return {
            sortBy: sortQueryValueToOptionValue[sortArray[0]],
            sortOrder: sortArray[1],
        };
    } else {
        return {};
    }
}

//it looks like options and defaultOptions are not in the same format somehow...
function makeSearchUrl(options, defaultOptions, prefix) {
    let mapMode = 'enabled';
    if (options.fullList === true) {
        mapMode = 'disabled';
    }

    defaultOptions = defaultOptions || DefaultConfiguration.search;

    defaultOptions.mapMode = 'enabled';
    if (defaultOptions.fullList === true) {
        defaultOptions.mapMode = 'disabled';
    }
    if (!prefix) {
        prefix = 'recherche';
    }
    let searchUrl = '/' + prefix + '/';
    const searchOptions = options.search || options;
    if (searchOptions.filterType) {
        searchUrl += UrlFormatter.filterTypeToUrl(searchOptions.filterType) + '/';
    }

    searchUrl += getLocationPart();
    searchUrl += urlFilters();
    searchUrl = encodeURI(searchUrl);

    let queryOpts = [];
    const sortStr = sortToUrlString(searchOptions);
    const defaultSortStr = sortToUrlString(defaultOptions);
    if (sortStr && sortStr != defaultSortStr) {
        queryOpts.push(sortQueryName + '=' + sortStr);
    }

    if (options.camera) {
        queryOpts.push('camera=' + UrlFormatter.cameraToUrl(options.camera));
    }
    queryOpts = _(searchOptions)
        .omit([ //already done above or in urlFilters()
            'location',
            'propertyType',
            'filterType',
            'minRooms',
            'maxRooms',
            'sortBy',
            'sortOrder',
            'zoneIds',
            'suggestion',
            'camera', //shouldn't be present anyway
        ])
        .extend({
            mapMode,
            listMode: options.listMode,
        })
        .map(function (value, name) {
            const urlName = searchOptionNameToQueryName[name];
            if (urlName && null != value && '' !== value && !_.isEqual(value, defaultOptions[name])) {
                const valueMapping = searchValueToQueryValue[name];
                if (valueMapping) {
                    value = valueMapping[value];
                } else if (booleanValuesFor[name] != null) {
                    value = fromBoolean(value, booleanValuesFor[name]);
                }
                if (null != value && '' !== value) {
                    if (jsonifyValuesFor[name]) {
                        return urlName + '=' + encodeURIComponent(JSON.stringify(value));
                    } else {
                        return urlName + '=' + encodeURIComponent(value);
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        })
        .compact()
        .concat(queryOpts)
        .value();
    if (queryOpts.length > 0) {
        searchUrl += '?' + queryOpts.join('&');
    }
    return searchUrl;

    function getLocationPart() {
        let locationName;
        if (searchOptions.locationNames) {
            locationName = _.map(searchOptions.locationNames, UrlHelper.slugify).join(',');
        } else {
            locationName = _.map(searchOptions.locations, slugifyLocation).join(',');
        }
        if (!locationName && !_.isEmpty(searchOptions)) {
            locationName = 'france';
        }

        return locationName;
    }

    function urlFilters() {
        let result = '';
        if (searchOptions.propertyType && searchOptions.propertyType.length
            && !_.isEqual(_.sortBy(searchOptions.propertyType), _.sortBy(defaultOptions.propertyType))) {
            result += '/' + UrlFormatter.propertyTypesToUrl(searchOptions.propertyType, defaultOptions.propertyType);
        }

        const isMinRoomDefaultOrNull = searchOptions.minRooms !== defaultOptions.minRooms;
        const isMaxRoomDefaultOrNull = searchOptions.maxRooms !== defaultOptions.maxRooms;
        if (isMinRoomDefaultOrNull || isMaxRoomDefaultOrNull) {
            if (searchOptions.maxRooms === 1 && searchOptions.propertyType == 'flat') {
                result += '/studio';
            } else if (searchOptions.minRooms) {
                if (searchOptions.maxRooms && searchOptions.maxRooms > searchOptions.minRooms) {
                    result += '/de-' + searchOptions.minRooms + '-a-' + searchOptions.maxRooms + '-pieces';
                } else if (!searchOptions.maxRooms) {
                    result += '/' + formatRoomsSlugInFrench(searchOptions.minRooms) + '-et-plus';
                } else {
                    result += '/' + formatRoomsSlugInFrench(searchOptions.maxRooms);
                }
            } else if (searchOptions.maxRooms) {
                result += '/' + formatRoomsSlugInFrench(searchOptions.maxRooms) + '-et-moins';
            }
        }
        return result;
    }
}

function formatRoomsSlugInFrench(count) {
    return count + '-piece' + (count > 1 ? 's' : '');
}

function slugifyLocation(location) {
    let name;
    if (_.isString(location)) {
        name = location;
    } else {
        name = location.getUrlValue();
    }
    return UrlHelper.slugify(name);
}

function parseSearchLocation(parsedUrlAndQuerystring, prefix) {
    const pathname = parsedUrlAndQuerystring.pathname;
    const parsedQuery = parsedUrlAndQuerystring.query;
    const pathnameWithoutPrefix = pathname.replace(new RegExp('^/' + prefix), '');
    const [/*recherche*/, filterType, locationNamesStr, ...additionalParameters] = pathnameWithoutPrefix.split('/');

    const searchConfig = UrlQueryHelper.getConfigFromParsedQuery({
        parsedQuery,
        queryNameToOptionName: searchQueryNameToOptionName,
        valueToQueryValue: searchValueToQueryValue,
        booleanValuesFor,
        numberValuesFor,
        jsonifyValuesFor,
        arrayValuesFor,
    });

    if (searchConfig.mapMode == 'enabled') {
        searchConfig.fullList = false;
    } else if (searchConfig.mapMode == 'disabled') {
        searchConfig.fullList = true;
    }
    delete searchConfig.mapMode;
    if (searchConfig.excludeAgencyNew == 'true') {
        searchConfig.excludeAgencyNew = true;
    }
    if (searchConfig.isSold) {
        searchConfig.sortBy = 'dateOfSale';
        searchConfig.sortOrder = 'desc';
    }
    _.extend(searchConfig, parseSortFromUrl(parsedQuery[sortQueryName]));
    searchConfig.filterType = UrlFormatter.filterTypeFromUrl(filterType);
    const locationNames = LocationsParser.parse(decodeURIComponent(locationNamesStr || ''));
    searchConfig.locationNames = locationNames;
    _.each(additionalParameters, additionalParameterInPath => {
        if (!additionalParameterInPath) {
            return;
        }
        const value = decodeURIComponent(additionalParameterInPath);
        const minRooms = MIN_ROOMS_REGEXP.exec(value);
        const maxRooms = MAX_ROOMS_REGEXP.exec(value);
        const minMaxRooms = MIN_MAX_ROOMS_REGEXP.exec(value);
        const sameRooms = SAME_ROOMS_REGEXP.exec(value);

        if (minRooms) {
            searchConfig.minRooms = +minRooms[1];
        } else if (maxRooms) {
            searchConfig.maxRooms = +maxRooms[1];
        } else if (minMaxRooms) {
            searchConfig.minRooms = +minMaxRooms[1];
            searchConfig.maxRooms = +minMaxRooms[2];
        } else if (sameRooms) {
            searchConfig.minRooms = +sameRooms[1];
            searchConfig.maxRooms = +sameRooms[1];
        } else if (value == 'studio') {
            searchConfig.maxRooms = 1;
        } else {
            searchConfig.propertyType = UrlFormatter.propertyTypesFromUrl(value);
        }
    });
    if (SAVE_SEARCH_URL_OPTION in parsedQuery) {
        _.extend(searchConfig, {openSaveSearch: true});
    }
    return searchConfig;
}
