const template = require('./templates/mapFilteredView.jade');
const View = require('./views/View');
const $ = require('jquery');
const _ = require('lodash');
const SideMapViewSingleton = require('./views/SideMapViewSingleton');

module.exports = class MapFilteredView extends View {
    constructor(options) {
        super({
            template,
        });
        this.isChecked = Boolean(options.limit);
        this.useKarto = options.useKarto;
    }

    show(options) {
        if (!this._isShown && options.showMap) {
            this._isShown = true;
            this.options = options;
            if (SideMapViewSingleton.get().isShown()) {
                this._show(options);
            } else {
                this._onToggledMapListener = _.bind(this._onToggledMap, this);
                SideMapViewSingleton.get().on('toggledMap', this._onToggledMapListener);
            }
        }
    }

    _onToggledMap(toggled) {
        if (toggled) {
            this._show(this.options);
        } else {
            this.hide();
        }
    }

    _show(options) {
        if (!this.isShown()) {
            this.$container = this.useKarto ? $(SideMapViewSingleton.get().map.getContainer())
                : $(SideMapViewSingleton.get().map.getDiv());
            const isChecked = Boolean(options.limit || (options.search && options.search.limit));
            this.options = options;
            this.isChecked = isChecked || this.isChecked;
            super.show(options);
            this.$input = this.$element.find('input');
            this.$input.prop('checked', this.isChecked);
            this._bindButton();
        }
    }

    hide() {
        if (this._isShown) {
            this._unbindButton();
            if (this._onToggledMapListener) {
                SideMapViewSingleton.get().removeListener('toggledMap', this._onToggledMapListener);
                delete this._onToggledMapListener;
            }
            super.hide();
            this._isShown = false;
        }
    }

    update() {
        this.hide();
        this.show(this.options);
    }

    _toggleButton() {
        this.setValue(!this.isChecked);
    }

    setValue(checked) {
        if (this.isShown()) {
            this.isChecked = checked;
            this.$input.prop('checked', this.isChecked);
        }
    }

    _bindButton() {
        this.buttonClickListener = _.bind(this._handleClick, this);
        this.$input.on('change', this.buttonClickListener);
    }

    _unbindButton() {
        if (this.$input) {
            this.$input.off('change', this.buttonClickListener);
        }
        this.buttonClickListener = null;
    }

    _handleClick(e) {
        this._toggleButton();
        this.emit('mapFilterChange', this.isChecked);
        e.preventDefault();
        e.stopPropagation();
    }
};
