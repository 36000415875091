const _ = require('lodash');
const i18nMixin = require('../../vue/components/mixins/i18n');
const {
    FILTERED_KEY_NAME,
    isFiltered,
} = require('../../utils/filteredFields');
const template = require('./ContactText.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'ContactText.',
        }),
    ],
    props: {
        name: {
            type: String,
            default: undefined,
        },
        value: {
            type: [String, Object],
            required: true,
        },
        strong: Boolean,
    },
    computed: {
        title() {
            let title;
            const {name} = this;
            if (name) {
                title = name + '\u00a0: ';
            }
            return title;
        },
        valueComponent() {
            return 'span';
        },
        valueModifiers() {
            return _.pick(this, 'strong');
        },
        isFiltered() {
            return isFiltered(this.value);
        },
        filterReason() {
            const {value} = this;
            return this.t(_.get(value, FILTERED_KEY_NAME));
        },
    },
    template: template(),
};
