const _ = require('lodash');
const Account = require('../authentication/Account');
const AccountManagementView = require('../authentication/AccountManagementView');
const ApplicationPage = require('./ApplicationPage');
const Title = require('./Title');
const ViewManager = require('../views/ViewManager');
const {i18n: {translate}} = require('fack');

module.exports = class AccountManagementPage extends ApplicationPage {
    constructor(options = {}) {
        const configuration = {
            name: 'accountManagement',
            title: getTranslatedTitle(options),
            isRegistrationRequired: true,
            disableReloadPageOnLogin: true,
            account: options.account,
            myAccount: options.myAccount,
            isPro: options.mode == 'pro',
            gtmCategory: 'Account',
        };
        super(configuration);
        this.configuration = configuration;
    }

    getTitle() {
        return this.options.title;
    }

    open(options) {
        const defaultAccount = this.configuration.myAccount ? Account.getAuthenticatedAccount() : options.account;
        options = _.extend({}, options, this.configuration);
        _.defaults(options, {
            account: defaultAccount,
        });
        if (!this.configuration.myAccount && defaultAccount.display_name) {
            options.title += ' ' + defaultAccount.display_name;
            Title.setTitle(options.title);
        }
        this.options = options;
        ViewManager.openViews(this.getViews(options), _.extend({
            headerTitle: options.title || null,
        }, options));
    }

    _loadData(options, callback) {
        if (this.configuration.myAccount) {
            callback(null, options);
        } else {
            Account.get(options.accountId, function (err, account) {
                // todo: redirect to 404 page on account not found
                options.account = account;
                callback(err, options);
            });
        }
    }

    parseUrl(url, userId) {
        if (!this.configuration.myAccount) {
            return {
                accountId: userId,
            };
        }
    }

    getMiddleViews(options) {
        return new AccountManagementView({
            title: options.title,
            isPro: this.configuration.isPro,
        });
    }

    isPageAccessAllowed() {
        return (Account.isAccountModifier() || this.configuration.myAccount);
    }

    getUrlPattern() {
        return this.configuration.myAccount ? '/modifier-mon-compte' : '/modification-du-compte-{id}';
    }
};

function getTranslatedTitle(options) {
    return options.title || options.myAccount ?
        translate('accountManagementView.myAccountTitle') : translate('accountManagementView.otherAccountTitle');
}
