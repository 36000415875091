const _ = require('lodash');
const template = require('./CommercialAd.jade');
const safeFrameAdUtils = require('../../utils/safeFrameAdUtils');
const sessionStorage = require('../../utils/sessionStorage');
const AdvertisementsHelper = require('../../views/utils/AdvertisementsHelper');
const BrowserDetect = require('browser-detect');
const SafeFrame = require('../../SafeFrame');
const ServerConfig = require('../../ServerConfig');
const ApplicationConfig = require('../../app/ApplicationConfig');
const Options = require('../../Options');

let previousPage;
// @vue/component
module.exports = {
    inject: ['page', 'searchCriteria'],
    props: {
        type: {
            type: String,
            default: AdvertisementsHelper.DEFAULT_ADVERTISEMENT_TYPE,
        },
        spot: {
            type: String,
            required: true,
        },
        safeFrame: Boolean,
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            enabledSafeFrameAd: false,
            safeFrameHeight: '0px',
            safeFrameCommand: null,
            safeFrameRendered: false,
            scollableDivSelector: '.searchResultsContainerConstantWidth',
        };
    },
    computed: {
        advertisementData() {
            const {page, type, searchCriteria, safeFrame} = this;
            return AdvertisementsHelper.getAdsData({
                page,
                type,
                searchCriteria: searchCriteria || {},
                safeFrame,
            });
        },
        url() {
            const {spot, index, safeFrame} = this;
            return this.advertisementData.toAdvertisementUrl(spot, index, safeFrame);
        },
        width() {
            return this.advertisementData.width;
        },
        height() {
            return this.advertisementData.height;
        },
        isEmbeddedMode() {
            return ApplicationConfig.isOnPartnersDomain || Options.get('isInEmbeddedMode');
        },
        isAdClosed() {
            return sessionStorage.getValue('closedAd');
        },
    },
    watch: {
        safeFrameHeight() {
            this.updatePageMainContainer();
        },
        safeFrameCommand() {
            const {safeFrameCommand, updateSafeFrameHeight, handleScrollEventListener, updateSafeFrameContainerVisibility} = this;
            const isExpOvr = safeFrameCommand === 'exp-ovr';
            const isCollapse = safeFrameCommand === 'collapse';
            if (isExpOvr) {
                updateSafeFrameHeight();
                if (sessionStorage.getValue('adDisplayed')) {
                    updateSafeFrameContainerVisibility();
                    // timeout is needed to prevent bug in scrolling after page is loaded and ad not loaded yet
                    setTimeout(() => {
                        handleScrollEventListener('add');
                    }, 1000);
                }
            } else if (isCollapse) {
                updateSafeFrameHeight();
                handleScrollEventListener('remove');
            }
        },
        safeFrameRendered() {
            sessionStorage.waitForItem('adDisplayed', this.page, (err, value) => {
                if (err) {
                    console.error(err);
                } else if (value && document.getElementById('safeframeAd')) {
                    if (BrowserDetect.isMobile()) {
                        this.safeFrameCommand = 'collapse';
                        safeFrameAdUtils.updateStyleForMobileAdDisplay();
                        safeFrameAdUtils.displaySafeFrameContainerOnMobile({page: this.page, state: this.safeFrameCommand});
                    } else {
                        safeFrameAdUtils.updateDropdownStyle();
                    }
                }
            });
        },
    },
    created() {
        this.handleScrollEventListener('remove');
        // reset adDisplayed session item
        sessionStorage.remove('adDisplayed');
        this.isSafeFrameAdEnabled();
        // ensure that all selectors that will be modified are present on the page
        const areAllSelectorsPresent = safeFrameAdUtils.areAllSelectorsPresent(this.page);
        if (this.safeFrame && !this.isAdClosed && this.enabledSafeFrameAd && areAllSelectorsPresent && !this.isEmbeddedMode) {
            // kill previous setIntervals
            if (previousPage) {
                clearInterval(window[previousPage]);
            }
            this.initializeSafeFrame();
            previousPage = this.page;
        }
    },
    methods: {
        initializeSafeFrame() {
            // ensure that no safeFrame is loaded
            safeFrameAdUtils.destroySafeFrame();
            SafeFrame(() => {
                // eslint-disable-next-line no-undef
                new $sf.host.Config({
                    // Should be absolute path to render file hosted on CDN
                    renderFile: this.url,
                    positions: {},
                    onPosMsg: (id, cmd) => {
                        this.safeFrameCommand = cmd;
                    },
                    onEndPosRender: () => {
                        this.safeFrameRendered = true;
                    },
                });
            });
        },
        triggerAdCollapse() {
            safeFrameAdUtils.geomUpdate();
        },
        handleScrollEventListener(action) {
            const {scollableDivSelector, triggerAdCollapse} = this;
            const searchResultsContainer = document.querySelector(scollableDivSelector);
            const eventListenerAction = action === 'add' ? 'addEventListener' : 'removeEventListener';
            if (searchResultsContainer) {
                searchResultsContainer[eventListenerAction]('scroll', triggerAdCollapse);
            } else {
                window[eventListenerAction]('scroll', triggerAdCollapse);
            }
        },
        updatePageMainContainer() {
            if (sessionStorage.getValue('adDisplayed')) {
                const {page, safeFrameHeight, safeFrameCommand} = this;
                safeFrameAdUtils.updatePageMainContainer(page, safeFrameHeight, safeFrameCommand);
            }
        },
        updateSafeFrameContainerVisibility() {
            if (!BrowserDetect.isMobile()) {
                const safeFrameContentFullWidth = document.getElementsByClassName('safeFrameContentFullWidth')[0];
                safeFrameContentFullWidth.style.visibility = 'visible';
            }
        },
        isSafeFrameAdEnabled() {
            if (ServerConfig.config.enabledPagesForSafeFrameAd) {
                const enabledPages = ServerConfig.config.enabledPagesForSafeFrameAd.split(',');
                this.enabledSafeFrameAd = _.includes(enabledPages, this.page);
            }
        },
        updateSafeFrameHeight() {
            this.safeFrameHeight = document.getElementById('safeframeAd').style.height;
        },
    },
    template: template(),
};
