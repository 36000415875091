const LocalDbManager = require('../../utils/localDbManager/LocalDbManager');
const localStorage = require('../../utils/localStorage/LocalStorage');

const INDEXED_DB_DATABASE_NAME = 'kimono';
const INDEXED_DB_DATABASE_VERSION = 1;
const ADS_PRINTS_OBJECT_STORE_NAME = 'adsPrints';
const INDEXED_DB_DATABASE_TO_REMOVE = 'kimonoDatabase';

class AdsPrintsStorageManager extends LocalDbManager {
    constructor() {
        super({
            dbName: INDEXED_DB_DATABASE_NAME,
            dbVersion: INDEXED_DB_DATABASE_VERSION,
            listObjectsToCreate: [
                {
                    objectStoreName: ADS_PRINTS_OBJECT_STORE_NAME,
                    objectStoreOptions: {
                        keyPath: 'realEstateAdId',
                    },
                    indexList: [
                        {
                            indexName: 'realEstateAdId_uniq_key',
                            indexKeyPath: 'realEstateAdId',
                            indexOptions: {
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            dbToDelete: INDEXED_DB_DATABASE_TO_REMOVE,
        });
    }

    _updateLocalStorage({objectName, newData, dataToKeep}) {
        const newAdsPrintedInLocalStorage = {};
        for (const row of [...newData, ...dataToKeep]) {
            const {realEstateAdId, expirationTime} = row;
            newAdsPrintedInLocalStorage[realEstateAdId] = expirationTime;
        }
        localStorage.setValue(objectName, newAdsPrintedInLocalStorage);
    }

    _getAllDataFromLocalStorage(objectName) {
        const dataFromLocalStorage = localStorage.getValue(objectName) || {};
        const printedAds = [];
        for (const [realEstateAdId, expirationTime] of Object.entries(dataFromLocalStorage)) {
            printedAds.push({
                realEstateAdId,
                expirationTime,
            });
        }
        return printedAds;
    }
}

module.exports = AdsPrintsStorageManager;
