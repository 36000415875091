const $ = require('jquery');
const _ = require('lodash');
const facadeCameraControllerTemplate = require('./templates/components/facadeCameraController.jade');
const facadeCameraEditorTemplate = require('./templates/components/facadeCameraEditor.jade');
const View = require('./views/View');
const Options = require('./Options');
const savedOptions = Options.read();
const moment = require('../common/momentFr');
const EventPack = require('./utils/EventPack');

module.exports = class FacadeCameraControllerView extends View {
    constructor(options) {
        super();
        this._mapEventPack = new EventPack();
        options = options || {};
        this._currentFacade = 0;
        this._userForceLotsVisibility = false;
        this._isBtnLotsVisible = false;
        this._sunAnimator = options.sunAnimator;
        if (options.map) {
            this.setMap(options.map);
        }
        this._cameraManager = options.cameraManager;
        this.useKarto = options.useKarto;
        this.mapContainer = this.useKarto ? this.map.getContainer() : this.map.getDiv();
    }

    getUserForceLotsVisibility() {
        return this._userForceLotsVisibility;
    }

    show(options) {
        this.hide();
        if (this._sunAnimator) {
            this._backupDateAndDayTimePercent = this._sunAnimator.getDateAndDayTimePercent();
        }
        this._clearHideAnimTimer();
        this._clearUpdateDisplayFacadeTimer();
        this._$facadeCameraController = this.renderTemplate(facadeCameraControllerTemplate);
        $(this.mapContainer).append(this._$facadeCameraController);
        this._facadeCameras = options.facadeCameras;
        this._facadeCamerasId = options.facadeCamerasId;
        this._$facadeCameraController.addClass('kimono-isOpen');
        this._lowerBuildingAround = options.lowerBuildingAround;
        if (this._programmeId != options.programmeId) {
            this._programmeId = options.programmeId;
            this._currentFacade = 0;
        }
        const savedOptions = Options.read();
        if (savedOptions.facadeCameraEditorEnabled) {
            this.initFacadeCameraEditor();
        }
        this.setFacadeCameraKeys(this._facadeCameras);
        this._bindBtn();
        this._updateBtnVisibleLots();
        this._updateDisplayFacadeBtn();
        this._updateDayTimeInMap();
        this._updateIsIn3d();
        this.centerOnProgramme();
    }

    initFacadeCameraEditor() {
        this._$facadeCameraEditor = this.renderTemplate(facadeCameraEditorTemplate);
        $(this.mapContainer).append(this._$facadeCameraEditor);
        this._$facadeCameraCodeGenerateContainer = this._$facadeCameraEditor.find('.facadeCameraCodeGenerateContainer');
        this._$newFacadeCameraBtn = this._$facadeCameraEditor.find('.newFacadeCameraBtn');
        this._$facadeCameraListContainer = this._$facadeCameraEditor.find('.facadeCameraListContainer');
        this._$setDayTimeGlobalBtn = this._$facadeCameraEditor.find('.setDayTimeGlobalBtn');

        this._$toggleFacadeCameraCodeGenerateContainer = this._$facadeCameraEditor.find('.toggleFacadeCameraCodeGenerateContainer');
        this._$toggleFacadeCameraCodeGenerateContainer.on('click', _.bind(function () {
            this._$facadeCameraCodeGenerateContainer.toggle();
        }, this));
        this._$newFacadeCameraBtn.on('click', _.bind(this.addNewCamera, this));
        this._$setDayTimeGlobalBtn.on('click', _.bind(this.setDayTimeToAllCameras, this));
        this.updateFacadeCamerasEditorContainer();
        this._$facadeCameraEditor.addClass('kimono-isOpen');
    }

    setMap(map) {
        this._mapEventPack.removeAllListeners();
        this.map = map;
        if (map) {
            this._isIn3d = map['3d'];
            this._mapEventPack.on(map, {
                on3dchange: (isIn3d) => {
                    if (this._isIn3d != isIn3d) {
                        this._isIn3d = isIn3d;
                        this._updateIsIn3d();
                    }
                },
                resize: () => {
                    this.setFacadeCameraKeys(this._facadeCameras);
                    this._updateFacadeCamera(true);
                },
            });
        }
        this._updateBuildingHeightIfNeeded();
    }

    setDayTimeToCamera(camera) {
        if (this._sunAnimator) {
            const dateAndDayTimePercent = this._sunAnimator.getDateAndDayTimePercent();
            camera.dateAndDayTimePercent = {
                date: moment(dateAndDayTimePercent.date).format('YYYY MM DD'),
                dayTimePercent: dateAndDayTimePercent.dayTimePercent,
            };
            this._$facadeCameraCodeGenerateContainer.html(this.convertFacadeCamerasInJsonText());
        }
    }

    setDayTimeToAllCameras() {
        _.each(this._facadeCameras, camera => {
            this.setDayTimeToCamera(camera);
        });
    }

    addNewCamera() {
        if (this.map && this.map._renderer) {
            this._facadeCameras.push(this._getFacadeCameraOptions());
            this.updateFacadeCamerasEditorContainer();
        }
    }

    _getFacadeCameraOptions() {
        if (this._cameraManager) {
            return this._cameraManager.getFacadeCameraOptions();
        }
        return null;
    }

    convertFacadeCamerasInJsonText() {
        let _text = '"' + this._facadeCamerasId + '":{';
        if (this._lowerBuildingAround) {
            _text += 'lowerBuildingAround: true, ';
        }
        _text += 'facadeCameras:[';
        _.each(this._facadeCameras, function (facadeCamera) {
            _text += '{';
            if (facadeCamera.dateAndDayTimePercent) {
                const date = facadeCamera.dateAndDayTimePercent.date;
                _text += 'dateAndDayTimePercent: {';
                _text += "date: '" + date + "', ";
                _text += 'dayTimePercent: ' + facadeCamera.dateAndDayTimePercent.dayTimePercent + ', ';
                _text += '},';
            }
            _text += 'cameraConfig: {';
            _text += 'lat: ' + facadeCamera.cameraConfig.lat + ',';
            _text += 'lon: ' + facadeCamera.cameraConfig.lon + ',';
            _text += 'zoom: ' + facadeCamera.cameraConfig.zoom + ',';
            _text += 'theta: ' + facadeCamera.cameraConfig.theta + ',';
            _text += 'phi: ' + facadeCamera.cameraConfig.phi + ',';
            if (facadeCamera.cameraConfig.originalScreenWidth) {
                _text += 'originalScreenWidth: ' + facadeCamera.cameraConfig.originalScreenWidth + ',},},';
            } else {
                _text += '},},';
            }
            _text += '<br>';
        });
        _text += '],},';
        return _text;
    }

    updateFacadeCamerasEditorContainer() {
        this._$facadeCameraListContainer.empty();
        _.each(this._facadeCameras, (facadeCamera, index) => {
            const $facadeCameraActionsContainer = $("<div class='facadeCameraActionsContainer'></div>");
            const $btnFacadeCamera = $("<div class='btn btn-default'>Camera " + (index + 1) + '</div>');
            const $updateFacadeCamera = $("<div class='btn btn-default'><i class='fa fa-refresh'></i></div>");
            const $deleteFacadeCamera = $("<div class='btn btn-default'><i class='fa fa-trash-o'></i></div>");
            const $dayTimeFacadeCamera = $("<div class='btn btn-default'><i class='fa fa-sun-o'></i></div>");

            $dayTimeFacadeCamera.on('click', () => {
                this.setDayTimeToCamera(this._facadeCameras[index]);
            });

            $btnFacadeCamera.on('click', () => this.selectFacadeCamera(index));
            $updateFacadeCamera.on('click', () => {
                this._facadeCameras[index] = this._getFacadeCameraOptions();
                this.updateFacadeCamerasEditorContainer();
            });
            $deleteFacadeCamera.on('click', () => {
                this._facadeCameras.splice(index, 1);
                this.updateFacadeCamerasEditorContainer();
            });
            $facadeCameraActionsContainer.append($btnFacadeCamera);
            $facadeCameraActionsContainer.append($updateFacadeCamera);
            $facadeCameraActionsContainer.append($dayTimeFacadeCamera);
            $facadeCameraActionsContainer.append($deleteFacadeCamera);
            this._$facadeCameraListContainer.append($facadeCameraActionsContainer);
        });
        this.setFacadeCameraKeys(this._facadeCameras);
        if (this._$facadeCameraCodeGenerateContainer && this._$facadeCameraCodeGenerateContainer.length) {
            this._$facadeCameraCodeGenerateContainer.html(this.convertFacadeCamerasInJsonText());
        }
    }

    hide(options, cb = _.noop) {
        if (this._$facadeCameraController) {
            this._unbindBtn();
            this._$facadeCameraController.addClass('kimono-isClose');
            if (this._$facadeCameraEditor) {
                this._$facadeCameraEditor.remove();
            }
            this._clearHideAnimTimer();
            this._hideAnimTimerId = setTimeout(() => {
                this._clearDom();
                this._hideAnimTimerId = null;
            }, 500);
            if (this._sunAnimator && this._backupDateAndDayTimePercent) {
                this._sunAnimator.animateDateAndDayTimePercent(this._backupDateAndDayTimePercent);
                delete this._backupDateAndDayTimePercent;
            }
        }
        cb();
    }

    _clearDom() {
        if (this._$facadeCameraController) {
            this._$facadeCameraController.remove();
            this._$facadeCameraController = null;
        }
    }

    _clearHideAnimTimer() {
        if (this._hideAnimTimerId != null) {
            this._clearDom();
            clearTimeout(this._hideAnimTimerId);
            this._hideAnimTimerId = null;
        }
    }

    _clearUpdateDisplayFacadeTimer() {
        if (this._updateDisplayFacadeBtnId != null) {
            clearTimeout(this._updateDisplayFacadeBtnId);
            this._updateDisplayFacadeBtnId = null;
        }
    }

    clearProgrammeId() {
        this._programmeId = null;
        this.hide();
    }

    toggleBtnVisibleLots(visible) {
        this._isBtnLotsVisible = visible;
        this._updateBtnVisibleLots();
    }

    _updateBtnVisibleLots() {
        if (this._$facadeCameraController) {
            this._$facadeCameraController.toggleClass('kimono-withVisibleLots', this._isBtnLotsVisible);
        }
    }

    _bindBtn() {
        //Bind toggleLotsVisibilityBtn
        this._$toggleLotsVisibilityBtn = this._$facadeCameraController.find('.toggleLotsVisibilityBtn');
        this._toggleLotsVisibilityBtnHandler = _.bind(this._toggleFacadeVisibility, this);
        this._$toggleLotsVisibilityBtn.on('click', this._toggleLotsVisibilityBtnHandler);
        //Bind kimono-nextBtn
        this._$nextCameraKeyBtn = this._$facadeCameraController.find('.kimono-nextBtn');
        this._nextCameraKeyBtnHandler = _.bind(this._selectNextFacade, this);
        this._$nextCameraKeyBtn.on('click', this._nextCameraKeyBtnHandler);
        //Bind previousBtn
        this._$previousCameraKeyBtn = this._$facadeCameraController.find('.kimono-previousBtn');
        this._previousCameraKeyBtnHandler = _.bind(this._selectPreviousFacade, this);
        this._$previousCameraKeyBtn.on('click', this._previousCameraKeyBtnHandler);
        //Bind mapStabilized
    }

    _unbindBtn() {
        //Unbind toggleLotsVisibilityBtn
        this._$toggleLotsVisibilityBtn.off('click', this._toggleLotsVisibilityBtnHandler);
        this._toggleLotsVisibilityBtnHandler = null;
        //Unbind previousBtn
        this._$previousCameraKeyBtn.off('click', this._previousCameraKeyBtnHandler);
        this._previousCameraKeyBtnHandler = null;
        //Unbind nextBtn
        this._$nextCameraKeyBtn.off('click', this._nextCameraKeyBtnHandler);
        this._nextCameraKeyBtnHandler = null;
    }

    _selectNextFacade() {
        this.selectFacadeCamera(this._currentFacade + 1);
    }

    _selectPreviousFacade() {
        this.selectFacadeCamera(this._currentFacade - 1);
    }

    _updateIsIn3d() {
        if (this._$facadeCameraController) {
            this._$facadeCameraController.toggle(this._isIn3d);
        }
        if (this._$facadeCameraEditor) {
            this._$facadeCameraEditor.toggle(this._isIn3d);
        }
    }

    _toggleFacadeVisibility() {
        this._userForceLotsVisibility = !this._userForceLotsVisibility;
        this._updateDisplayFacadeBtnDelayed();
    }

    _updateDisplayFacadeBtnDelayed() {
        const $displayFacadeBtn = this._$toggleLotsVisibilityBtn;
        $displayFacadeBtn.addClass('kimono-isHidden');
        this._clearUpdateDisplayFacadeTimer();
        this._updateDisplayFacadeBtnId = setTimeout(_.bind(this._updateDisplayFacadeBtn, this), 200);
    }

    _updateDisplayFacadeBtn() {
        const $displayFacadeBtn = this._$toggleLotsVisibilityBtn;
        if ($displayFacadeBtn) {
            const visible = this._userForceLotsVisibility;
            const text = visible ? 'Masquer' : 'Afficher';
            $displayFacadeBtn.find('span').text(text);
            $displayFacadeBtn.removeClass('kimono-isHidden');
            $displayFacadeBtn.addClass('kimono-isActive');
        }
        this.emit('facadeVisibilityChanged', this._userForceLotsVisibility);
        this._updateDisplayFacadeBtnId = null;
    }

    setFacadeCameraKeys(facadeCameras) {
        this._facadeCameras = facadeCameras;
        // Create keys from camera config
        const defaultMapWidth = 1600;
        const currentScreenWidth = Math.max($(this.mapContainer).innerWidth(), 100);
        this._facadeCameraKeys = [];
        _.each(facadeCameras, ({cameraConfig}) => {
            const scaleScreenWidth = (cameraConfig.originalScreenWidth || defaultMapWidth) / currentScreenWidth;
            let {zoom} = cameraConfig;
            if (scaleScreenWidth > 1) {
                zoom -= (1 + scaleScreenWidth) / 2 - 1;
            } else {
                zoom += (1 + 1 / scaleScreenWidth) / 2 - 1;
            }
            const cameraProperties = [
                'lat',
                'lon',
                'phi',
                'theta',
            ];
            const facadeCameraConfig = _.extend({zoom}, _.pick(cameraConfig, cameraProperties));
            this._facadeCameraKeys.push(facadeCameraConfig);
        });
    }

    centerOnProgramme() {
        this._updateFacadeCamera();
    }

    _updateFacadeCamera(teleport) {
        if (this._programmeId != null) {
            const cameraConfig = this._getFacadeCamera(this._currentFacade);
            if (cameraConfig && this._cameraManager) {
                const options = {
                    teleport,
                    durationInMS: 1000,
                    easing: 'easeInOutCubic',
                };
                this._cameraManager.gotoFacadeCamera(cameraConfig, options);
            }
            this._updateDayTimeInMap();
        }
    }

    _updateDayTimeInMap() {
        if (this._sunAnimator && this._facadeCameras) {
            const facade = this._facadeCameras[this._currentFacade];
            if (facade && facade.dateAndDayTimePercent) {
                const dateAndDayTimePercent = {
                    date: moment(facade.dateAndDayTimePercent.date, 'YYYY MM DD').toDate(),
                    dayTimePercent: facade.dateAndDayTimePercent.dayTimePercent,
                };
                this._sunAnimator.animateDateAndDayTimePercent(dateAndDayTimePercent);
            }
        }
    }

    selectFacadeCamera(facadeIndex, teleport) {
        if (this._currentFacade !== facadeIndex || teleport) {
            const facadeCameraKeysLength = this._facadeCameraKeys.length;
            if (facadeIndex < 0) {
                facadeIndex = facadeCameraKeysLength - 1;
            } else if (facadeIndex >= facadeCameraKeysLength) {
                facadeIndex = 0;
            }
            this._currentFacade = facadeIndex;
            this._updateFacadeCamera(teleport);
        }
    }

    _getFacadeCamera(facade) {
        if (this._hasFacades()) {
            return this._facadeCameraKeys[facade];
        }
        return null;
    }

    _hasFacades() {
        return this._facadeCameras && this._facadeCameras.length;
    }

    setBuildingsHeightAroundProgramme(lowerBuildingAround) {
        if (this._lowerBuildingAround != lowerBuildingAround) {
            this._lowerBuildingAround = lowerBuildingAround;
            this._mustUpdateBuildingHeight = true;
            this._updateBuildingHeightIfNeeded();
        }
    }

    _updateBuildingHeightIfNeeded() {
        if (this._mustUpdateBuildingHeight) {
            this._mustUpdateBuildingHeight = false;
            this.updateBuildingsHeightAroundProgramme();
            if (this._$facadeCameraCodeGenerateContainer && this._$facadeCameraCodeGenerateContainer.length) {
                this._$facadeCameraCodeGenerateContainer.html(this.convertFacadeCamerasInJsonText());
            }
        }
    }

    updateBuildingsHeightAroundProgramme() {
        if (this._lowerBuildingAround || savedOptions.alwaysLowBuilding) {
            this.setBuildingsLow();
        } else {
            this.setBuildingsHigh();
        }
    }

    setBuildingsLow() {
        if (!this._areBuildingsLow && this.map.buildingLayer) {
            this._areBuildingsLow = true;
            clearTimeout(this._animationTimerId);
            this._animationTimerId = _.delay(_.bind(function () {
                this.map.buildingLayer.setValues({
                    heightScale: 0.1,
                });
            }, this), 1000);
        }
    }

    setBuildingsHigh() {
        if (this._areBuildingsLow && this.map.buildingLayer) {
            this._areBuildingsLow = false;
            clearTimeout(this._animationTimerId);
            this._animationTimerId = _.delay(_.bind(function () {
                this.map.buildingLayer.setValues({
                    heightScale: 1.0,
                });
            }, this), 1000);
        }
    }
};
