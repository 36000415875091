const _ = require('lodash');
const BrowserDetect = require('browser-detect');
const MapApi = require('./MapApi');
const {isKartoEnabled, removeDuplicateVertices} = require('./utils/Karto');
const GeoJSON2GoogleMaps = require('./utils/GeoJSON2GoogleMaps');
const ClipperHelper = require('./ClipperHelper');
const CircleZoneAroundMeHelper = require('./geolocation/CircleZoneAroundMeHelper');
const PolygonZIndex = require('./utils/PolygonZIndex');
const GRAPHIC_STYLE_GUIDE_COLORS = require('./colors');

const defaultDisplayOptions = {
    strokeColor: GRAPHIC_STYLE_GUIDE_COLORS['blue-grey'],
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: '#000000',
    fillOpacity: BrowserDetect.isMobile() || BrowserDetect.isTablet() ? 0.2 : 0.1,
    isHole: true,
};

const KARTO_POLYGON_PROPERTIES = {
    'fill-color': '#000000',
    'fill-opacity': BrowserDetect.isMobile() || BrowserDetect.isTablet() ? 0.2 : 0.1,
    'line-width': 3,
    'line-color': GRAPHIC_STYLE_GUIDE_COLORS['blue-grey'],
};

module.exports = class Zone {
    /**
     *
     * @param {object} geoJSONGeometries object of id to geoJSON
     * @param {object} displayOptions
     * @constructor
     */
    constructor(geoJSONGeometries, displayOptions) {
        this._polygons = [];
        this.useKarto = isKartoEnabled('searchResultsPage');
        if (geoJSONGeometries) {
            this._setGeometries(geoJSONGeometries);
        }
        this._displayOptions = {};
        _.extend(this._displayOptions, displayOptions);
        _.defaults(this._displayOptions, defaultDisplayOptions);
    }

    setMap(map) {
        if (map == null) {
            this._deletePolygons();
            this.map = null;
        } else if (this.map !== map) {
            this.map = map;
            this._generateBorders();
        }
    }

    getBounds() {
        return this._latLngBounds;
    }

    _setGeometries(geometries) {
        this._latLngBounds = this.useKarto ? new kartoEngine.LngLatBounds() : new MapApi.api.LatLngBounds();
        let overlaysByGeometries;
        if (geometries) {
            overlaysByGeometries = this._geometriesToOverlays(geometries);
        } else {
            console.warn('Zone without geometry');
            overlaysByGeometries = [];
        }
        _.each(overlaysByGeometries, (overlaysByGeometry) => {
            _.each(overlaysByGeometry.overlays, (overlay) => {
                if (overlay.type === 'Error') {
                    console.warn('overlay got an error', overlay.message);
                } else if (overlay.getPaths) {
                    overlay.getPaths().forEach((path) => {
                        path.forEach((latLng) => {
                            this._latLngBounds.extend(latLng);
                            overlaysByGeometry.latLngBounds.extend(latLng);

                        });
                    });
                } else if (overlay.getPath) {
                    overlay.getPath().forEach((latLng) => {
                        this._latLngBounds.extend(latLng);
                        overlaysByGeometry.latLngBounds.extend(latLng);
                    });
                }
            });
        });
        this._overlaysByGeometries = overlaysByGeometries;
    }

    _geometriesToOverlays(geometries) {
        const overlaysByGeometries = [];
        _.each(geometries, (geometry, id) => {
            const overlaysByGeometry = {
                id,
                overlays: [],
                latLngBounds: this.useKarto ? new kartoEngine.LngLatBounds() : new MapApi.api.LatLngBounds(),
            };
            if (geometry.type === 'circle') {
                geometry = CircleZoneAroundMeHelper.createGeoJSONCircle(geometry.point.lon, geometry.point.lat, geometry.radius);
            }
            const googleMapGeometries = this.useKarto ? geometry : GeoJSON2GoogleMaps(geometry);
            if (!_.isArray(googleMapGeometries)) {
                overlaysByGeometry.overlays.push(googleMapGeometries);
            } else {
                Array.prototype.push.apply(overlaysByGeometry.overlays, googleMapGeometries);
            }
            overlaysByGeometries.push(overlaysByGeometry);
        });
        return overlaysByGeometries;
    }

    _deletePolygons() {
        _.each(this._polygons, (polygon) => {
            polygon.setMap(null);
            if (!this.useKarto) {
                polygon.setPaths(new MapApi.api.MVCArray([new MapApi.api.MVCArray()])); //remove listeners on paths
            }
        });
        this._polygons.length = 0;
    }

    _generateBorders() {
        this._deletePolygons();
        this._generateFullBorders();
    }

    _generateFullBorders() {
        if (this.useKarto) {
            const overlays = [];
            _.each(this._overlaysByGeometries, (overlayGeometry) => {
                // Extract the overlay geometry for better readability
                const overlay = overlayGeometry.overlays[0];
                const feature = new kartoEngine.Feature({
                    type: 'Feature',
                    geometry: removeDuplicateVertices(overlay),
                });

                feature.unkinkPolygon();
                overlays.push(feature);

            });
            let mergedPolygon = overlays[0];
            for (let i = 1; i < overlays.length; i++) {
                mergedPolygon = mergedPolygon.union(overlays[i]);
            }
            mergedPolygon.invert();
            mergedPolygon.setProperties(KARTO_POLYGON_PROPERTIES);

            const polygons = this.map.createShapeGroup();
            polygons.addFeature(mergedPolygon);
            this._polygons.push(polygons);
        } else {
            const displayOptions = this._displayOptions;
            const latLngBounds = this._latLngBounds;
            const sw = latLngBounds.getSouthWest();
            const ne = latLngBounds.getNorthEast();
            const options = _.extend({
                map: this.map,
                zIndex: PolygonZIndex.zone,
                disableZBuffer: true,
                clickable: false,
                bounds: {
                    left: sw.lng(),
                    right: ne.lng(),
                    top: ne.lat(),
                    bottom: sw.lat(),
                },
            }, displayOptions);
            const polygons = ClipperHelper.mergePolygons(this._overlaysByGeometries, options);
            this._polygons = this._polygons.concat(polygons);
        }
    }

    _closePolygonIfNeeded(feature) {
        if (feature.geometry.type === 'Polygon') {
            feature.geometry.coordinates = feature.geometry.coordinates.map(ring => {
                const firstPoint = ring[0];
                const lastPoint = ring[ring.length - 1];
                // Check if the ring is closed
                if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
                    // Close the ring by adding the first point to the end
                    ring.push(firstPoint);
                }
                return ring;
            });
        }
        return feature;
    }
};
