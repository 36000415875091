const _ = require('lodash');
const async = require('async');
const AsyncHelper = require('./utils/AsyncHelper');
const GeoJSON2GoogleMaps = require('./utils/GeoJSON2GoogleMaps');
const MapApi = require('./MapApi');
const karto = require('./loadKarto');
const ProgrammeHelper = require('../common/ProgrammeHelper');
const adsManager = require('./search/adsManager');
const Account = require('./authentication/Account');
const Errors = require('./utils/Errors');
const ZoneManager = require('./ZoneManager');
const Neighborhood = require('./Neighborhood');
const Error404 = require('./Error404');
const FiltersBuilder = require('../common/FiltersBuilder');
const Ajax = require('./utils/Ajax');
const blurTypes = require('../common/blurTypes');
const Options = require('./Options');
const configOptions = Options.read();
const PolygonZIndex = require('./utils/PolygonZIndex');
const ApplicationConfig = require('./app/ApplicationConfig');
const {updateFavoriteAdsIds} = require('./favorites');
const isNullOrEmpty = require('../common/isNullOrEmpty');

const BlurHelper = require('./BlurHelper');
const BLUR_OVERLAY_OPTIONS = BlurHelper.BLUR_OVERLAY_OPTIONS;
const BLUR_OVERLAY_CITY_BLURRED_OPTIONS = BlurHelper.BLUR_OVERLAY_CITY_BLURRED_OPTIONS;
const BLUR_OVERLAY_KARTO_OPTIONS = BlurHelper.BLUR_OVERLAY_KARTO_OPTIONS;
const KARTO_BLUR_OVERLAY_CITY_BLURRED_OPTIONS = BlurHelper.KARTO_BLUR_OVERLAY_CITY_BLURRED_OPTIONS;

const ServerConfig = require('./ServerConfig');
const getAuthenticatedUserContactRequests = require('./ad/getAuthenticatedUserContactRequests');

const NEIGHBORHOOD_OVERLAY_OPTIONS = {
    strokeColor: '#ffb82f',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: '#ffb82f',
    fillOpacity: 0.2,
    isHole: false,
    disableZBuffer: true,
    clickable: false,
    zIndex: PolygonZIndex.zone,
};

_.extend(module.exports, {
    load,
    loadBlurOverlays,
    loadNeighborhoodOverlays,
    loadNeighborhoodInfo,
    loadRealEstateAd,
    loadRelatedAds,
    loadSimilarAds,
    shouldLoadSimilarAds,
});

function shouldLoadSimilarAds(realEstateAd) {
    return !realEstateAd.similarAds && !ApplicationConfig.applicationPro &&
        (configOptions.displaySimilarAdsInDetailedSheet
            || !realEstateAd.status.onTheMarket
            || !_.isEmpty(getAuthenticatedUserContactRequests(realEstateAd)));
}

function load(realEstateAdId, options, onLoadedCallback) {
    const asyncHelper = new AsyncHelper();
    const searchFilters = options.filters || {onTheMarket: [true]};
    const tasks = {
        realEstateAd: callback => {
            asyncHelper.doAsync({
                func: cb => loadRealEstateAd({
                    disableErrorPage: options.disableErrorPage,
                    realEstateAdId,
                    filters: searchFilters, //fetch from elastic search please
                }, cb),
                callback,
            });
        },
        similarAds: [
            'realEstateAd', (callback, {realEstateAd}) => {
                if (shouldLoadSimilarAds(realEstateAd)) {
                    loadSimilarAds({
                        realEstateAdId,
                        searchCriteria: options.filters,
                        excludeAdsWithSameAccountId: realEstateAd.status.onTheMarket,
                    }, (err, similarAds) => {
                        if (err && !(err instanceof Error404)) { //admin with a 404 ad
                            console.error('Could not get similar ads', err);
                        }
                        callback(null, similarAds);
                    });
                } else {
                    callback();
                }
            },
        ],
        neighborhoodInfo: [
            'realEstateAd', (callback, {realEstateAd}) => {
                asyncHelper.doAsync({
                    func: cb => loadNeighborhoodInfo(realEstateAd, cb),
                    callback: optionalResultCallback(callback, 'neighborhoodInfo'),
                });
            },
        ],
        programme: [
            'realEstateAd', (callback, {realEstateAd}) => {
                if (realEstateAd.programmeRef) {
                    asyncHelper.doAsync({
                        func: cb => loadRealEstateAd({
                            realEstateAdId: realEstateAd.programmeRef,
                            filters: searchFilters,
                        }, cb),
                        callback,
                    });
                } else {
                    callback();
                }
            },
        ],
        residence: [
            'realEstateAd', (callback, {realEstateAd}) => {
                if (realEstateAd.residenceReference) {
                    asyncHelper.doAsync({
                        func: cb => loadRealEstateAd({
                            realEstateAdId: realEstateAd.residenceReference,
                            filters: searchFilters,
                        }, cb),
                        callback,
                    });
                } else {
                    callback();
                }
            },
        ],
        relatedAds: [
            'realEstateAd', (callback, {realEstateAd}) => {
                asyncHelper.doAsync({
                    func: cb => loadRelatedAds({
                        realEstateAd,
                        filters: searchFilters,
                    }, cb),
                    callback,
                });
            },
        ],
        filters: [
            'realEstateAd', (callback, {realEstateAd}) => {
                if (options.filters) {
                    callback(null, options.filters);
                } else {
                    asyncHelper.doAsync({
                        func: cb => computeFilters({
                            disableErrorPage: options.disableErrorPage,
                            realEstateAd,
                            buildSearch: options.buildSearch,
                        }, cb),
                        callback,
                    });
                }
            },
        ],
    };
    async.auto(tasks, (err, {realEstateAd, relatedAds, programme, residence, similarAds, neighborhoodInfo, filters}) => {
        if (relatedAds && relatedAds.length) {
            realEstateAd.relatedAds = relatedAds;
            _.each(realEstateAd.relatedAds, ad => {
                if (realEstateAd.id == ad.programmeRef) {
                    ad.programme = realEstateAd;
                }
                if (realEstateAd.id == ad.residenceReference) {
                    ad.residence = realEstateAd;
                }
            });
            const matchingRelatedAds = _.filter(relatedAds, ad => ad.matchFilters);

            _.extend(realEstateAd, ProgrammeHelper.computeAdValuesFromRelatedAdsValues(matchingRelatedAds));
        }
        if (programme) {
            realEstateAd.programme = programme;
        }
        if (residence) {
            realEstateAd.residence = residence;
        }
        if (similarAds) {
            realEstateAd.similarAds = similarAds;
        }
        onLoadedCallback(err, realEstateAd, neighborhoodInfo, filters);
    });
    return {
        abort,
    };

    function abort() {
        asyncHelper.cancelAll();
    }
}

function optionalResultCallback(callback, context, defaultValue) {
    return function (err) {
        if (err) {
            console.error('Error loading additional value ' + context, err);
            callback.call(this, null, defaultValue);
        } else {
            callback.apply(this, arguments);
        }
    };
}

function loadRealEstateAd(options, callback) {
    if (options.filters) {
        return adsManager.loadRealEstateAdByIdWithFilters({
            id: options.realEstateAdId,
            filters: options.filters,
        }, receiveRealEstateAd);
    } else {
        return adsManager.loadRealEstateAdById({
            disableErrorPage: options.disableErrorPage,
            id: options.realEstateAdId,
        }, receiveRealEstateAd);
    }

    function receiveRealEstateAd(err, realEstateAd) {
        if (err && err.status !== 'abort' && !(err instanceof Error404)) {
            console.error('error loading realEstateAd', err);
            callback(err);
            Errors.showError(err);
        } else {
            if (!err && !realEstateAd) {
                err = new Error404('realEstateAd not found');
            }
            callback(err, realEstateAd);
        }
    }
}

function loadBlurOverlays(realEstateAd, useKarto, callback) {
    let request = null;
    const blurInfo = realEstateAd.blurInfo;
    if (!blurInfo) {
        callback(null, null);
    } else if (_.includes(blurTypes.DISPLAYED_ON_MAP, blurInfo.type)) {
        let diskOverlay;
        const radius = BlurHelper.getCircleRadius(blurInfo);
        if (useKarto) {
            diskOverlay = karto.currentMap.createShapeGroup();
            diskOverlay.addFeature(
                new kartoEngine.Circle(
                    [
                        blurInfo.position.lon,
                        blurInfo.position.lat,
                    ],
                    radius
                )
            );
            diskOverlay.setPaint(BLUR_OVERLAY_KARTO_OPTIONS);
            callback(null, [diskOverlay]);
        } else {
            diskOverlay = new MapApi.api.Circle(_.extend({
                center: new MapApi.api.LatLng(blurInfo.position.lat, blurInfo.position.lon),
                radius,
            }, BLUR_OVERLAY_OPTIONS));
            callback(null, [diskOverlay]);
        }
    } else if (realEstateAd.district) {
        request = loadDistrictGeometry(realEstateAd.district, geometryCallback(blurInfo, useKarto, callback));
    } else {
        callback(null, null);
    }
    return request;
}

function geometryCallback(blurInfo, useKarto, callback) {
    return function (err, geometry) {
        if (!err && geometry) {
            if (useKarto) {
                const overlays = karto.currentMap.createShapeGroup();
                const geoJsonFeature = {
                    type: 'Feature',
                    geometry,
                    properties: {},
                };
                overlays.addFeature(new kartoEngine.Feature(geoJsonFeature));
                const options = _.extend(_.clone(BLUR_OVERLAY_KARTO_OPTIONS), KARTO_BLUR_OVERLAY_CITY_BLURRED_OPTIONS);
                overlays.setPaint(options);
                callback(null, [overlays]);
            } else {
                let overlays = GeoJSON2GoogleMaps(geometry);
                if (overlays.type == 'Error') {
                    console.error('Could not parse geometry: ' + overlays.message, geometry);
                    callback(new Error('Could not parse geometry for blurInfo' + JSON.stringify(blurInfo)));
                    return;
                }
                overlays = _.castArray(overlays);
                const options = _.extend(_.clone(BLUR_OVERLAY_OPTIONS), BLUR_OVERLAY_CITY_BLURRED_OPTIONS);

                _.each(overlays, function (overlay) {
                    overlay.setOptions(options);
                });
                callback(null, overlays);
            }
        } else {
            callback(err, null);
        }
    };
}

function loadDistrictGeometry(district, callback) {
    let request;
    if (district && district.geometry) {
        callback(null, district.geometry);
    } else if (district) {
        request = ZoneManager.fetchZoneData(district, function (err, zoneData) {
            if (!err && zoneData && !district.geometry) {
                district.geometry = zoneData.geometry;
                callback(null, district.geometry);
            } else {
                if (err && err.status !== 'abort') {
                    console.error('Could not load geometry', err);
                }
                callback(err, district.geometry || null);
            }
        });
    } else {
        callback(null, null);
    }
    return request;
}

function loadNeighborhoodOverlays(realEstateAd, useKarto, callback) {
    let request = null;
    if (realEstateAd.neighborhoodOverlays) {
        callback(null, realEstateAd.neighborhoodOverlays);
    } else if (realEstateAd.district) {
        request = loadDistrictGeometry(realEstateAd.district, function (err, geometry) {
            if (!err && geometry && !realEstateAd.neighborhoodOverlays/*avoid bugs if request sent multiple times*/) {
                let overlays;
                if (!useKarto) {
                    overlays = _.castArray(GeoJSON2GoogleMaps(geometry));
                    _.each(overlays, function (overlay) {
                        overlay.setOptions(NEIGHBORHOOD_OVERLAY_OPTIONS);
                    });
                }
                realEstateAd.neighborhoodOverlays = useKarto ? geometry : overlays;
                callback(null, realEstateAd.neighborhoodOverlays);
            } else {
                callback(err, realEstateAd.neighborhoodOverlays || null);
            }
        });
    } else {
        callback(null, null);
    }
    return request;
}

function loadNeighborhoodInfo(realEstateAd, cb) {
    let request;
    const district = realEstateAd.district;
    if (district && district.type != 'postalCode') {
        request = Neighborhood.fetchNeighborhoodStatsById({
            id: district.id,
        }, function (err, result) {
            if (err) {
                cb(err);
            } else {
                cb(null, result);
            }
        });
    } else {
        cb(null, null);
    }
    return request;
}

function loadRelatedAds({realEstateAd: {propertyType, id, userRelativeData}, filters}, cb) {
    if (propertyType == 'residence' || propertyType == 'programme') {
        const allowNotOnTheMarketFilter = allowNotOnTheMarketRelatedAds(userRelativeData);
        return fetchRelatedAds({
            id,
            propertyType,
            filters,
            allowNotOnTheMarketFilter,
        }, cb);
    } else {
        cb(null, null);
    }
}

function allowNotOnTheMarketRelatedAds({isAdmin, isNetwork, isOwner} = {}) {
    return Boolean(isAdmin || isNetwork || isOwner);
}

function loadSimilarAds({
    searchCriteria,
    realEstateAdId,
    maxCount = ServerConfig.config.similarAdsMaxCount,
    excludeAdsWithSameAccountId = true,
}, cb) {
    Account.getAccountAndCreateGuestIfNeeded(() => {
        Account.authAjax({
            url: '/similarRealEstateAds',
            data: {
                realEstateAdId,
                searchCriteria: _.omitBy(searchCriteria, isNullOrEmpty),
                maxCount,
                excludeAdsWithSameAccountId,
            },
            serverErrorMessage: 'similarRealEstateAds',
            disableErrorPage: true,
            callback: (err, data) => {
                if (!err) {
                    const {ads} = data;
                    updateFavoriteAdsIds(ads);
                    cb(null, ads);
                } else {
                    cb(err);
                }
            },
        });
    });
}

function fetchRelatedAds(options, callback) {
    const data = options;
    if (!data.id) {
        console.trace('fetchRelatedAds : invalid ad id, options=' + JSON.stringify(options));
        callback(null, null);
        return null;
    }
    if (data.filters) {
        data.filters = JSON.stringify(adsManager.prepareFilters(data));
    }
    data.accessToken = Account.getAccessToken();
    return Ajax.request({
        url: '/relatedRealEstateAds.json',
        data,
        disableErrorPage: true,
        serverErrorMessage: 'fetchRelatedAds',
        callback: (err, ads) => {
            if (!err) {
                updateFavoriteAdsIds(ads);
            }
            callback(err, ads);
        },
    });
}

function computeFilters(options, computeFiltersCallback) {
    loadRelatedAds({
        disableErrorPage: options.disableErrorPage,
        realEstateAd: options.realEstateAd,
    }, (err, relatedAds) => {
        if (err) {
            computeFiltersCallback(err);
        } else {
            const ad = options.realEstateAd;
            ad.relatedAds = relatedAds;
            const filters = FiltersBuilder.buildSearch(ad);
            computeFiltersCallback(null, filters);
        }
    });
}
