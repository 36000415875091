const _ = require('lodash');
const $ = require('jquery');
const AddressSuggestionHelper = require('../utils/AddressSuggestionHelper');
const MapApi = require('../MapApi');
const {resourceUrl} = require('fack');
const PopupLayerHelper = require('../PopupLayerHelper');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const EventPack = require('./EventPack');
const MarkerZIndex = require('./MarkerZIndex');
const {isKartoEnabled} = require('./Karto');

const eventPack = new EventPack();
const markersAndItems = [];
let clickAndDraggable = true;
let useKarto;

const zoneByTimeMarkerPopupTemplate = require('../templates/zoneByTimeMarkerPopup.jade');

const TARGET_MARKER_ABOVE_OTHER_MARKERS_BELOW_ZOOM = 12;

module.exports = {
    createMarkers,
    deleteMarkers,
    updateMarkers,
};

function getMarkerIconUrl(mode) {
    const iconName = mode ? (`images/map/pins/${mode}.png`) : null;
    return iconName ? resourceUrl(iconName) : null;
}

function updateMarker({marker, item}) {
    marker.setClickable(clickAndDraggable);
    marker.setDraggable(clickAndDraggable);
    if (!clickAndDraggable) {
        setPopupVisible(marker, clickAndDraggable);
    }
    const position = item.from;
    if (position) {
        if (useKarto) {
            marker.update({position: [position.lng, position.lat]});
        } else {
            marker.setPosition(new MapApi.api.LatLng(position.lat, position.lng));
        }
    }
}

function createMarkers(searchLocationInfos) {
    _.each(searchLocationInfos, searchLocationInfo => {
        if (searchLocationInfo.type == 'travelTimeZone') {
            createMarker(searchLocationInfo.travelTimeInfos);
        }
    });
}

function deleteMarkers() {
    _.each(markersAndItems, deleteMarker);
    markersAndItems.length = 0;
    eventPack.removeAllListeners();
}

function updateMarkers({travelTimeMarkersClickableAndDraggable}) {
    clickAndDraggable = travelTimeMarkersClickableAndDraggable;
    _.each(markersAndItems, updateMarker);
}

function createMarker({item, openTravelTimeSearchCallback}) {
    useKarto = isKartoEnabled('searchResultsPage');
    const map = SideMapViewSingleton.get().map;
    if (map) {
        const iconUrl = getMarkerIconUrl(item.mode);
        const position = item.from;
        const isMarkerClickableAndDraggable = clickAndDraggable && openTravelTimeSearchCallback;
        const markerOptions = {
            position: useKarto ? [position.lng, position.lat] : new MapApi.api.LatLng(position.lat, position.lng),
            icon: {url: iconUrl},
            clickable: isMarkerClickableAndDraggable,
            draggable: isMarkerClickableAndDraggable,
        };
        const marker = useKarto ? map.createMarker3D(
            {
                ...markerOptions,
                altitude: 0,
                depthTest: false,
                depthWrite: false,
            }
        ) : new MapApi.api.Marker({...markerOptions, map});
        if (useKarto) {
            const markerInfowindow = map.createInfoWindow({
                opened: false,
                closeButton: false,
            });
            marker.setInfoWindow(markerInfowindow);
            marker.zoneByTimeMarker = true;
        }
        if (isMarkerClickableAndDraggable) {
            const openTravelTimeSearchDebounced = _.debounce(openTravelTimeSearchCallback);
            if (useKarto) {
                eventPack.on(map, {
                    dragend: (event) => {
                        if (event.type == 'Marker3D' && event.result.zoneByTimeMarker && !event.result.ad) {
                            const marker3d = event.result;
                            item.address = AddressSuggestionHelper.formatAddress(item.address, marker3d.getPosition());
                            openTravelTimeSearchDebounced();
                        }
                    },
                    selection: (event) => {
                        if (event.type == kartoEngine.SelectionType.MARKER_3D
                            && event.result.zoneByTimeMarker && !event.result.ad
                        ) {
                            setPopupVisible(marker, false);
                            openTravelTimeSearchDebounced();
                        }
                    },
                    mousein: (event) => {
                        if (event.type == kartoEngine.SelectionType.MARKER_3D
                            && event.result.zoneByTimeMarker && !event.result.ad
                        ) {
                            marker.getInfoWindow().open();
                        }
                    },
                    mouseout: (event) => {
                        if (event.type == kartoEngine.SelectionType.MARKER_3D
                            && event.result.zoneByTimeMarker && !event.result.ad
                        ) {
                            marker.getInfoWindow().close();
                        }
                    },
                });
            } else {
                eventPack.on(marker, {
                    dragend: () => {
                        const latLng = marker.getPosition();
                        item.address = AddressSuggestionHelper.formatAddress(item.address, {
                            lat: latLng.lat(),
                            lng: latLng.lng(),
                        });
                        openTravelTimeSearchDebounced();
                    },
                    click: () => {
                        setPopupVisible(marker, false);
                        openTravelTimeSearchDebounced();
                    },
                });
            }
            createPopup(marker);
        }
        if (useKarto) {
            eventPack.on(map, 'zoom', () => {
                updateMarkerZIndex(marker, map.getZoom());
            });
        } else {
            eventPack.on(map, 'zoom_changed', () => {
                updateMarkerZIndex(marker, map.getZoom());
            });
        }
        markersAndItems.push({marker, item});
        updateMarkerZIndex(marker, map.getZoom());
    }
}

function deleteMarker({marker}) {
    if (marker) {
        deletePopup(marker);
        marker.setMap(null);
    }
}

function updateMarkerZIndex(marker, zoom) {
    const inFront = zoom <= TARGET_MARKER_ABOVE_OTHER_MARKERS_BELOW_ZOOM;
    marker.setZIndex(inFront ? MarkerZIndex.max : MarkerZIndex.min);
}

function createPopup(marker) {
    const $templateInfo = $(zoneByTimeMarkerPopupTemplate());
    if (useKarto) {
        marker.getInfoWindow().setContent($templateInfo.html());
    } else {
        const popupLayer = marker.popupLayer || PopupLayerHelper.create(SideMapViewSingleton.get().map);
        PopupLayerHelper.openPopup(popupLayer, marker, $templateInfo);
        eventPack.on(marker, {
            mouseover: () => {
                setPopupVisible(marker, true);
                PopupLayerHelper.movePopup(popupLayer);
            },
            mouseout: () => {
                setPopupVisible(marker, false);
            },
        });
    }
    setPopupVisible(marker, false);
}

function setPopupVisible(marker, visible) {
    if (useKarto) {
        marker.getInfoWindow()[visible ? 'open' : 'close']();
    } else if (marker.popupLayer) {
        marker.popupLayer.$html.toggle(visible);
    }
}

function deletePopup(marker) {
    if (useKarto) {
        marker.getInfoWindow().setContent(null);
        marker.getInfoWindow().close();
    } else if (marker.popupLayer) {
        PopupLayerHelper.closePopup(marker.popupLayer);
    }
}
