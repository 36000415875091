const _ = require('lodash');
const internalValue = require('../../../form/components/mixins/withInternalValue');
const GoogleTagManager = require('./../../../stats/GoogleTagManager');
const {mapGetters} = require('vuex');
const EVENT_NAMES = {
    POPUP_CLOSED: 'PopupClosed',
    COMPLETE_FOLDER: 'CompleteFolder',
    FOLDER_MODIFICATION: 'FolderModification',
    DOC_NOT_READY: 'DocNotReady',
    FOLDER_FOR_VALIDATION: 'FolderForValidation',
    DOC_INTERACTION: 'DocInteraction',
};

// @vue/component
module.exports = {
    mixins: [
        internalValue,
    ],
    computed: {
        ...mapGetters('rentalApplication', [
            'popupSource',
            'currentDossier',
            'isValid',
        ]),
        popupName() {
            return this.$options.name || 'UnknownPopup';
        },
    },
    methods: {
        handleCloseTracking() {
            this.internalValue = false;
            GoogleTagManager.sendEvent(EVENT_NAMES.POPUP_CLOSED, {
                popup_name: this.popupName,
            });
        },
        handleCompleteTracking() {
            GoogleTagManager.sendEvent(EVENT_NAMES.COMPLETE_FOLDER, {
                popup_name: this.popupSource,
            });
        },
        handleFolderModificationTracking() {
            GoogleTagManager.sendEvent(EVENT_NAMES.FOLDER_MODIFICATION, {
                popup_name: this.popupName,
            });
        },
        handleDocNotReadyTracking() {
            GoogleTagManager.sendEvent(EVENT_NAMES.DOC_NOT_READY, {
                popup_name: this.popupSource,
            });
        },
        handleFolderForValidation() {
            const subject = _.get(this.currentDossier, 'type', 'Unknown');
            GoogleTagManager.sendEvent(EVENT_NAMES.FOLDER_FOR_VALIDATION, {
                subject: subject,
                folder_type: this.popupSource,
                folder_valid: this.isValid.toString(),
            });
        },
        handleDocumentInteraction(action, placement, text = '') {
            GoogleTagManager.sendEvent(EVENT_NAMES.DOC_INTERACTION, {
                action: action,
                placement: placement,
                text: text || '',
                folder_type: this.popupSource,
            });
        },
    },
};
