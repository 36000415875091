const _ = require('lodash');
const _numberFormat = require('underscore.string/numberFormat');
const $ = require('jquery');
const {EventEmitter} = require('events');
const DefaultConfiguration = require('../../common/DefaultConfiguration');
const BlurHelper = require('../BlurHelper');
const {mercatorProjection} = require('../utils/Karto');

const FIT_ADS_MAX_ZOOM = 18;

module.exports = class CameraManager extends EventEmitter {
    constructor() {
        super();
        this._map = null;
        this._camera = _.clone(DefaultConfiguration.camera);
    }

    /**
     * Sets the map instance.
     * @param {Object} map - The map instance to be set.
     */
    setMap(map) {
        this._map = map;
    }

    /**
     * Retrieves the camera parameters from the current map state.
     * @returns {Object} An object containing camera parameters including:
     *   - {boolean} '3d' - Indicates if the map is in 3D mode.
     *   - {number} zoom - The zoom level of the map, adjusted by adding 1.
     *   - {number} phi - The heading of the map.
     *   - {number} theta - The tilt of the map.
     *   - {Object} position - The latitude and longitude of the map center.
     */
    getCameraFromMap() {
        const map = this._map;
        const center = map.getCenter();
        return _.extend(
            {
                '3d': map.isIn3d(),
                zoom: Math.floor(map.getZoom() + 1),
                phi: map.getHeading(),
                theta: map.getTilt(),
            },
            positionToLatLon({
                lat: center.lat,
                lon: center.lng,
            }));
    }

    /**
     * Moves the camera to the specified latitude and longitude bounds.
     * @param {Object} latLngBounds - The bounds to center the camera on.
     * @param {Object} cameraOptions - The options for the camera movement, including:
     *   - {number} zoom - The desired zoom level.
     *   - {Object} position - The position to move to, containing 'lat' and 'lon'.
     */
    moveCameraToBounds(latLngBounds, cameraOptions) {
        if (this._map) {
            const center = latLngBounds.getCenter();
            const boundsZoom = this.getZoomFromBoundsFromApi(latLngBounds);
            cameraOptions.zoom = Math.min(boundsZoom, cameraOptions.zoom);
            cameraOptions.position = {
                lon: center.lng,
                lat: center.lat,
            };
            this.moveCameraToPosition(cameraOptions);
        }
    }

    /**
     * Retrieves the zoom level based on the given bounds using the API.
     * @param {Object} bounds - The bounds to calculate the zoom level for.
     * @returns {number} The calculated zoom level.
     */
    getZoomFromBoundsFromApi(bounds) {
        const zoom = _.get(this._map.cameraForBounds(bounds), 'zoom');
        return zoom ? Math.floor(zoom) + 1 : this._map.getZoom();
    }

    /**
     * Moves the camera to the center of a neighborhood zone defined by overlays.
     * @param {Array} overlays - The array of overlays containing geographic data.
     * @param {boolean} teleport - Indicates whether to teleport the camera to the new position.
     */
    moveCameraToNeighborhoodZone(overlays, teleport) {
        if (overlays) {
            const overlay = overlays[0];
            const latLngBounds = overlay.getFeatures().getBounds();
            this.moveCameraToBounds(latLngBounds, {
                '3d': this._map.isIn3d(),
                zoom: 17,
                theta: this._map.getPitch(),
                phi: this._map.getBearing(),
                teleport,
            });
        }
    }

    /**
     * Retrieves the current camera configuration.
     * @returns {Object} The current camera configuration.
     */
    getCamera() {
        return this._camera;
    }

    /**
     * Sets the camera configuration based on the provided parameters and options.
     * @param {Object} camera - The camera configuration to set.
     * @param {Object} [options] - Additional options for setting the camera, including:
     *   - {boolean} [options.teleport=true] - Indicates whether to teleport the camera to the new position.
     *   - {boolean} [options.forceCameraChanged] - Forces a camera change event.
     */
    setCamera(camera, options) {
        options = options || {};
        options = _.defaults(options, {teleport: true});
        const map = this._map;
        if (map) {
            if (this._hasCameraAnimation) {
                this._abortCameraAnimation();
            }
            options = _.extend({skipCameraUrl: !(camera)}, options);
            const cameraConf = this._getCameraFromApi();
            if (cameraConf && !(this._cameraEquals(cameraConf, camera))) {
                camera.cameraNotSet = camera.cameraNotSet || false;
                _.extend(this._camera, camera);
                options.position = {lat: +camera.lat, lon: +camera.lon};
                options.zoom = this._camera.zoom;
                options.theta = this._camera.theta;
                options.phi = this._camera.phi;
                options['3d'] = this._camera['3d'];
                if (!options.teleport) {
                    this.moveCameraToPosition(options);
                } else {
                    this._setCamera(options);
                }
            } else if (options.forceCameraChanged) {
                this._onCameraMoveEnded(options);
            }
        }
    }

    /**
     * Checks if the camera is currently moving.
     * @param {boolean} isMoving - Indicates whether the camera is moving.
     * @returns {boolean} Returns true if the camera is moving; otherwise, false.
     */
    isCameraMoving(isMoving) {
        if (this._map) {
            return isMoving;
        }
        return false;
    }

    /**
     * Updates the camera configuration based on the current map state.
     * @param {Object} eventInfo - Information about the event that triggered the update, which may include:
     *   - {boolean} eventInfo.skipCameraUrl - If true, skips updating the camera URL.
     *   - {boolean} eventInfo.forceCameraChanged - If true, forces a camera change event.
     */
    updateCameraFromMap(eventInfo) {
        const camera = this._getCameraFromApi();
        if (camera && !(this._cameraEquals(camera, this._camera))) {
            camera.cameraNotSet = camera.cameraNotSet || false;
            _.extend(this._camera, camera);
            this.triggerCameraChanged(eventInfo);
        } else if (eventInfo.skipCameraUrl || eventInfo.forceCameraChanged) {
            this.triggerCameraChanged(eventInfo);
        }
    }

    /**
     * Triggers the 'mapIdle' event when the map has finished moving.
     */
    triggerMapIdle() {
        if (this._map) {
            this.emit('mapIdle');
        }
    }

    /**
     * Adjusts the camera to fit the specified latitude and longitude bounds.
     * @param {Object} latLngBounds - The bounds to fit the camera to.
     * @param {Object} [options] - Additional options for fitting the camera, if needed.
     */
    fitCameraToBounds(latLngBounds, options) {
        options = options || {};
        this.fitBounds(latLngBounds, options);
    }

    /**
     * Fits the camera to the specified latitude and longitude bounds with given options.
     * @param {Object} latLngBounds - The bounds to fit the camera to.
     * @param {Object} [options] - Additional options for fitting the camera, including:
     *   - {number} [options.minZoom] - The minimum zoom level allowed.
     *   - {number} [options.maxZoom] - The maximum zoom level allowed.
     *   - {number} [options.zoom] - The desired zoom level.
     *   - {boolean} [options.teleport=true] - Indicates whether to teleport the camera to the new position.
     *   - {number} [options.margin] - The margin to add around the bounds.
     */
    fitBounds(latLngBounds, options) {
        if (this._map) {
            options = _.defaults(options || {}, {
                minZoom: DefaultConfiguration.camera.zoomMin,
                maxZoom: DefaultConfiguration.camera.zoomMax,
                zoom: DefaultConfiguration.camera.zoomMax,
                teleport: true,
            });
            if (options && options.margin) {
                this._addMarginToBounds(latLngBounds, options.margin);
            }
            if (options.maxZoom != null && options.zoom > options.maxZoom) {
                options.zoom = options.maxZoom;
            } else if (options.minZoom != null && options.zoom < options.minZoom) {
                options.zoom = options.minZoom;
            }
            this.moveCameraToBounds(latLngBounds, options);
        }
    }

    /**
     * Adds margin to the specified bounds.
     * @param {Object} bounds - The bounds to which the margin will be added.
     * @param {number|Object} margin - The margin value or an object specifying x and y margins.
     *   - If a single number is provided, it is applied to both x and y margins.
     */
    _addMarginToBounds(bounds, margin) {
        const $element = $(this._map.getContainer());
        const width = $element.width();
        const height = $element.height();
        if (_.isNumber(margin)) {
            margin = {x: margin, y: margin};
        }

        const wantedBoundsHeight = height - 2 * margin.y;
        const wantedBoundsWidth = width - 2 * margin.x;
        const xScale = margin.x / wantedBoundsWidth;
        const yScale = margin.y / wantedBoundsHeight;

        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const boundsLatAmplitude = Math.abs(sw.lat - ne.lat);
        const boundsLngAmplitude = Math.abs(sw.lng - ne.lng);
        const latMargin = boundsLatAmplitude * yScale;
        const lngMargin = boundsLngAmplitude * xScale;
        bounds.extend(new kartoEngine.LngLat(
            sw.lng - lngMargin,
            sw.lat - latMargin
        ));
        bounds.extend(new kartoEngine.LngLat(
            ne.lng + lngMargin,
            ne.lat + latMargin
        ));
    }

    /**
     * Sets the default bounds for the camera using pre-defined configurations.
     * @param {Object} [options] - Additional options for fitting the camera to the default bounds.
     */
    setDefaultBounds(options) {
        this.fitCameraToBounds(new kartoEngine.LngLatBounds(
            DefaultConfiguration.camera.bounds.sw,
            DefaultConfiguration.camera.bounds.ne
        ), options);
    }

    /**
     * Triggers a camera changed event with the provided event information.
     * @param {Object} eventInfo - Information about the camera change event.
     */
    triggerCameraChanged(eventInfo) {
        if (this._map) {
            this.emit('cameraChanged', eventInfo);
        }
    }

    /**
     * Retrieves the current camera configuration from the map's API.
     * @returns {Object} The current camera configuration, including:
     *   - {boolean} '3d' - Indicates if the map is in 3D mode.
     *   - {number} lat - The latitude of the camera's position.
     *   - {number} lon - The longitude of the camera's position.
     *   - {number} zoom - The current zoom level.
     *   - {number} phi - The heading of the camera.
     *   - {number} theta - The tilt of the camera.
     */
    _getCameraFromApi() {
        const map = this._map;
        const center = map.getCenter();
        return {
            '3d': map.isIn3d(),
            lat: +center.lat,
            lon: +center.lng,
            zoom: Math.floor(map.getZoom() + 1),
            phi: map.getHeading(),
            theta: map.getTilt(),
        };
    }

    /**
     * Moves the camera to the specified position based on the given options.
     * @param {Object} cameraOptions - Options for moving the camera, which may include:
     *   - {boolean} [cameraOptions.asyncCameraOptions] - If true, the movement may be asynchronous.
     *   - {boolean} [cameraOptions.teleport] - Indicates whether to teleport the camera to the new position.
     */
    moveCameraToPosition(cameraOptions) {
        if (this._map) {
            if (this._hasCameraAnimation) {
                this._abortCameraAnimation();
            }
            const moveCameraToPositionHandler = _.bind(this._moveCameraToPosition, this);
            this._hasCameraAnimation = true;
            if (cameraOptions.asyncCameraOptions && !cameraOptions.teleport) {
                this._asyncAbortCallback = this._moveCameraAsync(moveCameraToPositionHandler, cameraOptions);
            } else {
                moveCameraToPositionHandler(cameraOptions);
            }
        }
    }

    /**
     * Asynchronously moves the camera to a specified position based on the provided options.
     * @param {Function} callback - The callback function to invoke for moving the camera.
     * @param {Object} cameraOptions - Options for moving the camera, which may include:
     *   - {Object} cameraOptions.asyncCameraOptions - Options specific to asynchronous camera movement.
     *   - {Function} [cameraOptions.onArrivalCallback] - A callback to invoke when the camera has arrived at the destination.
     *   - {boolean} [cameraOptions.teleport] - Indicates whether to teleport the camera.
     * @returns {Function} A function to abort the camera movement.
     */
    _moveCameraAsync(callback, cameraOptions) {
        let timerIdTeleportFace;
        const that = this;
        const asyncCameraOptions = cameraOptions.asyncCameraOptions;
        if (asyncCameraOptions.waitDelay) {
            timerIdTeleportFace = setTimeout(teleportFast, asyncCameraOptions.waitDelay);
            if (asyncCameraOptions.waitForEventsCallback) {
                asyncCameraOptions.waitForEventsCallback(onWaitReady);
            } else {
                onWaitReady();
            }
        } else {
            moveNow();
        }

        function onWaitReady() {
            if (timerIdTeleportFace != null) {
                onAbort();
                moveNow();
            }
        }

        function teleportFast() {
            onAbort();
            cameraOptions.teleport = false;
            moveNow();
        }

        function moveNow() {
            if (asyncCameraOptions.mustAbortMoveCameraToPosition && asyncCameraOptions.mustAbortMoveCameraToPosition()) {
                that._onCameraMoveEnded(cameraOptions);
            } else {
                callback(cameraOptions);
            }
        }

        function onAbort() {
            if (timerIdTeleportFace != null) {
                clearTimeout(timerIdTeleportFace);
                timerIdTeleportFace = null;
            }
        }

        return onAbort;
    }

    /**
     * Moves the camera to the bounds of a specified real estate advertisement.
     * @param {Object} realEstateAd - The real estate advertisement to focus on.
     * @param {Object} cameraOptions - Options for moving the camera, which may include:
     *   - {boolean} [cameraOptions.teleport] - Indicates whether to teleport the camera to the bounds.
     *   - {Function} [cameraOptions.onArrivalCallback] - A callback to invoke when the camera has arrived.
     */
    moveCameraToRealEstateAd(realEstateAd, cameraOptions) {
        let sw;
        let ne;
        const position = realEstateAd.blurInfo && realEstateAd.blurInfo.position || realEstateAd.position;
        if (realEstateAd.blurInfo && realEstateAd.blurInfo.bbox) {
            const bbox = realEstateAd.blurInfo.bbox;
            if (_.isArray(bbox)) {
                sw = new kartoEngine.LngLat(bbox[0], bbox[1]);
                ne = new kartoEngine.LngLat(bbox[2], bbox[3]);
            } else {
                console.log('bbox is not an array for ad ' + this.realEstateAd.id, bbox);
            }
        } else if (position && position.lat && (position.lon || position.lng)) {
            ne = sw = new kartoEngine.LngLat(position.lon || position.lng, position.lat);
        } else {
            console.warn('no Position for Ad ', this.realEstateAd);
        }
        if (ne) {
            const latLngBounds = new kartoEngine.LngLatBounds(sw, ne);
            this.moveCameraToBounds(latLngBounds, cameraOptions);
        } else {
            this._onCameraMoveEnded(cameraOptions);
        }
    }

    /**
     * Finalizes the camera movement and updates the camera state.
     * @param {Object} cameraOptions - Options for the camera movement, which may include:
     *   - {Function} [cameraOptions.onArrivalCallback] - A callback to invoke when the camera has arrived at the destination.
     */
    _onCameraMoveEnded(cameraOptions) {
        this._asyncAbortCallback = null;
        this._hasCameraAnimation = false;
        this.updateCameraFromMap(_.extend({skipCameraUrl: true}, cameraOptions));
        if (cameraOptions.onArrivalCallback) {
            cameraOptions.onArrivalCallback();
        }
    }

    /**
     * Moves the camera to a specific position based on the provided options.
     * @param {Object} cameraOptions - Options for moving the camera, which may include:
     *   - {Object} cameraOptions.position - The target position to move the camera to.
     *   - {boolean} [cameraOptions.teleport] - Indicates whether to teleport the camera.
     *   - {number} [cameraOptions.zoom] - The desired zoom level.
     *   - {number} [cameraOptions.theta] - The desired pitch of the camera.
     *   - {number} [cameraOptions.phi] - The desired bearing of the camera.
     */
    _moveCameraToPosition(cameraOptions) {
        const map = this._map;
        if (map) {
            cameraOptions = _.extend(positionToLatLon(cameraOptions.position), cameraOptions);
            cameraOptions = _.defaults(cameraOptions, {
                '3d': map.isIn3d(),
                zoom: 17,
                theta: map.getPitch(),
                phi: map.getBearing(),
            });
            if (_.isNaN(cameraOptions.zoom)) {
                cameraOptions.zoom = 17;
            }
            if (!cameraOptions.teleport) {
                map.easeTo({
                    center: cameraOptions.position,
                    zoom: cameraOptions.zoom - 1,
                    bearing: cameraOptions.phi,
                    pitch: cameraOptions.theta,
                    duration: 1500,
                });
                map.once('idle', () => {
                    this._onCameraMoveEnded(cameraOptions);
                });
            } else {
                this._setCamera(cameraOptions);
            }
        }
    }

    /**
     * Sets the camera's position, zoom, bearing, and pitch based on the given options.
     * @param {Object} cameraOptions - Options for setting the camera, which may include:
     *   - {Object} cameraOptions.position - The position to set the camera to.
     *   - {number} [cameraOptions.zoom] - The zoom level to set; if not provided, the current zoom is used.
     *   - {number} cameraOptions.phi - The bearing to set the camera to.
     *   - {number} cameraOptions.theta - The pitch to set the camera to (applies if in 3D mode).
     */
    _setCamera(cameraOptions) {
        const position = positionToLatLon(cameraOptions.position);
        const center = new kartoEngine.LngLat(position.lon, position.lat);
        this._map.jumpTo({
            center: center,
            zoom: cameraOptions.zoom ? cameraOptions.zoom - 1 : this._map.getZoom(),
            bearing: cameraOptions.phi,
            pitch: cameraOptions['3d'] ? cameraOptions.theta : 0,
        });
        this._onCameraMoveEnded(cameraOptions);
    }

    /**
     * Converts geographic coordinates from WGS 84 (EPSG:4326) to Web Mercator (EPSG:900913).
     * @param {number} lng - The longitude in EPSG:4326.
     * @param {number} lat - The latitude in EPSG:4326.
     * @returns {Object} The converted coordinates in EPSG:900913, containing:
     *   - {number} lat - The latitude in EPSG:900913.
     *   - {number} lon - The longitude in EPSG:900913.
     */
    _to900913(lng, lat) {
        const x = mercatorProjection.convertLat4326To900913(lat);
        const y = mercatorProjection.convertLon4326To900913(lng);
        return {lat: x, lon: y};
    }

    /**
     * Fits the camera to include the bounds of multiple real estate advertisements.
     * @param {Array<Object>} realEstateAds - An array of real estate advertisements to fit the camera to.
     * @param {Object} [options] - Optional parameters for fitting the camera, which may include:
     *   - {number} [options.maxZoom] - The maximum zoom level to use when fitting.
     *   - {Object} [options.margin] - The margin to apply when fitting the bounds, containing:
     *     - {number} x - The horizontal margin.
     *     - {number} y - The vertical margin.
     */
    fitCameraToRealEstateAds(realEstateAds, options) {
        options = options || {};
        const latLngBounds = new kartoEngine.LngLatBounds();
        let hasAtLeastOneCoordinates = false;
        _.each(realEstateAds, function (realEstateAd) {
            if (realEstateAd.blurInfo && realEstateAd.blurInfo.bbox) {
                const bbox = realEstateAd.blurInfo.bbox;
                const sw = new kartoEngine.LngLat(bbox[0], bbox[1]);
                const ne = new kartoEngine.LngLat(bbox[2], bbox[3]);
                latLngBounds.extend(ne);
                latLngBounds.extend(sw);
                hasAtLeastOneCoordinates = true;
            } else {
                const displayedPosition = BlurHelper.getDisplayedPosition(realEstateAd);
                if (displayedPosition && null != displayedPosition.lat
                    && (null != displayedPosition.lng || null != displayedPosition.lon)) {
                    const latLng = new kartoEngine.LngLat(displayedPosition.lng || displayedPosition.lon, displayedPosition.lat);
                    latLngBounds.extend(latLng);
                    hasAtLeastOneCoordinates = true;
                }
            }
        });

        if (!hasAtLeastOneCoordinates) {
            console.warn('all ads without position');
            const nE = new kartoEngine.LngLat(DefaultConfiguration.camera.bounds.ne.lng, DefaultConfiguration.camera.bounds.ne.lat);
            const sW = new kartoEngine.LngLat(DefaultConfiguration.camera.bounds.sw.lng, DefaultConfiguration.camera.bounds.sw.lat);
            latLngBounds.extend(nE);
            latLngBounds.extend(sW);
        }
        this.fitCameraToBounds(latLngBounds, _.defaults(options, {
            maxZoom: FIT_ADS_MAX_ZOOM,
            margin: {x: 29, y: 30},
        }));
    }

    /**
     * Aborts any ongoing camera animation.
     */
    _abortCameraAnimation() {
        if (this._asyncAbortCallback) {
            this._asyncAbortCallback();
            this._asyncAbortCallback = null;
        }
    }

    /**
     * Compares two camera configurations for equality.
     * @param {Object} camera1 - The first camera configuration to compare.
     * @param {Object} camera2 - The second camera configuration to compare.
     * @returns {boolean} True if the camera configurations are equal, otherwise false.
     */
    _cameraEquals(camera1, camera2) {
        const precision = 1000;
        return camera1.zoom === camera2.zoom
            && roundValue(camera1.lon, precision) === roundValue(camera2.lon, precision)
            && roundValue(camera1.lat, precision) === roundValue(camera2.lat, precision)
            && camera1['3d'] === camera2['3d']
            && camera1.phi === camera2.phi
            && camera1.theta === camera2.theta;
    }

};

/**
 * Converts a position object to a latitude/longitude format.
 * @param {Object} position - The position object containing latitude and longitude.
 * @param {number} [position.lng] - The longitude value (can also be specified as `position.lon`).
 * @param {number} position.lat - The latitude value.
 * @returns {Object} An object containing the formatted latitude and longitude:
 *   - {number} lat - The latitude in decimal format.
 *   - {number} lon - The longitude in decimal format.
 */
function positionToLatLon(position) {
    function formatNumber(number) {
        const decimals = 7;
        return _numberFormat(number, decimals, '.', '');
    }

    const lon = position.lng || position.lon;
    const lat = position.lat;
    return {
        lat: +formatNumber(+lat),
        lon: +formatNumber(+lon),
    };
}

/**
 * Rounds a value to a specified precision.
 * @param {number} value - The value to round.
 * @param {number} precision - The precision to round to, where 10^precision determines the rounding factor.
 * @returns {number} The rounded value.
 */
function roundValue(value, precision) {
    return Math.round(value * precision) / precision;
}
