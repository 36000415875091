const SideMapView = require('../SideMapView');
const _ = require('lodash');
const KartoMap = require('../SearchPageMapView');
const Views = require('./Views');
const {isKartoEnabled, getFirstPathnameParam} = require('../utils/Karto');

let instance;

module.exports = {
    prevPathNameParam: null,
    previousKartoState: null,
    get: function () {
        const firstPathnameParam = getFirstPathnameParam();
        if (firstPathnameParam !== this.prevPathNameParam || !_.get(instance, 'map')) {
            this.prevPathNameParam = firstPathnameParam;
            const currentKartoState = isKartoEnabled('searchResultsPage');
            if (currentKartoState !== this.previousKartoState) {
                this.previousKartoState = currentKartoState;
                instance = currentKartoState ? new KartoMap() : new SideMapView();
                Views.map = instance;
            }
        }
        return instance;
    },
};
