const _ = require('lodash');
const BrowserDetect = require('browser-detect');

module.exports = {
    enable,
};

function enable($el, options) {
    // add the selected attribute the the selected option, or else bootstrap-select will unselect it
    _.each($el, select => {
        if (!select.multiple && select.selectedIndex >= 0) {
            const selectedOption = select.options[select.selectedIndex];
            selectedOption.setAttribute('selected', true);
        }
    });
    $el.selectpicker(options);
    if (BrowserDetect.isMobile() || BrowserDetect.isTablet()) {
        $el.selectpicker('mobile');
    }

    if (BrowserDetect.isIOS()) {
        // For iOS, add an empty disable optgroup at first element
        // An empty disabled option can not be used because it will add an empty 'tickable' line in iOS
        // http://stackoverflow.com/questions/34660500/mobile-safari-multi-select-bug (2nd solution)
        for (let i = 0; i < $el.length; i++) {
            const e = $el[i];
            if (!e.multiple) {
                continue;
            }
            const optGroup = document.createElement('OPTGROUP');
            optGroup.disabled = true;
            optGroup.hidden = true;
            e.add(optGroup, e.firstChild);
        }
    }
}
