const _ = require('lodash');
const VError = require('verror');
const assert = require('assert');

let indexedDB;

class LocalDbManager {
    constructor({dbName, dbVersion, objectStoreName, listObjectsToCreate, dbToDelete}) {
        this.objectStoreName = objectStoreName;
        this.dbVersion = dbVersion;
        this.dbName = dbName;
        this.indexedDbOpened = false;
        this.listObjectsToCreate = listObjectsToCreate;
        this.isLoaded = false;
        this.dbToDelete = dbToDelete;
    }

    _deleleDatabase(databaseName) {
        const DBDeleteRequest = indexedDB.deleteDatabase(databaseName);
        DBDeleteRequest.onsuccess = () => {
            console.info('Database ' + databaseName + ' is deleted successfully');
        };
        DBDeleteRequest.onerror = (event) => {
            const error = event.target.error;
            console.error(error, 'Unable to delete database ' + databaseName);
        };
    }

    initIfNeeded() {
        indexedDB = window.indexedDB;
        return new Promise((resolve) => {
            if (this.isLoaded) {
                resolve(null);
            } else if (indexedDB) {
                const dbOpenRequest = indexedDB.open(this.dbName, this.dbVersion);
                dbOpenRequest.onsuccess = () => {
                    this.indexedDbOpened = true;
                    this.isLoaded = true;
                    if (this.dbToDelete) {
                        this._deleleDatabase(this.dbToDelete);
                    }
                    resolve(null);
                };
                dbOpenRequest.onerror = () => {
                    this.indexedDbOpened = false;
                    this.isLoaded = true;
                    console.error('Failed to open indexedDb database with name ' + this.dbName + 'and version '
                        + this.dbVersion);
                    resolve(null);
                };
                dbOpenRequest.onupgradeneeded = (event) => {
                    const db = event.target.result;
                    for (const objectToCreate of this.listObjectsToCreate) {
                        const {objectStoreName, objectStoreOptions, indexList} = objectToCreate;
                        const objectStore = db.createObjectStore(objectStoreName, objectStoreOptions || {});
                        for (const index of indexList) {
                            const {indexName, indexKeyPath, indexOptions} = index;
                            objectStore.createIndex(indexName, indexKeyPath, indexOptions || {});
                        }
                    }
                };
            } else {
                this.indexedDbOpened = false;
                this.isLoaded = true;
            }
        });
    }

    _deleteDatabaseAndInitDb() {
        this.indexedDbOpened = false;
        this.isLoaded = false;
        return new Promise((resolve) => {
            const deleteRequest = indexedDB.deleteDatabase(this.dbName);
            deleteRequest.onsuccess = () => {
                this.initIfNeeded()
                    .then(() => {
                        resolve(null);
                    })
                    .catch((err) => {
                        this.indexedDbOpened = false;
                        this.isLoaded = true;
                        console.warn(err, 'Unable to initialise indexedDB database, using localStorage...');
                        resolve(null);
                    });
            };
            deleteRequest.onerror = () => {
                this.indexedDbOpened = false;
                this.isLoaded = true;
                resolve(null);
            };
            deleteRequest.onblocked = () => {
                this.indexedDbOpened = false;
                this.isLoaded = true;
                resolve(null);
            };
        });
    }

    _getAllDataFromObject(objectName) {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);
            request.onsuccess = (event) => {
                const db = event.target.result;
                if (_.includes(db.objectStoreNames, objectName)) {
                    const objectStore = db
                        .transaction(objectName, 'readwrite')
                        .objectStore(objectName);
                    objectStore.getAll().onsuccess = (event) => {
                        const result = event.target.result;
                        db.close();
                        resolve(result);
                    };
                    objectStore.getAll().onerror = (event) => {
                        const error = event.target.error;
                        db.close();
                        reject(new VError(error, 'Error occurred when trying to get all data from object %s in indexedDb'
                            + ' database %s', objectName, this.dbName));
                    };
                } else {
                    db.close();
                    console.warn('Object ' + objectName + ' do not exist in indexedDB database ' + this.dbName);
                    this._deleteDatabaseAndInitDb(objectName)
                        .then(() => {
                            resolve([]);
                        });
                }
            };
            request.onerror = (event) => {
                const error = event.target.error;
                reject(new VError(error, 'Error occurred when trying to open database %s during get all data from'
                    + ' object %s', this.dbName, objectName));
            };
        });
    }

    async getAllData(objectName) {
        if (this.indexedDbOpened) {
            return await this._getAllDataFromObject(objectName);
        } else {
            return this._getAllDataFromLocalStorage(objectName);
        }
    }

    _getAllDataFromLocalStorage() {
        assert.fail('Not implemented');
    }

    _updateDataInIndexedDbObject(objectName, data, action) {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);
            request.onsuccess = (event) => {
                const db = event.target.result;
                if (_.includes(db.objectStoreNames, objectName)) {
                    const transaction = db
                        .transaction(objectName, 'readwrite');
                    const objectStore =
                        transaction.objectStore(objectName);
                    _.forEach(data, data => {
                        objectStore[action](data);
                    });
                    transaction.oncomplete = function () {
                        db.close();
                        resolve(null);
                    };
                    transaction.onerror = function (event) {
                        reject(new Error(`Transaction error ${event.target.error}: failed to ${action} data in indexedDB`));
                        db.close();
                    };
                } else {
                    db.close();
                }
            };
            request.onerror = (event) => {
                const error = event.target.error;
                reject(new VError(error, 'Error occurred when trying to open database %s during %s of'
                    + ' data %s in object %s', this.dbName, action, JSON.stringify(data), objectName));
            };
        });
    }

    async update({objectName, newData, dataToRemoveIds, dataToKeep}) {
        if (this.indexedDbOpened) {
            await this._updateIndexedDbObject({objectName, newData, dataToRemoveIds});
        } else {
            this._updateLocalStorage({objectName, newData, dataToKeep});
        }
    }

    async _updateIndexedDbObject({objectName, newData, dataToRemoveIds}) {
        if (!_.isEmpty(dataToRemoveIds)) {
            await this._updateDataInIndexedDbObject(objectName, dataToRemoveIds, 'delete');
        }
        if (!_.isEmpty(newData)) {
            await this._updateDataInIndexedDbObject(objectName, newData, 'add');
        }
    }

    _updateLocalStorage() {
        assert.fail('Not implemented');
    }
}

module.exports = LocalDbManager;
