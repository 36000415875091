const namespaceComponents = require('../../vue/components/helpers/namespaceComponents');

const NAMESPACE = 'user';

module.exports = namespaceComponents(NAMESPACE, {
    'info-picture': require('./InfoPicture'),
    'info-row': require('./InfoRow'),
    'info-role': require('./InfoRole'),
    'actions-dropdown': require('./UserActionsDropdown'),
});
