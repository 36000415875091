const $ = require('jquery');
const _ = require('lodash');
const async = require('async');
const querystring = require('querystring');
const {EventEmitter} = require('events');
const urlUtil = require('url');

const ServerConfig = require('../ServerConfig');
const WindowMessages = require('../utils/events/WindowMessages');
const UserAgentHelper = require('../../common/nativeApp/UserAgentHelper');
const {
    setAuthenticatedAccount: nativeSetAuthenticatedAccount,
    logout,
    getInstanceIdToken,
    getNotificationsCapabilitiesJSON,
} = require('../utils/NativeInterfaceProxy');
const ApplicationConfig = require('../app/ApplicationConfig');
const Errors = require('../utils/Errors');
const Ajax = require('../utils/Ajax');
const AccountImage = require('../../common/AccountImage');
const Roles = require('../../common/Roles');
const Views = require('../views/Views');
const Options = require('../Options');
const {
    AGENCY_ACCOUNT_TYPE,
    DEVELOPER_ACCOUNT_TYPE,
    NETWORK_ACCOUNT_TYPE,
} = require('../../common/AccountTypes');

const {DEFAULT_MAX_IMAGE_SIZE} = AccountImage;

const emitter = new EventEmitter();
const forgetAuthenticationListeners = [];
let undergoingAuthentications = 0;

module.exports = _.extend(
    emitter,
    {
        get,
        find,
        create,
        update,
        bindAuthenticationMessageHandler,
        authenticate,
        authenticateFromCookie,
        forgetAuthentication,
        addForgetAuthenticationAsyncListener,
        isAuthenticated,
        getAuthenticatedAccount,
        getAuthenticatedAccountId,
        getAccessToken,
        setGuestAccountEnabled,
        addAccountFilter,
        setAuthenticatedAccount,
        isRegistered,
        checkCredentialIdAvailable,
        requestResetAccountPassword,
        resetAccountPassword,
        isAgency,
        isDeveloper,
        isNetwork,
        hasRole,
        hasAnyRole,
        hasPasswordAuth,
        hasConnectedThroughSection,
        getCredType,
        getAccountAndCreateGuestIfNeeded,
        sendWelcomeAgencyEmail,
        sendProAccountCompletedEmail,
        hasPassword,
        authAjax,
        sendJson,
        postJson,
        putJson,
        captchaValidationRequired,
        findOwnedAccounts,
        isAncestorOfAccount,
        getAuthenticatedAccountOwnedAccounts,
        getRelatedAccounts,
        getAccountImageUrl,
        authenticateWithAccessToken,
        disableSavedSearchesForEmail,
        reactivateSavedSearch,
        hasOnlyClosedOrDisabledOwnedAccounts,
        canMarkAdsAsLeading,
        canBuyTemporaryLeadingAds,
        canHighlightAds,
        canBoostAds,
        canDisplayAdsNotificationBoostsHistory,
        hasContract,
        addReferrers,
        getReferrer,
        hasImport,
        getAllEmails,
        getAccountsNames,
        canActOnImports,
        updateAccountPushRegistration,
        subscribeToNewsletter,
        getPropertyValue,
        hasPushNotifications,
        waitForUndergoingAuthentication,
        getAuthorizationHeaders,
        canEnablePublicPage,
    },
    getRoleTesters()
);

const ACCESS_TOKEN_COOKIE_NAME = 'access_token';
const cookieOptions = {
    path: '/',
    expires: 365,
    secure: location.protocol == 'https:',
};
let currentReferrers = [];
let authenticatedAccount = null;
let guestAccountEnabled = false;
const accountFilters = [];
let guestCreationCallback = [];

function getRoleTesters() {
    const testers = {};
    _.each(Roles.getKeysList(), role => {
        testers['is' + _.upperFirst(role)] = _.partial(hasRole, role);
    });
    return testers;
}

function get(idOrNamespace, cb) {
    getAccount({id: idOrNamespace}, cb);
}

function find(data, cb) {
    const query = {};
    if (hasAnyRole()) {
        query.access_token = getAccessToken();
    }
    _.extend(query, data);
    return sendGetRequest('/accounts', query, function (err, results) {
        if (err || !results || !results.success) {
            err = err || new Error(_.defaultTo(results && results.message, 'Unable to get accounts with query'));
            cb(err);
        } else {
            fixAccounts(results.accounts);
            cb(null, results);
        }
    });
}

function fixAccounts(accounts) {
    _.each(accounts, function (account) {
        fixAccount(account);
    });
}

function fixAccount(account) {
    if (account.agencyFeeUrl) {
        account.feesUrl = account.agencyFeeUrl;
    } else if (account.hasAgencyFeesContent) {
        account.feesUrl = `${ServerConfig.config.wwwUrl}/honoraires-agence/${account.id}`;
    }

    const keysWithUrl = ['website', 'facebook', 'twitter', 'linkedin', 'instagram', 'feesUrl'];
    _.each(keysWithUrl, (key) => {
        fixWebsite(account, key);
    });
    account.websiteDisplayName = generateDisplayNameForUrl(account.website);
}

function getAccount(data, cb) {
    sendGetRequest('/account', _.extend(data, {
        access_token: getAccessToken(),
    }), function (err, result) {
        if (!err && result.success) {
            fixAccount(result.account);
            cb(null, result.account);
        } else {
            cb(err || result.message);
        }
    });
}

function getAccountOrAuthenticatedAccount(accountToCheck) {
    return accountToCheck || authenticatedAccount || {};
}

function getAllEmails(accountToCheck) {
    const account = getAccountOrAuthenticatedAccount(accountToCheck);
    let accountEmails = [];
    if (account.email) {
        accountEmails.push(account.email);
    }
    const contacts = _.get(account, 'contacts', []);
    const contactsEmails = _(contacts).map('email').compact().value();
    accountEmails = accountEmails.concat(contactsEmails);
    return _.uniq(accountEmails);
}

function fixWebsite(account, name) {
    let website = account[name];
    if (website) {
        const url = urlUtil.parse(website);
        if (!url.protocol) {
            website = 'http://' + website;
        }
        account[name] = website;
    }
}

//only keep host and path (if not `/` ) for display
function generateDisplayNameForUrl(url) {
    if (url) {
        const {
            path = '',
            host = '',
            search,
        } = urlUtil.parse(url);
        let displayPath = path;
        if (search) {
            displayPath = _.replace(displayPath, search, '…');
        }
        if (displayPath === '/') {
            displayPath = '';
        }
        let displayedName;
        try {
            displayedName = decodeURI(host + displayPath);
        } catch (e) {
            console.error(e, 'Could not decode uri', host + displayPath);
        }
        return displayedName || url;
    } else {
        return undefined;
    }
}

function create(account, cb) {
    const queryObj = {
        create: account.email,
    };
    const accessToken = getAccessToken();
    if (accessToken && !isRegistered()) {
        queryObj.guest_access_token = accessToken;
    }
    sendPostRequest(
        '/account/dataFromUser?' + querystring.stringify(queryObj),
        account,
        getAuthenticationResultHandler(function (err, result) {
            if (result && result.success) {
                const newsLetter = Boolean(result.account.wantsToReceiveDirectOffersAndNewsLetter);
                emitter.emit('accountCreated', {
                    type: 'password',
                    accountId: result.account.id,
                    wantsToReceiveDirectOffersAndNewsLetter: newsLetter,
                    //todo id conversion ?
                });
            }
            cb(err, result);
        })
    );
}

/**
 *
 * @param {function} fn optional validator, signature function(data, cb). should call cb(err, validationStatus)
 * and validationStatus with either error detailing error or success set to true
 */
function addAccountFilter(fn) {
    accountFilters.push(fn);
}

/**
 * @param {boolean} enabled is guest account enabled (for authentication with cookie and logout)
 */
function setGuestAccountEnabled(enabled) {
    guestAccountEnabled = Boolean(enabled);
}

function checkCredentialIdAvailable(data, callback) {
    sendPostRequest('/credentialIdAvailable', data, callback);
}

function requestResetAccountPassword(data, callback) {
    sendPostRequest('/requestResetAccountPassword', data, callback);
}

function resetAccountPassword(resetPasswordToken, data, callback) {
    sendPostRequest('/resetAccountPassword?resetPasswordToken=' + resetPasswordToken, data, callback);
}

/**
 *
 * @param {object} accountDataToModify data in accountDataToModify will be used to change dataFromUser (with merging), only send changes
 * @param {function} cb
 * @param {object} [options]
 */
function update(accountDataToModify, cb, options) {
    const updatedAccountId = accountDataToModify.id;
    const isUpdatingAuthenticatedAccount = updatedAccountId == getAuthenticatedAccountId();
    const queryObj = {
        id: updatedAccountId,
        access_token: getAccessToken(),
    };
    sendRequest({
        path: '/account/dataFromUser?' + querystring.stringify(queryObj),
        type: 'PUT',
        data: JSON.stringify(_.omit(accountDataToModify, 'id')),
    }, isUpdatingAuthenticatedAccount ? getAuthenticationResultHandler(callback, options) : callback);

    function callback(err, result) {
        if (result && result.success) {
            const newsLetter = Boolean(accountDataToModify.wantsToReceiveDirectOffersAndNewsLetter);
            emitter.emit('accountUpdated', {
                updatedAccountId,
                wantsToReceiveDirectOffersAndNewsLetter: newsLetter,
            });
        }
        cb(err);
    }
}

function authenticate(credentials, cb) {
    //TODO avoid multiple simultaneous authenticate/autoAuthenticate
    undergoingAuthentications++;
    sendPostRequest('/authenticate', credentials, getAuthenticationResultHandler(cb));
}

function mergePreviousGuestAccount(guestAccessToken, targetAccountAccessToken, cb) {
    sendPostRequest('/merge?access_token=' + encodeURIComponent(targetAccountAccessToken), {
        access_token: guestAccessToken,
    }, cb);
}

function getAuthenticationResultHandler(cb, options) {
    return function (err, result) {
        if (!err && result.success) {
            async.waterfall([
                function (cb) {
                    cb(err, result);
                },
            ].concat(accountFilters), function (err, result) {
                if (!err && result.success) {
                    const accessToken = getAccessToken();
                    const newAuthenticatedAccount = result.account;
                    if (accessToken && !isRegistered() && accessToken != newAuthenticatedAccount.access_token) {
                        // eslint-disable-next-line handle-callback-err
                        mergePreviousGuestAccount(accessToken, newAuthenticatedAccount.access_token, function (err, mergeResult) {
                            let resultToSend = result;
                            let accountToAuth = result.account;
                            if (mergeResult && mergeResult.success) {
                                resultToSend = mergeResult;
                                accountToAuth = mergeResult.account;
                                emitter.emit('mergeAccounts', {
                                    mergedAccountId: getAuthenticatedAccountId(),
                                    targetAccountId: mergeResult.account.id,
                                });
                            }
                            setAuthenticatedAccount(accountToAuth, options, function (err) {
                                cb(err, resultToSend);
                            });
                        });
                    } else {
                        setAuthenticatedAccount(newAuthenticatedAccount, options, function () {
                            cb(null, result);
                        });
                    }
                } else if (err) {
                    cb(err);
                } else {
                    cb(null, {
                        success: false,
                        reason: result.error || result,
                    });
                }
            });
        } else {
            cb(err, result);
        }
        undergoingAuthentications--;
        emitter.emit('authenticationAttemptCompleted');
    };
}

function sendPostRequest(path, data, cb) {
    return sendRequest({
        path,
        type: 'POST',
        data: JSON.stringify(data),
    }, cb);
}

function sendGetRequest(path, data, cb) {
    return sendRequest({
        path,
        type: 'GET',
        data,
    }, cb);
}

function sendRequest(options, callback) {
    const path = options.path;
    const type = options.type;
    const data = options.data;
    const {accountUrl} = ServerConfig.config;
    return Ajax.request({
        url: accountUrl + path,
        type,
        data,
        contentType: 'application/json',
        disableErrorPage: true,
        timeout: 30000, //30 seconds
        callback: function (err, data) {
            if (err) {
                if (err.status == 'abort') {
                    return;
                }
                console.error(path + ': ' + err.message);
            }
            callback(err, data);
        },
    });
}

function authenticateFromCookie(cb) {
    const accessToken = getAccessTokenFromCookie();
    if (accessToken) {
        undergoingAuthentications++;
        sendPostRequest('/autoAuthenticate', {access_token: accessToken}, getAuthenticationResultHandler(cb));
    } else {
        cb(null, null);
    }
}

function createGuest(requiresProAccount, callback) {
    if (guestAccountEnabled) {
        const request = '/autoAuthenticate?createGuestAccountOnFailure' + (requiresProAccount ?
            '&requiresProAccount' : '');
        sendPostRequest(request, {}, getAuthenticationResultHandler(callback, {emitAccountChange: false}));
    }
}

function getAccountAndCreateGuestIfNeeded(callback) {
    const account = getAuthenticatedAccount();
    if (!account) {
        if (guestCreationCallback.length == 0) {
            guestCreationCallback.push(callback);
            createGuest(ApplicationConfig.requiresProAccount, function (err) {
                if (err) {
                    // none of the callers of getAccountAndCreateGuestIfNeeded can handle an error
                    Errors.showUnexpectedError(err);
                } else {
                    _.each(guestCreationCallback, function (waitingCallback) {
                        waitingCallback(null, getAuthenticatedAccount() || null);
                    });
                    guestCreationCallback = [];
                }
            });
        } else {
            guestCreationCallback.push(callback);
        }
    } else {
        callback(null, account);
    }
}

function authenticateWithAccessToken(accessToken, cb) {
    authenticate({
        access_token: accessToken,
    }, cb);
}

function saveAccessTokenInCookie(accessToken) {
    $.cookie(ACCESS_TOKEN_COOKIE_NAME, accessToken, cookieOptions);
}

// eslint-disable-next-line complexity
function setAuthenticatedAccount(account, options, cb) {
    cb = cb || _.noop;
    if (!_.isEqual(authenticatedAccount, account)) {
        options = _.defaults(options || {}, {
            emitAccountChange: true,
        });
        const accountIdBefore = authenticatedAccount && authenticatedAccount.id;
        const accountIdAfter = account && account.id;
        const wasRegisteredBefore = authenticatedAccount && isRegistered(authenticatedAccount) || false;
        const isRegisteredAfter = account && isRegistered(account);
        authenticatedAccount = account;
        if (authenticatedAccount) {
            saveAccessTokenInCookie(authenticatedAccount.access_token);
            if (hasRole('showRoom')) {
                // a showroom account cannot logout, so no need to remember the previous value to set it back later
                Options.setForThisSessionOnly('commercialAdsEnabled', false);
                Options.setForThisSessionOnly('financialPartnerCreditSimulationEnabled', false);
            }
        }
        if (accountIdBefore !== accountIdAfter && (isRegisteredAfter || wasRegisteredBefore)) {
            emitter.emit('change:id', {previousAccountId: accountIdBefore, newAccountId: accountIdAfter});
        }
        if (wasRegisteredBefore !== isRegisteredAfter) {
            emitter.emit('change:registration');
        }
        if (null == accountIdAfter) {
            emitter.emit('forgetAccount');
        }
        if (options.emitAccountChange) {
            emitter.emit('change');
        }
        if (nativeSetAuthenticatedAccount) {
            nativeSetAuthenticatedAccount(JSON.stringify(authenticatedAccount));
        } else if (UserAgentHelper.isFromNativeAndroidApp()) {
            if (accountIdAfter) {
                // previous account registration removal is automatically done server side
                pushFCMRegistration(accountIdAfter, 'POST');
            } else if (accountIdBefore) {
                pushFCMRegistration(accountIdBefore, 'DELETE');
            }
        }
        cb();
    } else {
        cb();
    }
}

function getAccessToken() {
    return authenticatedAccount && authenticatedAccount.access_token;
}

function getAccessTokenFromCookie() {
    return $.cookie(ACCESS_TOKEN_COOKIE_NAME);
}

function addForgetAuthenticationAsyncListener(fn) {
    forgetAuthenticationListeners.push(fn);
}

function forgetAuthentication(cb) {
    const accountId = getAuthenticatedAccountId();
    async.eachSeries(forgetAuthenticationListeners, (fn, cb) => {
        fn({accountId}, cb);
    }, () => {
        $.removeCookie(ACCESS_TOKEN_COOKIE_NAME, cookieOptions);
        setAuthenticatedAccount(null, null);
        if (logout) {
            logout();
        }
        if (cb) {
            cb();
        }
    });
}

/**
 *
 * @returns {?object} the authenticated account, or null if non authenticated
 */
function getAuthenticatedAccount() {
    return authenticatedAccount;
}

/**
 *
 * @returns {?string} the id of the authenticated account, or null if non authenticated
 */
function getAuthenticatedAccountId() {
    return authenticatedAccount ? authenticatedAccount.id : null;
}

function isRegistered(account) {
    const accountToCheck = _.defaultTo(account, authenticatedAccount);
    if (accountToCheck) {
        const {credentials, company: {name, corporateName} = {}} = accountToCheck;
        return Boolean(credentials || name || corporateName);
    } else {
        return false;
    }
}

function isAgency(accountToCheck) {
    const account = getAccountOrAuthenticatedAccount(accountToCheck);
    return account.accountType === AGENCY_ACCOUNT_TYPE;
}

function isDeveloper(accountToCheck) {
    const account = getAccountOrAuthenticatedAccount(accountToCheck);
    return account.accountType === DEVELOPER_ACCOUNT_TYPE;
}

function isNetwork(accountToCheck) {
    const account = getAccountOrAuthenticatedAccount(accountToCheck);
    return account.accountType === NETWORK_ACCOUNT_TYPE;
}

function hasPasswordAuth(accountToCheck) {
    const account = accountToCheck || authenticatedAccount;
    let passwordAuth = false;
    if (account) {
        const passwordCredentials = _.find(account.credentials, function (cred) {
            return cred.type == 'password';
        });
        if (passwordCredentials) {
            passwordAuth = (passwordCredentials.type == 'password');
        }
    }
    return passwordAuth;
}

function hasConnectedThroughSection(accountToCheck) {
    const account = accountToCheck || authenticatedAccount;
    let connectedThroughSection = false;
    if (account) {
        const credentials = _.find(account.credentials, function (cred) {
            return cred.type != null;
        });
        if (credentials) {
            connectedThroughSection = (credentials.type != 'password');
        }
    }
    return connectedThroughSection;
}

function getCredType(accountToCheck) {
    const account = accountToCheck || authenticatedAccount;
    let type;
    if (account) {
        type = _.find(account.credentials, function (cred) {
            return cred.type != null;
        }).type;
    }
    return type;
}

function hasAnyRole() {
    return authenticatedAccount && !_.isEmpty(authenticatedAccount.roles);
}

function hasRole(role) {
    return Roles.testAccount(authenticatedAccount, role);
}

function isAuthenticated() {
    return authenticatedAccount != null;
}

function bindAuthenticationMessageHandler() {
    WindowMessages.on('auth', function (message) {
        if (message.access_token) {
            const accessToken = message.access_token;
            const accountCreated = message.account_created;
            const authType = message.type;
            authenticateWithAccessToken(accessToken, function (err, authenticationResult) {
                if (!err && authenticationResult.success) {
                    const authenticatedAccount = authenticationResult.account;
                    console.log('Authenticated with access token "%s" from external authentication', accessToken);
                    if (accountCreated) {
                        const newsLetter = Boolean(authenticatedAccount.wantsToReceiveDirectOffersAndNewsLetter);
                        emitter.emit('accountCreated', {
                            type: authType,
                            accountId: authenticatedAccount.id,
                            wantsToReceiveDirectOffersAndNewsLetter: newsLetter,
                        });
                    }
                } else {
                    console.error(
                        'Could not authenticate with access token "%s" from external authentication',
                        accessToken
                    );
                    if (!err && !authenticationResult.success && authenticationResult.reason.message === 'closedOrDisabled') {
                        Views.volatileFeedback.showError('loginError.closedOrDisabled');
                    } else {
                        Views.volatileFeedback.showError();
                    }
                }
            });
        }
    });
}

function sendWelcomeAgencyEmail(options, callback) {
    const {accountUrl} = ServerConfig.config;
    postJson({
        url: accountUrl + '/sendWelcomeAgencyEmail',
        data: {
            accountId: options.accountId,
            recipientEmail: options.recipientEmail,
            accessToken: getAccessToken(),
        },
        timeout: 30000, //30 seconds
        disableErrorPage: true,
        callback,
    });
}

function sendProAccountCompletedEmail(callback) {
    const {accountUrl} = ServerConfig.config;
    postJson({
        url: accountUrl + '/sendProAccountCompletedEmail',
        data: {
            accessToken: getAccessToken(),
        },
        timeout: 30000, //30 seconds
        disableErrorPage: true,
        callback,
    });
}

function hasPassword(data, callback) {
    sendGetRequest('/hasPassword', data, callback);
}

function getAuthorizationHeaders(account) {
    if (account && account.token) {
        return {Authorization: 'Bearer ' + account.token};
    } else {
        return {};
    }
}

function authAjax() {
    return Ajax.withHeaders(getAuthorizationHeaders(authenticatedAccount), arguments);
}

function sendJson(options) {
    const {data} = options;
    authAjax(_.extend({
        contentType: 'application/json',
    }, options, {
        data: JSON.stringify(data),
    }));
}

function postJson(options) {
    sendJson(_.extend({type: 'POST'}, options));
}

function putJson(options) {
    sendJson(_.extend({type: 'PUT'}, options));
}

function captchaValidationRequired(login, callback) {
    sendGetRequest('/captchaValidationRequired', {id: login}, callback);
}

function findOwnedAccounts(idOrNamespace, callback) {
    async.waterfall([
        _.partial(getAccount, {id: idOrNamespace}),
        (account, callback) => {
            authAjax({
                method: 'GET',
                url: ServerConfig.config.accountUrl + '/findOwnedAccounts',
                data: {id: account && account.id},
                disableErrorPage: true,
                callback: function (err, result) {
                    if (!err && result.success) {
                        callback(null, result.accounts || []);
                    } else {
                        callback(err);
                    }
                },
            });
        },
    ], callback);
}

function isAncestorOfAccount(accountId, callback) {
    if (accountId) {
        return authAjax({
            method: 'GET',
            url: `${ServerConfig.config.accountUrl}/account/${getAuthenticatedAccountId()}/isAncestorOf`,
            data: {
                presumedDescendantAccountId: accountId,
            },
            disableErrorPage: true,
            callback: function (err, result) {
                if (!err && result.success) {
                    callback(null, result.isAncestor);
                } else {
                    callback(err);
                }
            },
        });
    } else {
        callback(null, null);
    }
}

function getAuthenticatedAccountOwnedAccounts() {
    return authenticatedAccount ? authenticatedAccount.ownedAccounts : [];
}

function hasOnlyClosedOrDisabledOwnedAccounts(ownedAccounts) {
    return _.every(ownedAccounts, (account) => account.disabled || account.closed);
}

function getRelatedAccounts(users, cb) {
    const relatedIds = _.uniq(_.compact(
        _.map(users, 'importAccountId')
            .concat(_.map(users, 'disabledBy'))
            .concat(_.map(users, 'networkClosedBy'))
            .concat(_.flatten(_.map(users, 'ownerIds')))
    ));
    if (relatedIds.length && (hasRole('admin') || hasRole('dirViewer') || hasRole('accountDisabler'))) {
        return getAccountsNames(relatedIds, cb);
    } else {
        cb(null, {});
    }
}

function getAccountImageUrl(account, maxWidth = DEFAULT_MAX_IMAGE_SIZE, maxHeight = DEFAULT_MAX_IMAGE_SIZE) {
    return AccountImage.getAccountImageUrl(ServerConfig.config.fileUrl, account, maxWidth, maxHeight);
}

function updateAccountPushRegistration(accountId, pushRegistration, method, cb) {
    pushRegistrationByAccountId(accountId, {
        payload: JSON.parse(JSON.stringify(pushRegistration)),
        type: 'web',
    }, method, cb);
}

function pushFCMRegistration(accountId, method) {
    let payload;
    let clientCapabilities;

    try {
        payload = {instanceIdToken: getInstanceIdToken()};
        if (getNotificationsCapabilitiesJSON) {
            clientCapabilities = JSON.parse(getNotificationsCapabilitiesJSON());
        }
    } catch (e) {
        console.error('Could not get payload data from app: ' + e.message);
    }

    if (payload && payload.instanceIdToken) {
        pushRegistrationByAccountId(accountId, {
            payload,
            type: 'fcm',
            clientCapabilities,
        }, method);
    }
}

function pushRegistrationByAccountId(accountId, data, method, cb) {
    const serverErrorMessage = method + 'account pushRegistration';
    sendJson({
        url: ServerConfig.config.accountUrl + '/account/pushRegistrations?id=' + accountId,
        type: method,
        disableErrorPage: true,
        timeout: 30000, //30 seconds
        data,
        serverErrorMessage,
        callback: cb,
    });
}

function disableSavedSearchesForEmail(email, cb) {
    postJson({
        url: `${ServerConfig.config.savedSearchesUrl}/disableSavedSearchesByEmail?${querystring.stringify({email})}`,
        disableErrorPage: true,
        timeout: 30000, //30 seconds
        serverErrorMessage: 'disableSavedSearchesForEmail',
        callback: cb,
    });
}

function reactivateSavedSearch(searchId, cb) {
    const qs = querystring.stringify({
        searchId,
        accountId: getAuthenticatedAccountId(),
    });
    authAjax({
        url: `${ServerConfig.config.savedSearchesUrl}/reactivateSavedSearch?${qs}`,
        method: 'POST',
        disableErrorPage: true,
        contentType: 'application/json',
        timeout: 30000, //30 seconds
        serverErrorMessage: 'reactivateSavedSearch',
        callback: cb,
    });
}

function canMarkAdsAsLeading(accountToCheck) {
    const account = getAccountOrAuthenticatedAccount(accountToCheck);
    return Roles.testAccount(account, 'adModifier') || account.maxLeadingAds > 0 || account.maxLeadingAdsOn;
}

function canBuyTemporaryLeadingAds(accountToCheck) {
    const account = getAccountOrAuthenticatedAccount(accountToCheck);
    return account.canBuyTemporaryLeadingAds;
}

function canHighlightAds(accountToCheck) {
    const account = getAccountOrAuthenticatedAccount(accountToCheck);
    return Roles.testAccount(account, 'adModifier') || account.maxHighlightedAds > 0 || account.maxHighlightedAdsOn;
}

function canBoostAds(accountToCheck) {
    const account = getAccountOrAuthenticatedAccount(accountToCheck);
    return account.maxAdsToSendInNotificationsPerMonth > 0;
}

function canDisplayAdsNotificationBoostsHistory() {
    const authenticatedAccount = this.getAuthenticatedAccount();
    return authenticatedAccount && !_.isNil(authenticatedAccount.maxAdsToSendInNotificationsPerMonth);
}

function hasContract() {
    return _.has(authenticatedAccount, 'contract');
}

function addReferrers(referrers) {
    //add referrers to account, at the end of the array, only keeping unique instances
    currentReferrers = referrers;
    getAccountAndCreateGuestIfNeeded((err, account) => {
        if (err) {
            console.error('Could not get account to add referrers', err);
        } else {
            const previousReferrers = account.referrers;
            account.referrers = _.difference(account.referrers || [], referrers).concat(referrers);
            if (!_.isEqual(previousReferrers, account.referrers)) {
                const accountDataToModify = {id: account.id, referrers: account.referrers};
                update(accountDataToModify, callback, {emitAccountChange: false});
            }
            //even if they did not changed in account, they may have changed from currentReferrers.
            emitter.emit('referrerChanged', account.referrers);
        }
    });

    function callback(err) {
        if (err) {
            console.error('Could not add referrers', err);
        }
    }
}

function getReferrer() {
    const account = getAuthenticatedAccount();
    //fallback to currentReferrers when account is not yet ready.
    const referrers = _.get(account, 'referrers') || currentReferrers;
    return _.last(referrers);
}

function hasImport() {
    return Boolean(authenticatedAccount && authenticatedAccount.import);
}

function getAccountsNames(accountIds, cb) {
    return sendGetRequest('/getAccountNames', {
        access_token: getAccessToken(),
        accountIds,
    }, (err, idsToNames) => {
        if (err) {
            console.error('Could not get accounts names', err);
            cb(null, {});
        } else {
            cb(null, idsToNames);
        }
    });
}

function canActOnImports() {
    return hasRole('admin');
}

function subscribeToNewsletter(email, callback) {
    getAccountAndCreateGuestIfNeeded((err, account) => {
        if (err) {
            callback(err);
        } else if (account) {
            postJson({
                url: `${ServerConfig.config.accountUrl}/account/${account.id}/subscribeToNewsletter`,
                data: {
                    email,
                },
                disableErrorPage: true,
                timeout: 30000, //30 seconds
                serverErrorMessage: 'subscribeToNewsletter',
                callback: (err, {account} = {}) => {
                    if (!err) {
                        if (account) {
                            setAuthenticatedAccount(account);
                        }
                        callback(null);
                    } else {
                        callback(err);
                    }
                },
            });
        } else {
            callback(new Error('Unable to get account'));
        }
    });
}

function getPropertyValue(propertyName) {
    const authenticatedAccount = this.getAuthenticatedAccount();
    return authenticatedAccount && authenticatedAccount[propertyName];
}

function hasPushNotifications() {
    const authenticatedAccount = this.getAuthenticatedAccount();
    return authenticatedAccount && !_.isEmpty(authenticatedAccount.pushRegistrations);
}

function waitForUndergoingAuthentication(cb) {
    if (undergoingAuthentications > 0) {
        emitter.once('authenticationAttemptCompleted', cb);
    } else {
        setImmediate(cb);
    }
}

function canEnablePublicPage() {
    const authenticatedAccount = this.getAuthenticatedAccount();
    return authenticatedAccount && authenticatedAccount.canEnablePublicPage;
}
