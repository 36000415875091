const _ = require('lodash');
const AdsPrintsStorageManager = require('./localDb/AdsPrintsStorageManager');
const ServerConfig = require('../ServerConfig');
const Account = require('../authentication/Account');
const RealtimeServer = require('../RealtimeServer');

const adsPrintsIndexedDb = new AdsPrintsStorageManager();

let realEstateAdIds = [];
let pendingRequest = false;
const PRINTED_ADS_TIME_TO_LIVE_DURATION_IN_MS = 3600e3;
const ADS_PRINTS_OBJECT_STORE_NAME = 'adsPrints';

module.exports = {
    collectAdsPrints,
    ADS_PRINTS_OBJECT_STORE_NAME,
};

const delayInMsBeforeSendingAdsPrints = _.get(ServerConfig.config, 'delayInMsBeforeSendingAdsPrints');
const collectPrintsDebounced = _.debounce(() => {
    if (!_.isEmpty(realEstateAdIds) && !pendingRequest) {
        pendingRequest = true;
        Account.getAccountAndCreateGuestIfNeeded((err, account) => {
            pendingRequest = false;
            if (err) {
                console.error('Could not get account info');
            } else {
                adsPrintsIndexedDb.initIfNeeded()
                    .then(() => {
                        adsPrintsIndexedDb.getAllData(ADS_PRINTS_OBJECT_STORE_NAME)
                            .then((printedAds) => {
                                const {validPrintedAds, expiredPrintedAds} = formatAdsPrintsAsValidAndExpiredData(printedAds);
                                const validPrintedAdsIds = _.map(validPrintedAds, 'realEstateAdId');
                                const expiredPrintedAdsIds = _.map(expiredPrintedAds, 'realEstateAdId');
                                const newAdsToPrintIds = _.without(realEstateAdIds, ...validPrintedAdsIds);
                                if (!_.isEmpty(newAdsToPrintIds) || !_.isEmpty(expiredPrintedAdsIds)) {
                                    const now = new Date();
                                    const newAdsToPrint = _.map(newAdsToPrintIds, adId => {
                                        return {
                                            realEstateAdId: adId,
                                            expirationTime: now.getTime() + PRINTED_ADS_TIME_TO_LIVE_DURATION_IN_MS,
                                        };
                                    });
                                    adsPrintsIndexedDb.update({
                                        objectName: ADS_PRINTS_OBJECT_STORE_NAME,
                                        newData: newAdsToPrint,
                                        dataToRemoveIds: expiredPrintedAdsIds,
                                        dataToKeep: validPrintedAds,
                                    })
                                        .then(() => {
                                            if (!_.isEmpty(newAdsToPrintIds)) {
                                                RealtimeServer.emit('ads:prints', {
                                                    useRedisList: true,
                                                    realEstateAdIds: newAdsToPrintIds,
                                                    accountId: account.id,
                                                });
                                            }
                                            realEstateAdIds = [];
                                        })
                                        .catch((err) => {
                                            console.error(err, 'Unable to update localDb storage');
                                        });
                                }
                            })
                            .catch((err) => {
                                console.error(err, 'Unable to get data from localDb');
                            });
                    })
                    .catch((err) => {
                        console.error(err, 'Unable to collect real estate ads prints');
                    });
            }
        });
    }
}, delayInMsBeforeSendingAdsPrints);

function collectAdsPrints(realEstateAdId) {
    // remove duplicate real estate ad ids
    realEstateAdIds = _.union(realEstateAdIds, [realEstateAdId]);
    collectPrintsDebounced();
}

function formatAdsPrintsAsValidAndExpiredData(printedAds) {
    const validPrintedAds = [];
    const expiredPrintedAds = [];
    const now = new Date();
    for (const printedAd of printedAds) {
        if (now.getTime() > printedAd.expirationTime) {
            expiredPrintedAds.push(printedAd);
        } else {
            validPrintedAds.push(printedAd);
        }
    }
    return {
        validPrintedAds,
        expiredPrintedAds,
    };
}
