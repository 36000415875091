const ContactBlockWithTitle = require('./BlockWithTitle');
const ContactText = require('./ContactText');
const ContactPhone = require('./ContactPhone');
const template = require('./Call.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

// @vue/component
module.exports = {
    components: {
        ContactBlockWithTitle,
        ContactText,
        ContactPhone,
    },
    mixins: [
        i18nMixin({
            prefix: 'Call.',
            keys: [
                'title',
                'withheldNumber',
                'answeredCall',
                'missedCall',
            ],
        }),
    ],
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    computed: {
        entries() {
            const {
                contact: {
                    callerPhoneNumber,
                    targetPhoneNumber,
                    transactionType,
                    answered,
                    durationFormatted,
                    callWaitingTimeFormatted,
                },
                withheldNumber,
                answeredCall,
                missedCall,
                t,
            } = this;
            const entries = [];
            pushEntry('callerPhoneNumber', callerPhoneNumber || withheldNumber, {usePhoneLink: callerPhoneNumber});
            pushEntry('targetPhoneNumber', targetPhoneNumber, {usePhoneLink: true});
            if (transactionType) {
                pushEntry('transactionType', this.t('transactionType', {context: transactionType}));
            }
            pushEntry('status', answered ? answeredCall : missedCall);
            if (answered) {
                pushEntry('duration', durationFormatted);
                pushEntry('callWaitingTime', callWaitingTimeFormatted);
            }
            return entries;

            function pushEntry(key, value, {usePhoneLink} = {}) {
                entries.push({
                    key,
                    name: t(key),
                    value,
                    type: usePhoneLink ? 'contact-phone' : 'contact-text',
                });
            }
        },
    },
    template: template(),
};
