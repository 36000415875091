const _ = require('lodash');
const moment = require('../../../common/momentFr');
const i18nMixin = require('../../vue/components/mixins/i18n');
const template = require('./ContactListContact.jade');
const Account = require('../../authentication/Account');
const {getDisplayName} = require('../../utils/NamesFormatter');
const {getPhoneNumberToDisplay} = require('../../utils/PhoneNumberHelper');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'contactView.contactList.',
            keys: [
                'failedContactMessage',
                'noMessage',
            ],
        }),
    ],
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isRegistered() {
            return Account.isRegistered();
        },
        formattedDate() {
            const {date} = this.contact;
            const now = new Date();
            const dayLimitToUseRelativeDate = 1;
            const useRelativeDate = moment(now).diff(date, 'days') < dayLimitToUseRelativeDate;
            return useRelativeDate ? moment(date).fromNow() : moment(date).format('dddd DD MMMM YYYY [à] HH[h]mm');
        },
        hasError() {
            const {contact} = this;
            return contact.error || contact.failedReason;
        },
        message() {
            return this.contact.message;
        },
        footerText() {
            const {sender} = this.contact;
            const {
                email,
                phone,
            } = sender;

            const usableName = getDisplayName(sender);
            const formattedPhone = getPhoneNumberToDisplay(phone);

            return _.compact([
                usableName,
                email,
                formattedPhone,
            ]).join(' - ');
        },
    },
    template: template(),
};
