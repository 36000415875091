const ContactText = require('./ContactText');
const ContactLink = require('./internal/ContactLink');

// @vue/component
module.exports = {
    mixins: [
        ContactText,
    ],
    computed: {
        valueComponent() {
            return ContactLink('mailto:' + this.value);
        },
    },
};
