const _ = require('lodash');

const Options = require('../../../Options');
const template = require('./DiscoverSplitText.jade');

// @vue/component
module.exports = {
    props: {
        photoUrl: {
            type: String,
            default: undefined,
        },
        coverPosition: {
            type: String,
            default: 'center',
        },
        commercialAdType: {
            type: String,
            default: undefined,
        },
        withAdvertisement: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasPhoto() {
            return !_.isEmpty(this.photoUrl);
        },
        imagePosition() {
            return `center ${this.coverPosition}`;
        },
        imageAlt() {
            return `Discover image ${this.coverPosition}`;
        },
        useCommercialAd() {
            return this.withAdvertisement && Options.get('commercialAdsEnabled');
        },
    },
    template: template(),
};
