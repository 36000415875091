const _ = require('lodash');
const moment = require('moment');
const assert = require('assert');

const template = require('./ContactsResults.jade');
const DataTable = require('../../pro/components/DataTable');
const ContactDetails = require('./ContactDetails');
const i18nMixin = require('../../vue/components/mixins/i18n');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const formatContact = require('../formatContact');
const getTranslationKeysMatchingContact = require('../getTranslationKeysMatchingContact');
const FetchHelper = require('../contactsLoader');
const BemClassesMixin = require('../../vue/mixins/BemClasses');
const ApplicationConfig = require('../../app/ApplicationConfig');
const Account = require('../../authentication/Account');
const {isFiltered} = require('../../utils/filteredFields');

const COLUMNS = [
    {
        label: 'type',
        sortable: false,
    },
    {
        label: 'name',
        sortable: true,
        sortBy: contact => contact.displayName,
    },
    {
        label: 'subject',
        sortable: true,
        sortBy: contact => contact.messageTitle,
    },
    {
        label: 'callerPhoneNumber',
        sortable: true,
    },
    {
        label: 'date',
        sortable: true,
    },
];
const CONTACT_TYPE_ICONS = {
    accountContacts: 'contact-type-account-contacts',
    adSubmissions: 'contact-type-ad-submission',
    calls: 'contact-type-call',
    calls_failed: 'contact-type-call-missed',
    contactRequest: 'contact-type-contact-request',
    estimationRequests: 'contact-type-estimation-request',
};
const NO_DATA_DISPLAYED = '-';

// @vue/component
module.exports = {
    components: {
        DataTable,
        ContactDetails,
    },
    constants: {
        DEFAULT_SORT_KEY: 'date',
    },
    mixins: [
        i18nMixin({
            prefix: 'ContactsResults.',
            keys: [
                'callsInformationsWithHeldNumber',
                'truncatedDataWarningMessage',
            ],
        }),
        BemClassesMixin,
    ],
    props: {
        startDate: {
            type: Object,
            required: true,
        },
        endDate: {
            type: Object,
            required: true,
        },
        disallowSort: Boolean,
        maxContacts: {
            type: Number,
            default: undefined,
        },
        accountId: {
            type: String,
            required: true,
        },
        showTitle: Boolean,
        isOwnerView: Boolean,
        exportLink: {
            type: String,
            default: undefined,
        },
        showTruncatedDataWarning: Boolean,
        filters: {
            type: Array,
            default: undefined,
        },
        contacts: {
            type: Array,
            default: undefined,
        },
        hideRealEstateAdInfo: Boolean,
        contactsPerPage: {
            type: Number,
            default: undefined,
        },
        scrollIntoViewOnPageChange: Boolean,
    },
    data() {
        return {
            contactsData: [],
            hasError: false,
            isLoading: false,
            hasTruncatedData: false,
        };
    },
    computed: {
        isEmpty() {
            return _.isEmpty(this.contactsData);
        },
        exportButtonText() {
            return this.t('export.' + (this.isOwnerView ? 'myContacts' : 'contacts'));
        },
        columns() {
            const {t, disallowSort} = this;
            return _.map(COLUMNS, column => {
                const {label} = column;
                return {
                    ...column,
                    formattedLabel: t(`dataTable.header.${label}`),
                    sortable: disallowSort ? false : column.sortable,
                };
            });
        },
        dataTableTranslationTexts() {
            const {formattedStartDate, formattedEndDate, dataTableTranslate} = this;
            const account = Account.getAuthenticatedAccount();
            const noResultsContext = ApplicationConfig.applicationPro && !account.canSeeChildrenContacts ?
                'proWithoutAccessToChildrenContacts' : (!formattedStartDate && !formattedEndDate ? 'noDates' : '');
            return {
                noResultsMessage: dataTableTranslate('noResultsMessage', {
                    context: noResultsContext,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                }),
                titleSuffix: dataTableTranslate('titleSuffix'),
                titleSuffixPlural: dataTableTranslate('titleSuffix', {context: 'plural'}),
                loadingMessage: dataTableTranslate('loading'),
            };
        },
        formattedStartDate() {
            const {startDate} = this;
            return startDate ? DateFormatter.humanizeDay(startDate) : '';
        },
        formattedEndDate() {
            const {endDate} = this;
            return endDate ? DateFormatter.humanizeDay(endDate) : '';
        },
    },
    watch: {
        startDate() {
            this.fetchContacts();
        },
        endDate() {
            this.fetchContacts();
        },
        filters() {
            this.fetchContacts();
        },
        contacts(value) {
            this.contactsData = this.formatContacts(value);
        },
    },
    mounted() {
        const {accountId, startDate, endDate, contacts} = this;
        if (contacts) {
            this.contactsData = this.formatContacts(contacts);
        } else {
            assert(accountId, 'When not passing contacts, the component needs an accountId');
            assert(startDate && endDate, 'When passing accountId, the component needs a startDate and an endDate');
            this.fetchContacts();
        }
    },
    methods: {
        formatContacts(contacts) {
            return _.map(contacts, contact => formatContact(contact));
        },
        dataTableTranslate(key, options = {}) {
            return this.t('dataTable.' + key, options);
        },
        fetchContacts() {
            const {accountId, startDate, endDate, filters} = this;
            this.isLoading = true;
            FetchHelper.fetchAccountContacts({
                accountId,
                filters,
                fromDate: moment(startDate).startOf('day').toISOString(),
                toDate: moment(endDate).add(1, 'day').startOf('day').toISOString(),
            }, (err, {total, contactRequests}) => {
                this.isLoading = false;
                if (err) {
                    this.hasError = true;
                } else {
                    this.contactsData = this.formatContacts(contactRequests);
                    this.hasTruncatedData = total > contactRequests.length;
                }
            });
        },
        contactTypeIcon(contact) {
            const iconKey = contact.type + (this.hasContactFailed(contact) ? '_failed' : '');
            return CONTACT_TYPE_ICONS[iconKey] || CONTACT_TYPE_ICONS[contact.type];
        },
        hasContactFailed(contact) {
            return contact.answered === false || contact.sent === false;
        },
        contactTypeIconModifier(contact) {
            return {
                failed: this.hasContactFailed(contact),
            };
        },
        labelTranslationKeys(contact) {
            return getTranslationKeysMatchingContact(contact, 'labels');
        },
        isCall(contact) {
            return contact.type === 'calls';
        },
        getFormattedDate(contact) {
            return moment(contact.date).calendar();
        },
        getDataTableCellClass(cellName) {
            return this.getBemClasses(`cell-${cellName}`)[0];
        },
        phoneToDisplay(contact) {
            let phoneToDisplay;
            const {callerPhoneNumber} = contact;
            if (isFiltered(callerPhoneNumber)) {
                phoneToDisplay = NO_DATA_DISPLAYED;
            } else {
                phoneToDisplay = callerPhoneNumber
                    || (this.isCall(contact) ? this.t('callsInformationsWithHeldNumber') : NO_DATA_DISPLAYED);
            }
            return phoneToDisplay;
        },
        nameToDisplay(contact) {
            let name;
            const {displayName} = contact;
            if (this.isCall(contact)) {
                name = '';
            } else if (isFiltered(displayName)) {
                name = NO_DATA_DISPLAYED;
            } else {
                name = displayName;
            }
            return name;
        },
    },
    template: template(),
};
