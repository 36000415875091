const _ = require('lodash');
const BrowserDetect = require('browser-detect');

const adsManager = require('./adsManager');
const blurTypes = require('../../common/blurTypes');

const {
    resultsPerPage: defaultResultsPerPage,
    maxAuthorizedResults: defaultMaxAuthorizedResults,
} = require('../../common/DefaultConfiguration');
const Options = require('../Options');
const configOptions = Options.read();

module.exports = class SearchResults {
    constructor(options) {
        this.resultsPerPage = options.resultsPerPage || defaultResultsPerPage;
        this.maxAuthorizedResults = options.maxAuthorizedResults || defaultMaxAuthorizedResults;
        this.searchCriteria = _.clone(options.searchCriteria);
        // todo : check if next line is necessary because searchCriteria.page seems to not be handled server side
        this.searchCriteria.page = Math.min(this.searchCriteria.page, this.getLastAuthorizedPage());
        this.enableAggregates = options.enableAggregates;
        this.savedSearchId = options.savedSearchId;
        this._load = _.bind(adsManager.loadResults, adsManager);
        this._loadOptions = options.loadOptions;
        this._pages = [];
        this._leadingAds = [];
        this.titleOptions = this.searchCriteria;
    }

    loadPage(pageIndex, cb) {
        const options = {
            enableGoogleStructuredDataAggregates: true,
            pageIndex: Math.min(pageIndex, this.getLastAuthorizedPageIndex()),
        };

        options.withLeadingAds = true;

        return this._loadResults(options, cb);
    }

    loadAggregates(cb) {
        const options = {
            resultsPerPage: 0,
            enableAggregates: true,
        };
        this._loadResults(options, cb);
    }

    _getFiltersFromOptions(options) {
        const pageIndex = options.pageIndex || 0;
        const resultsPerPage = options.resultsPerPage || this.resultsPerPage;
        return _.extend(
            {
                size: resultsPerPage,
                from: Math.min(this.maxAuthorizedResults, pageIndex * resultsPerPage),
                showAllModels: configOptions.showAllModels,
            },
            this.searchCriteria,
            this._loadOptions && this._loadOptions.filters,
            _(options)
                .pick([
                    'limit',
                    'propertyType',
                    'propertyType.base',
                    'newProperty',
                    'programmeWith3dFirst',
                ])
                .omitBy(_.isNil)
                .value()
        );
    }

    _loadResults(options, cb) {
        let pageIndex = options.pageIndex || 0;
        const {enableAggregates = this.enableAggregates, enableGoogleStructuredDataAggregates} = options;
        const filters = this._getFiltersFromOptions(options);
        if (filters.limit || options.blurInfoType) {
            filters.blurInfoType = options.blurInfoType || blurTypes.DISPLAYED_ON_MAP;
        }

        let leadingCount;
        if (options.withLeadingAds) {
            if (this._loadOptions && this._loadOptions.leadingCount >= 0) {
                leadingCount = this._loadOptions.leadingCount;
            } else {
                leadingCount = 2;
            }
            if (BrowserDetect.isMobile()) {
                leadingCount = Math.min(1, leadingCount);
            }
        }
        const loadingOptions = _.extend({
            callback: _.bind(function (err, result) {
                if (!err) {
                    _.extend(this, _.pick(result, [
                        'totalResults',
                        'filterCounts',
                        'adsTotalLimit',
                        'titleOptions',
                        'updatedFilters',
                        'isExtendedWithSearchAround',
                        'googleStructuredData',
                    ]));
                    pageIndex = this.pageIndex = result.pageIndex || pageIndex;
                    if (result.values) {
                        this._pages[pageIndex] = result.values;
                    }
                    if (result.leadingAds) {
                        this._leadingAds[pageIndex] = result.leadingAds;
                    }
                }
                cb(err, result && result.values);
            }, this),
        }, this._loadOptions, {
            filters,
            enableAggregates,
            enableGoogleStructuredDataAggregates,
            leadingCount,
            canExtendResults: true,
            savedSearchId: this.savedSearchId,
        });
        return this._load(loadingOptions);
    }

    getPage(pageIndex) {
        return this._pages[pageIndex];
    }

    getLeadingAds(pageIndex) {
        return this._leadingAds[pageIndex];
    }

    _getAllLeadingAdsMatchingAdId(adId) {
        return _.filter(_.flatten(this._leadingAds), {id: adId});
    }

    getAdById(adId) {
        const ad = this.getAdInPages(adId);
        const leadingAds = this._getAllLeadingAdsMatchingAdId(adId);
        return _.find([ad, ...leadingAds], el => !_.isNil(el));
    }

    updateAd(adId, updateFunction) {
        const adsToUpdate = _.compact([
            this.getAdInPages(adId),
            ...this._getAllLeadingAdsMatchingAdId(adId),
        ]);
        _.each(adsToUpdate, updateFunction);
    }

    getAdInPages(adId) {
        const adIndex = this.findAdIndex({id: adId});
        const {ad} = this.getAdWithPaginationData(adIndex);
        return ad;
    }

    getAdWithPaginationData(adIndex) {
        const pageIndex = this._getPageForAdIndex(adIndex);
        const indexInPage = adIndex - this.resultsPerPage * pageIndex;
        const ad = _.get(this._pages, [pageIndex, indexInPage]);
        return {pageIndex, indexInPage, ad};
    }

    loadAdsOnMap(options, cb) {
        return this._loadResults(_.extend({
            pageIndex: 0,
            enableAggregates: false,
            blurInfoType: blurTypes.DISPLAYED_ON_MAP,
        }, options), cb);
    }

    //returns -1 if ad not found
    findAdIndex(ad) {
        const resultsPerPage = this.resultsPerPage;
        let adIndex = -1;

        _.each(this._pages, (page, pageIndex) => {
            const adIndexInPage = findAdIndexInPage(page, ad);
            if (adIndexInPage >= 0) {
                adIndex = resultsPerPage * pageIndex + adIndexInPage;
                return false; // exit each
            }
        });
        return adIndex;
    }

    getFilters() {
        return _.extend({}, this.searchCriteria, this._loadOptions && this._loadOptions.filters);
    }

    getAd(adIndex, cb) {
        const {pageIndex, indexInPage, ad} = this.getAdWithPaginationData(adIndex);
        if (ad) {
            cb(null, ad);
        } else {
            this.loadPage(pageIndex, _.bind(function (err) {
                if (err) {
                    cb(err);
                } else {
                    cb(null, this._pages[pageIndex][indexInPage]);
                }
            }, this));
        }
    }

    _getPageForAdIndex(adIndex) {
        return Math.floor(adIndex / this.resultsPerPage);
    }

    clearPagesCache() {
        this._pages = [];
    }

    getLastAuthorizedPage() {
        return Math.ceil(this.maxAuthorizedResults / this.resultsPerPage);
    }

    getLastAuthorizedPageIndex() {
        return this.getLastAuthorizedPage() - 1;
    }

    hasSearchAroundExtendedResults() {
        return Boolean(this.isExtendedWithSearchAround) && this.totalResults > 0;
    }

    hasExtendedResults() {
        return this.hasSearchAroundExtendedResults() || (Boolean(this.updatedFilters) && this.totalResults > 0);
    }

    getExtendedSearchCriteria() {
        return _.extend({}, this.searchCriteria, this.updatedFilters);
    }
};

function findAdIndexInPage(page, ad) {
    return _.findIndex(page, {id: ad.id});
}
