const $ = require('jquery');
const SunCalc = require('suncalc');
const moment = require('../../../common/momentFr');
const template = require('./SunAnimator.jade');
const noUiSlider = require('../../lib/noUiSlider/nouislider');
const i18nMixin = require('../../vue/components/mixins/i18n');
const datePickerTemplate = require('./datePicker.jade');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'SunAnimator.',
            keys: ['title'],
        }),
    ],
    props: {
        map: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dropdownOpen: false,
            dayTimePercent: 75,
            date: moment.utc().month(6/*July*/).date(27).startOf('day').toDate(),
        };
    },
    watch: {
        date() {
            this.computeAndUpdateMapDate();
        },
        dayTimePercent() {
            this.computeAndUpdateMapDate();
        },
    },
    mounted() {
        document.addEventListener('click', this.closeDropdownOnClickOutside);
        this.initSlider();
        this.initDatePicker();
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
    },
    methods: {
        toggleDropdown() {
            const zIndex = !this.dropdownOpen ? 1 : '';
            $('.karto-pois-interface').css('z-index', zIndex);
            $('#kimono-graphicOptionsMenu').css('z-index', zIndex);
            this.dropdownOpen = !this.dropdownOpen;
        },
        closeDropdownOnClickOutside(event) {
            if (this.dropdownOpen && !this.$refs.dropdown.contains(event.target)) {
                this.dropdownOpen = false;
            }
        },
        initSlider() {
            const sunPositionSlider = document.getElementById('kimono-sunPositionSlider');
            noUiSlider.create(sunPositionSlider, {
                start: [this.dayTimePercent],
                range: {
                    min: 0,
                    max: 100,
                },
            });
            const self = this;
            sunPositionSlider.noUiSlider.on('update', (values, handle) => {
                this.dayTimePercent = parseFloat(values[handle]);
                self.updateSunAndSky(values);
            });
        },
        initDatePicker() {
            this.$container = $('.kimono-dateSelectorContainer');
            this.$element = $(datePickerTemplate());
            this.$container.append(this.$element);
            this.$datePicker = this.$element.find('#kimono-dateSelector');
            const datepicker = this.$datePicker.datepicker({
                autoclose: true,
                format: 'd MM yyyy',
                language: 'fr',
            });
            this.$datePicker.datepicker('setDate', new Date(this.date));
            const self = this;
            datepicker.on('clearDate changeDate', () => {
                self.date = moment.utc(this.$datePicker.datepicker('getDate'), 'YYYY-MM-DD');
            });
        },
        updateSunAndSky(values) {
            const $sky = $('#kimono-sky');
            const $sun = $('#kimono-sunPosition');
            const path = $('#kimono-theMotionPath').get(0);
            const pathLength = path.getTotalLength();
            const p = path.getPointAtLength((values) / 100 * pathLength);
            const transform = 'translate(' + p.x + 'px,' + p.y + 'px)';
            $sun.css({transform: transform});
            const bgpx = -(140 - Math.round(p.y)) + 'px';
            $sky.css({'background-position-x': bgpx});
        },
        computeAndUpdateMapDate() {
            const msPerHours = 60 * 60 * 1000;
            const msPerDegreesLon = (24 * msPerHours) / 360;
            const center = this.map.getCenter();
            const lon = center.lng;
            const lat = center.lat;
            const noon = this.date.valueOf() + (12 * msPerHours) - msPerDegreesLon * lon;
            const sunCalcProperties = SunCalc.getTimes(new Date(noon), lat, lon);
            let start = sunCalcProperties.sunrise.valueOf();
            let end = sunCalcProperties.sunset.valueOf();
            if (isNaN(start) || isNaN(end)) {
                start = noon - 12 * msPerHours;
                end = noon + 12 * msPerHours;
            }
            this.map.setDate(new Date(Math.round(start + ((end - start) * this.dayTimePercent / 100))));
        },
        onTimeButtonClick(value) {
            this.dayTimePercent = value;
            const sunPositionSlider = document.getElementById('kimono-sunPositionSlider');
            sunPositionSlider.noUiSlider.set(value);
            this.updateSunAndSky(value);
        },
    },
    template: template(),
};
