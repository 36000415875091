const {EventEmitter} = require('events');
const SideMapViewSingleton = require('../views/SideMapViewSingleton');
const AsyncHelper = require('../utils/AsyncHelper');
const isSoldRealEstateAd = require('../utils/isSoldRealEstateAd');
const _ = require('lodash');
const fack = require('fack');
const ngeohash = require('ngeohash');
const EventPack = require('../utils/EventPack');

const eventPack = new EventPack();

const ICON_URL = fack.resourceUrl('images/map/markers/marker_noprice.png');
const TILE_ZOOMS = [
    {zoom: 2, requestZoom: 7, scale: 0.2, geoHashPrecision: 7},
    {zoom: 3, requestZoom: 7, scale: 0.2, geoHashPrecision: 7},
    {zoom: 4, requestZoom: 7, scale: 0.2, geoHashPrecision: 7},
    {zoom: 5, requestZoom: 7, scale: 0.2, geoHashPrecision: 7},
    {zoom: 6, requestZoom: 7, scale: 0.2, geoHashPrecision: 7},
    {zoom: 7, requestZoom: 7, scale: 0.45, geoHashPrecision: 7},
    {zoom: 8, requestZoom: 9, scale: 0.45, geoHashPrecision: 9},
    {zoom: 9, requestZoom: 9, scale: 0.6, geoHashPrecision: 9},
    {zoom: 10, requestZoom: 9, scale: 0.6, geoHashPrecision: 9},
    {zoom: 11, requestZoom: 9, scale: 1, geoHashPrecision: 9},
    {zoom: 12, requestZoom: 9, scale: 1, geoHashPrecision: 9},
    {zoom: 13, requestZoom: 9, scale: 1.2, geoHashPrecision: 9},
    {zoom: 14, requestZoom: 9, scale: 2.1, geoHashPrecision: 9},
    {zoom: 15, requestZoom: 13, scale: 2.4, geoHashPrecision: 9},
    {zoom: 16, requestZoom: 13, scale: 2.5, geoHashPrecision: 9},
    {zoom: 17, requestZoom: 13, scale: 2.6, geoHashPrecision: 9},
    {zoom: 18, requestZoom: 13, scale: 2.6, geoHashPrecision: 9},
    {zoom: 19, requestZoom: 13, scale: 2.6, geoHashPrecision: 9},
    {zoom: 20, requestZoom: 13, scale: 2.6, geoHashPrecision: 9},
    {zoom: 21, requestZoom: 13, scale: 2.6, geoHashPrecision: 9},
];

let cachedPoints;

module.exports = class KartoAllResultsGrid extends EventEmitter {
    constructor(options) {
        super();
        this.options = options;
        this._gridZoomForMinimalData = this.options.zoomForMinimalData - 1;
        this.loadTilesDebounced = _.debounce(_.bind(this.handleTilesByZoom, this), 50);
        this.searchCriteria = null;
        this.searchOptions = null;
        this.tiles = [];
        this.alreadyDisplayedAds = [];
        this.visibleTiles = [];
        this.activePoints = [];
        this.processedTiles = [];
        this.isProcessingTiles = false;
        this.scale = 0;
        this.isIdle = false;
        this._isShown = false;
        this.adToHide = null;
        this._isEnabled = true;
        this._hoverEnabled = true;
        this.previousTiles = [];
        this.isDeleted = true;
        this.processedPoints = [];
        this.requestedTiles = [];
        this.oldActivePoints = [];
    }

    show() {
        const sideMapView = SideMapViewSingleton.get();
        const {map} = sideMapView;
        this.map = map;
        this._isShown = true;
        map.once('idle', this._onMapIdle.bind(this));
        if (cachedPoints) {
            cachedPoints.forEach(point => map.remove3DPoints(point.id));
            cachedPoints = [];
        }
        this._registerEventListeners();
    }

    _onMapIdle() {
        this.isIdle = true;
        this.loadTilesDebounced();
    }

    _registerEventListeners() {
        eventPack.on(this.map, {
            selection: this._onSelection.bind(this),
            mousein: this._onMouseIn.bind(this),
            mouseout: this._onMouseOut.bind(this),
            zoom: this._onZoom.bind(this),
        });
    }

    _onSelection(event) {
        if (event.type !== kartoEngine.SelectionType.POINTS_3D || !event.result.feature.isSmallMarker) {
            return;
        }
        const adInfo = event.result.feature.ad;
        if (!adInfo.isFakeId && !this._shouldUseMinimalData() && !isSoldRealEstateAd(adInfo)) {
            this.emit('click', adInfo, null, event);
        }
    }

    _onMouseIn(event) {
        if (!this._hoverEnabled || event.type !== kartoEngine.SelectionType.POINTS_3D || !event.result.feature.isSmallMarker) {
            return;
        }
        const adInfo = event.result.feature.ad;
        this._currentAdAtMouse = adInfo;
        if (!adInfo.isFakeId && !this._shouldUseMinimalData()) {
            this.emit('mouseOver', adInfo);
        }
    }

    _onMouseOut(event) {
        if (event.type === kartoEngine.SelectionType.POINTS_3D && event.result.feature.isSmallMarker) {
            const adInfo = event.result.feature.ad;
            this.emit('mouseOut', adInfo);
        }
    }

    _onZoom() {
        if (this.isIdle) {
            this.handleTilesByZoom();
        }
    }

    handleTilesByZoom() {
        if (this.map) {
            if (this.customTilesData) {
                this.cancelAsyncTasks();
                this.map.removeCustomTilesData(this.customTilesData);
                delete this.customTilesData;
            }
            this.scale = this.getScaleByZoom(this.map.getTileZoom());
            this.processTiles();
        }
    }

    processTiles() {
        const tileZoom = TILE_ZOOMS.find(tile => tile.zoom === Math.floor(this.map.getZoom()));
        const requestZoom = tileZoom ? tileZoom.requestZoom : null;
        this.oldActivePoints = [...this.activePoints]; // Store current active points for later removal
        this.customTilesData = new kartoEngine.SimpleCustomTilesData({
            map: this.map,
            minZoom: 3,
            maxZoom: 21,
            maxTileDistance: 5,
            maxTilePerIteration: 1,
            tilesZoomLevel: requestZoom,
            addTilesProcessor: {
                requestTiles: this.addRequestTiles.bind(this),
                processTiles: this.addProcessTiles.bind(this),
            },
            removeTilesProcessor: {
                processTile: () => {},
            },
        });
        this.map.addCustomTilesData(this.customTilesData);
    }

    addRequestTiles(tiles) {
        return new Promise((resolve, reject) => {
            this.tiles = _.map(tiles, tile => {
                tile.useMinimalData = this._shouldUseMinimalData();
                tile.id = `${tile.canonical[2]}_${tile.canonical[0]}_${tile.canonical[1]}`;
                return tile;
            });
            this.asyncHelper = this.asyncHelper || new AsyncHelper();
            const tileKeys = _.map(this.tiles, tile => tile.id);
            const searchCriteria = {...this.searchCriteria, tileKeys};
            const gridZoom = this._getGridZoomForCameraZoom(this.map.getZoom());
            this._geoHashPrecision = gridZoom.geoHashPrecision;
            const searchOptions = {geoHashPrecision: this._geoHashPrecision, ...this.searchOptions};
            const loader = this.tiles[0].useMinimalData ? this.options.loadMinimal : this.options.load;
            this.asyncHelper.doAsync({
                func: cb => loader(searchCriteria, searchOptions, cb),
                callback: (err, allResults) => {
                    if (err) {
                        return reject(err);
                    }
                    resolve(allResults);
                },
            });
        });
    }

    addProcessTiles(tiles, allResults) {
        if (!tiles || !allResults) {
            return;
        }
        const removedStatuses = [
            kartoEngine.TileUtils.TileStatus.REMOVED,
            kartoEngine.TileUtils.TileStatus.CANCELED,
        ];
        _.forEach(tiles, (tile, index) => {
            const tileStatus = tile.getStatus();
            if (!tileStatus || _.includes(removedStatuses, tileStatus)) {
                return;
            }
            this.processTileData(tile, allResults[index]);
        });
        // Clear oldActivePoints
        this.removePoints(this.oldActivePoints, this.map, 0);
    }

    processTileData(tile, resultsByTile) {
        if (!resultsByTile) {
            return;
        }
        tile.data = resultsByTile;
        const ads = resultsByTile.ads || [];
        tile.markerData = this._createMarkerData(ICON_URL, tile, ads);
        const featuresToHide = [];
        const data = this.generateTileFeatures(tile, featuresToHide);
        if (data.features.length === 0) {
            return;
        }
        const opts = this.getTileOptions();
        const pointsId = this.map.create3DPoints(data, opts);
        tile.userData.ad_points = pointsId;
        data.features.push(...featuresToHide);
        this.activePoints.push({id: pointsId, data, opts});
    }

    generateTileFeatures(tile, featuresToHide) {
        const data = {
            type: 'FeatureCollection',
            features: [],
        };
        const coordinates = tile.markerData.markerPoints;
        if (coordinates) {
            _.forEach(coordinates, coords => {
                const feature = {
                    id: coords.id,
                    type: 'Feature',
                    geometry: {
                        coordinates: coords.position,
                        type: 'Point',
                    },
                    ad: coords.ad,
                    isSmallMarker: true,
                };
                if (!_.includes(this.alreadyDisplayedAds, coords.id) && coords.id !== this.adToHide) {
                    data.features.push(feature);
                } else {
                    featuresToHide.push(feature);
                }
            });
        }
        return data;
    }

    _createMarkerData(url, tile, ads) {
        const markerData = {
            adDetails: {},
            adIds: [],
            geometries: [],
            lineGeometries: [],
            meshes: [],
            lineMeshes: [],
            iconUrl: url,
            count: 0,
            visibleCount: 0,
            isMinimalData: false,
            tileId: tile.id,
            markerPoints: [],
        };
        const useMinimalData = tile.useMinimalData;
        const numberOfAds = ads.length;
        if (numberOfAds > 0) {
            let frontCounter = 0;
            _.forEach(ads, (ad, idxAd) => {
                if (useMinimalData) {
                    const geoHashData = ad;
                    const latLon = ngeohash.decode(geoHashData);
                    ad = {
                        id: `${markerData.tileId}_${idxAd}`,
                        lat: latLon.latitude,
                        lon: latLon.longitude,
                        isFakeId: true,
                    };
                } else {
                    ad.isSmallMarker = true;
                }
                const fullIndex = frontCounter++;
                markerData.adDetails[ad.id] = {
                    index: fullIndex,
                    ad,
                    visible: true,
                    markerData,
                };
                markerData.adIds[fullIndex] = ad.id;
                markerData.markerPoints.push({id: ad.id, position: [ad.lon, ad.lat], ad});
            });
            markerData.count = numberOfAds;
            markerData.visibleCount = frontCounter;
        }
        return markerData;
    }

    getTileOptions() {
        return {
            defaultHeight: 0,
            depthTest: false,
            allowOverlap: false,
            sizeFactor: this.scale,
            color: '#EA516C',
            dimensionPixel: 4,
            line: {enable: false},
            clickable: !this._shouldUseMinimalData(),
            hoverable: !this._shouldUseMinimalData(),
        };
    }

    hide() {
        this.cancelAsyncTasks();
        eventPack.removeAllListeners();
        this.map.removeCustomTilesData(this.customTilesData);
        delete this.customTilesData;
        cachedPoints = _.clone(this.activePoints);
        _.forEach(this.activePoints, (point, index) => {
            this.map.remove3DPoints(point.id);
            cachedPoints.splice(index, 1);
        });
        this._isShown = false;
    }

    setSearchCriteria(searchCriteria) {
        this.searchCriteria = searchCriteria;
        this.isProcessingTiles = false;
        if (this.isIdle) {
            this.loadTilesDebounced();
        }
    }

    removePoints(points, map) {
        if (points.length === 0) {
            return;
        }
        _.forEach(points, (point) => {
            map.remove3DPoints(point.id);
            this.activePoints = _.filter(this.activePoints, activePoint => activePoint.id !== point.id);
        });
    }

    isShown() {
        return this._isShown;
    }

    update() {
        this.hide();
        this.show();
    }

    cancelAsyncTasks() {
        if (this.asyncHelper) {
            this.asyncHelper.cancelAll();
        }
    }

    updatePoints(points) {
        _.forEach(points, (point) => {
            if (point.data.features.length > 0) {
                const point3d = this.map.getPoints3DManager().getPoints3D(point.id);
                if (point3d) {
                    this.map.update3DPoints(point.id, point.data, point.opts);
                }
            }
        });
    }

    addAdToHide(adId) {
        this.adToHide = adId;
        const updatedPoints = this.removeFeaturesFromPoints(this.activePoints, [adId]);
        this.updatePoints(updatedPoints);
    }

    removeAdToHide() {
        this.updatePoints(this.activePoints);
        this.adToHide = '';
    }

    setAlreadyDisplayedAds(ads) {
        this.alreadyDisplayedAds = ads;
        this.alreadyDisplayedAds.push(this.adToHide);
        const updatedPoints = this.removeFeaturesFromPoints(this.activePoints, ads);
        this.updatePoints(updatedPoints);
    }

    removeFeaturesFromPoints(points, featuresIdsToRemove) {
        return _.map(points, obj => {
            return {
                ...obj,
                data: {
                    ...obj.data,
                    features: _.filter(obj.data.features, feature => !_.includes(featuresIdsToRemove, feature.id)),
                },
            };
        });
    }

    setHoverEnabled(enabled) {
        this._hoverEnabled = enabled;
    }

    setEnabled(enabled) {
        this._isEnabled = enabled;
    }

    _shouldUseMinimalData() {
        const cameraZoom = Math.floor(this.map.getZoom());
        return (cameraZoom <= this._gridZoomForMinimalData);
    }

    getScaleByZoom(zoomLevel) {
        const tileZoom = _.find(TILE_ZOOMS, {zoom: zoomLevel});
        return tileZoom ? tileZoom.scale : 0;
    }

    _getGridZoomForCameraZoom(cameraZoom) {
        let zoom = TILE_ZOOMS[0].requestZoom;
        let geoHashPrecision = 9;
        for (let i = 0; i < TILE_ZOOMS.length; i++) {
            const zoomInfo = TILE_ZOOMS[i];
            if (zoomInfo.zoom <= cameraZoom) {
                zoom = zoomInfo.requestZoom;
                geoHashPrecision = zoomInfo.geoHashPrecision;
            }
        }
        return {
            zoom,
            geoHashPrecision,
        };
    }
};
