const _ = require('lodash');
const {isFiltered} = require('./filteredFields');

module.exports = {
    getDisplayName,
};

function getDisplayName({firstName, lastName, name}) {
    return getFormattedName(lastName, firstName) || name;
}

function getFormattedName(lastName, firstName) {
    let formattedName;
    if (isFiltered(lastName)) {
        formattedName = lastName;
    } else if (isFiltered(firstName)) {
        formattedName = firstName;
    } else {
        formattedName = _.compact([formatLastName(lastName), formatFirstName(firstName)]).join(' ');
    }
    return formattedName;
}

function formatFirstName(firstNames) {
    const firstNamesFormatted = upperFirstBySeparator(_.toLower(firstNames), '-');
    return upperFirstBySeparator(firstNamesFormatted, ' ');
}

function upperFirstBySeparator(firstNames, separator) {
    return _(firstNames)
        .split(separator)
        .map(_.upperFirst)
        .join(separator);
}

function formatLastName(lastName) {
    return _.toUpper(lastName);
}
