const LoadScriptHelper = require('./utils/LoadScriptHelper');
const ServerConfig = require('./ServerConfig');
const {isWebGL2Supported} = require('./utils/WebglSupport');

let isInitialized = false;

const karto = {
    engine: null,
    noWebGl: false,
    editableAdMarker: null,
    modificationAdMap: null,
    agencyPoi: null,
    currentMap: null,
    init: function loadKarto(node, options, onMapInitializedCbk) {
        if (!isInitialized) {
            LoadScriptHelper.loadScript(ServerConfig.config.kartoApiUrl, {
                crossoriginAllowed: true,
                successCallback: () => {
                    initKarto(node, options, onMapInitializedCbk);
                    isInitialized = true;
                },
                errorCallback: function (err) {
                    console.error('Failed to load Map: ' + err);
                    onMapInitializedCbk(null);
                },
                attributes: {
                    crossorigin: 'anonymous',
                },
            });
        } else {
            initKarto(node, options, onMapInitializedCbk);
        }
        function initKarto(node, options, onMapInitializedCbk) {
            if (!isWebGL2Supported()) {
                console.error('WebGL is not fully supported or disabled on your browser');
                karto.noWebGl = true;
                if (typeof onMapInitializedCbk === 'function') {
                    onMapInitializedCbk(null);
                }
            } else {
                if (typeof kartoEngine !== 'undefined' &&
                        kartoEngine && !options.keepMap && kartoEngine.Engine.getInstance().getNbMaps() !== 0
                ) {
                    const totalMaps = kartoEngine.Engine.getInstance().getNbMaps();
                    for (let index = 0; index < totalMaps; index++) {
                        kartoEngine.Engine.getInstance().removeMap(index);
                    }
                    karto.currentMap = null;
                    karto.modificationAdMap = null;
                    karto.editableAdMarker = null;
                }
                kartoEngineLoader.load(() => {
                    const apiKey = ServerConfig.config.kartoApiKeyForClient;
                    const engine = kartoEngine.Engine.getInstance(apiKey);
                    karto.engine = engine;
                    const map = engine.createMap(node, options);
                    map.initialize().then(() => {
                        map.getHeading = function () {
                            return map.getBearing();
                        };
                        map.getTilt = function () {
                            return map.getPitch();
                        };
                        map.getMapTypeId = function () {
                            return map._mapTypeId;
                        };
                        map.setMapTypeId = function (mapTypeId) {
                            map._mapTypeId = mapTypeId;
                        };
                        if (typeof onMapInitializedCbk === 'function') {
                            karto.currentMap = map;
                            onMapInitializedCbk(map);
                        }
                    }).catch((err) => {
                        console.error('Error during map initialization: ' + err);
                        onMapInitializedCbk(null);
                    });
                });
            }
        }
    },
    setModificationAdMap: function (map) {
        this.modificationAdMap = map;
        this.currentMap = map;
    },
    setEditableAdMarker: function (marker) {
        this.editableAdMarker = marker;
    },
    setCurrentMap: function (map) {
        this.currentMap = map;
    },
};

module.exports = karto;
