const _ = require('lodash');

const FILTERED_KEY_NAME = 'filterReason';

module.exports = {
    FILTERED_KEY_NAME,
    isFiltered,
};

function isFiltered(value) {
    return _.has(value, FILTERED_KEY_NAME);
}
