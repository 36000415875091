const _ = require('lodash');
const {loadStripe} = require('../utils/stripeLoader');
const Account = require('../authentication/Account');
const ServerConfig = require('../ServerConfig');
const {sendErrorToAnalytics} = require('../utils/Errors');
const StripeError = require('./StripeError');

const {
    i18n: {translate},
} = require('fack');

module.exports = {
    updateAdFeaturedProperty,
    canMarkAdsAsLeading,
    canHighlightAds,
    getBuyableProducts,
    createPaymentIntent,
    updatePaymentIntent,
    validatePayment,
    sendBillingInformation,
};

/**
 * Copy realEstateAd and apply featured property on it. Return this copy
 * @param {Object} realEstateAd
 * @param {string} featureName - either `isLeading` or `highlighted`
 * @param {Object} featuredPropertyOptions - the featured property options to apply
 * @param {boolean} featuredPropertyOptions.value
 * @param {string} [featuredPropertyOptions.endDate] - featuring end date, in string format
 * @param {string} featuredPropertyOptions.modificationDate - ad modification date, in string format
 * @returns {Object} - updated copy of `realEstateAd`
 */
function updateAdFeaturedProperty(realEstateAd, featureName, {value, endDate, modificationDate}) {
    const clonedAd = _.cloneDeep(realEstateAd);
    if (modificationDate) {
        clonedAd.modificationDate = modificationDate;
    }
    _.set(clonedAd, ['status', featureName], value);
    _.set(clonedAd, ['status', featureName + 'Until'], endDate);
    return clonedAd;
}

function canSetAsFeatured(realEstateAd) {
    return _.get(realEstateAd, 'userRelativeData.canSetAsFeatured');
}

function canMarkAdsAsLeading(realEstateAd) {
    return (Account.canMarkAdsAsLeading() || Account.canBuyTemporaryLeadingAds()) && canSetAsFeatured(realEstateAd);
}

function canHighlightAds(realEstateAd) {
    return Account.canHighlightAds() && canSetAsFeatured(realEstateAd);
}

function getBuyableProducts(featureName, realEstateAdId, callback) {
    Account.authAjax({
        url: `${ServerConfig.config.adFeaturingUrl}/${featureName}/products`,
        data: {
            realEstateAdId,
        },
        disableErrorPage: true,
        callback,
    });
}

function createPaymentIntent(productToken, callback) {
    Account.postJson({
        url: `${ServerConfig.config.paymentUrl}/payment-intent`,
        data: {productToken: productToken},
        disableErrorPage: true,
        callback,
    });
}

function updatePaymentIntent(id, productToken, callback) {
    Account.putJson({
        url: `${ServerConfig.config.paymentUrl}/payment-intent/${id}`,
        data: {productToken: productToken},
        disableErrorPage: true,
        callback,
    });
}

function sendBillingInformation(paymentIntentId, billing, callback) {
    sendBillingInformationToPayment(paymentIntentId, billing, callback);
    sendBillingInformationToAccount(billing);
}

function sendBillingInformationToPayment(paymentIntentId, billing, callback) {
    Account.putJson({
        url: `${ServerConfig.config.paymentUrl}/billing/${paymentIntentId}`,
        data: _.omitBy(billing, _.isNil),
        disableErrorPage: true,
        callback,
    });
}

function sendBillingInformationToAccount(billing) {
    const authenticatedAccount = Account.getAuthenticatedAccount();
    if (authenticatedAccount && !_.isEqual(billing, authenticatedAccount.billing)) {
        Account.update({
            id: authenticatedAccount.id,
            billing,
        }, err => {
            if (err) {
                // not propagating, it's here to help prefill for next time, not a show stopper.
                console.log(err, 'Could not update billing information on account');
                sendErrorToAnalytics(err);
            }
        });
    }
}

function validatePayment(paymentIntent, {paymentMethod, email}, callback) {
    const {clientSecret} = paymentIntent;

    loadStripe().then(stripe => {
        try {
            stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod,
                receipt_email: email,
            }).then(result => {
                if (result.error) {
                    // error is an object with code, type and message
                    callback(new StripeError(result.error));
                } else {
                    callback();
                }
            });
        } catch (err) {
            // only happens on configuration errors (invalid client secret & similar)
            stripeErrorHandling('errorInPaymentConfiguration', err, callback);
        }
    }, err => {
        stripeErrorHandling('errorLoadingStripe', err, callback);
    });
}

function stripeErrorHandling(key, baseError, callback) {
    const err = new StripeError({message: translate('setFeatured.stripe.' + key), type: 'integration'}).causedBy(baseError);
    sendErrorToAnalytics(err);
    console.error(err);
    callback(err);
}
