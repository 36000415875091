const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const {getPhoneNumberToDisplay} = require('../utils/PhoneNumberHelper');
const {getDisplayName} = require('../utils/NamesFormatter');
const RealEstateAdTitleGenerator = require('../../common/RealEstateAdTitleGenerator');
const VirtualTourHelper = require('../virtualTours/VirtualTourHelper');
const {getImageUrlFromAlias} = require('../ImageHelper');
const humanizeDuration = require('humanize-duration');

const CONTACT_SENDER_FIELDS = [
    'firstName',
    'lastName',
    'name', // old format from "account_contacts"
    'accountId',
    'email',
    'ip',
    'phone',
    'userAgent',
    'postalCode',
];

module.exports = function formatContact(contact) {
    contact = fixLiveTourFormat(contact); // Remove this after livetour format migration
    const {
        callerPhoneNumber,
        duration,
        durationAnswered,
        realEstateAd,
        targetPhoneNumber,
    } = contact;
    const sender = buildSenderFromContact(contact);
    const phoneToFormat = callerPhoneNumber || sender.phone;
    return _.assignIn({}, contact, {
        callerPhoneNumber: getPhoneNumberToDisplay(phoneToFormat),
        targetPhoneNumber: getPhoneNumberToDisplay(targetPhoneNumber),
        displayName: getDisplayName(sender) || translate('contactsPro.missingName'),
        messageTitle: getMessageTitle(contact),
        realEstateAd: realEstateAd ? formatRealEstateAd(realEstateAd) : undefined,
        durationFormatted: duration ? formatDuration(duration) : undefined,
        callWaitingTimeFormatted: duration ? formatDuration(duration - (durationAnswered || 0)) : undefined,
        sender,
    });
};

function fixLiveTourFormat(contact) {
    const {
        askForLiveTour,
        liveTourAppointmentDate,
        liveTourType,
        appointmentConfirmationUrl,
    } = contact;
    if (askForLiveTour) {
        return _.extend({
            liveTour: {
                type: liveTourType,
                appointmentDate: liveTourAppointmentDate,
                appointmentConfirmationUrl,
            },
        }, contact);
    } else {
        return contact;
    }
}

function getMessageTitle({
    answered,
    duration,
    transactionType,
    realEstateAd,
    type = 'contactRequest',
}) {
    const titleByType = {
        contactRequest: _.partial(RealEstateAdTitleGenerator.getTitle, realEstateAd, 'contactRequest'),
        accountContacts: () => translate('contactsPro.contactTitle.accountContacts'),
        adSubmissions: () => translate('contactsPro.contactTitle.adSubmissions.' + realEstateAd.transactionType, {
            adResume: RealEstateAdTitleGenerator.getTitle(realEstateAd, 'adSubmission'),
        }),
        estimationRequests: () => translate('contactsPro.contactTitle.estimationRequests'),
        calls: () => {
            return translate(`contactsPro.contactTitle.calls.answered_${answered}`, {
                duration: formatDuration(duration),
                transactionType: transactionType || 'noTransactionType',
            });
        },
    };
    return titleByType[type]();
}

function formatRealEstateAd(realEstateAd) {
    return _.assignIn(VirtualTourHelper.enhanceAd(realEstateAd), {
        photos: _.map(realEstateAd.photos, photo => ({
            url: getImageUrlFromAlias(photo.photo),
        })),
    });
}

function formatDuration(duration) {
    return humanizeDuration(duration * 1000, {
        delimiter: ` ${translate('humanizeDuration.delimiter')} `,
        language: 'fr',
        largest: 2,
        round: true,
    });
}

/**
 * Contact might not contain any `sender` object, as some servers are flattening it. This helper extracts the 'sender' object
 * from the contact, handling any structure.
 */
function buildSenderFromContact(contact) {
    return _.extend(_.pick(contact, CONTACT_SENDER_FIELDS), contact.sender);
}
