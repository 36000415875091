module.exports = {
    authenticateWithFacebook,
    authenticateWithGoogle,
    authenticateWithApple,
};

function authenticateWithFacebook() {
    openAuth('facebook', 640, 348);
}

function authenticateWithGoogle() {
    openAuth('google', 640, 540);
}

function authenticateWithApple() {
    openAuth('apple', 770, 690);
}

function openAuth(name, width, height) {
    const left = (screen.width / 2) - (width / 2);
    const top = (screen.height / 2) - (height / 2);

    // initial popup URL on the same domain to workaround IE11 broken postMessage
    // see http://stackoverflow.com/a/25673084/14443
    const url = '/authenticate/' + name;

    window.open(url, 'authentication',
        'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left
        + ',menubar=no,toolbar=no,status=no,location=no,personalbar=no');
}
