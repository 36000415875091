const _ = require('lodash');

function Error404(message, data) {
    Error.call(this, message);
    if (_.isPlainObject(data)) {
        _.assign(this, data);
    }
    this.message = message;
    this.statusCode = 404;
    this.code = this.statusCode;
}

Error404.prototype = new Error();
Error404.prototype.constructor = Error404;

module.exports = Error404;
