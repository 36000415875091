const _ = require('lodash');
const PolygonZIndex = require('./utils/PolygonZIndex');
const {blue: BLUE} = require('./colors');

const BLUR_DEFAULT_TYPE = 'default';
const EXACT_RADIUS = 25;
const BLUR_TYPES = [];

module.exports = {
    hasExactPosition,
    getCircleRadius,
    hasDistrictPosition,
    getDistrictZoneName,
    getDisplayedPosition,
    BLUR_TYPES,
    BLUR_OVERLAY_OPTIONS: {
        strokeOpacity: 0,
        fillColor: BLUE,
        strokeColor: BLUE,
        strokeWeight: 0,
        fillOpacity: 0.3,
        isHole: false,
        disableZBuffer: true,
        clickable: false,
        zIndex: PolygonZIndex.zone,
    },
    BLUR_OVERLAY_KARTO_OPTIONS: {
        'fill-color': BLUE,
        'fill-opacity': 0.3,
        clickable: false,
        zIndex: PolygonZIndex.zone,
    },
    BLUR_OVERLAY_CITY_BLURRED_OPTIONS: {
        strokeOpacity: 1,
        strokeWeight: 2,
    },
    KARTO_BLUR_OVERLAY_CITY_BLURRED_OPTIONS: {
        'line-color': BLUE,
        'line-opacity': 1,
        'line-width': 2,
    },
    BLUR_DEFAULT_TYPE,
    getBlurTypeKeyFromBlurType,
};

BLUR_TYPES.push({
    key: BLUR_DEFAULT_TYPE,
    type: BLUR_DEFAULT_TYPE,
});

BLUR_TYPES.push({
    key: 'exactPosition',
    type: 'exact',
    radius: 50,
});

_.each([50, 100, 250, 500, 750, 1000], function (radius) {
    BLUR_TYPES.push({
        key: 'disk-' + radius,
        type: 'disk',
        radius,
    });
});

BLUR_TYPES.push({
    key: 'district',
    type: 'district',
});

BLUR_TYPES.push({
    key: 'cityOrArrondissement',
    type: 'cityOrArrondissement',
});

BLUR_TYPES.push({
    key: 'postalCode',
    type: 'postalCode',
});

function getDistrictZoneName(realEstateAd) {
    const district = realEstateAd.district;
    let searchLocation = district.name;
    const postalCode = district.postal_code;
    if (postalCode) {
        searchLocation += '-' + postalCode;
    }
    return searchLocation;
}

function hasDistrictPosition(realEstateAd) {
    return _.get(realEstateAd, 'blurInfo.type') === 'district';
}

function hasExactPosition(realEstateAd) {
    return _.get(realEstateAd, 'blurInfo.type') === 'exact';
}

function getCircleRadius(blurInfo) {
    return blurInfo.type == 'exact' ? EXACT_RADIUS : blurInfo.radius;
}

function getDisplayedPosition(realEstateAd) {
    let position;
    if (realEstateAd.blurInfo) {
        position = realEstateAd.blurInfo.position;
    }
    const {position: realEstateAdPosition} = realEstateAd;
    if (realEstateAdPosition) {
        position = realEstateAdPosition;
    }
    return position;
}

function getBlurTypeKeyFromBlurType(blurTypeObject) {
    return _.get(_.find(BLUR_TYPES, blurTypeObject), 'key', BLUR_DEFAULT_TYPE);
}
