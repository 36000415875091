const _ = require('lodash');
const $ = require('jquery');
const template = require('./PoisInterface.jade');
const agencyPoiContentTemplate = require('./AgencyPoiContent.jade');
const KimonoPoiCategory = require('./PoiCategory');
const i18nMixin = require('../../vue/components/mixins/i18n');
const AgencyPoisHelper = require('../../utils/AgencyPoisHelper');
const {initializeAgencyMarker} = require('../../poi/AgencyPoi');
const PoiInterface = require('../../poi/PoiInterface');
const karto = require('../../loadKarto');

// @vue/component
module.exports = {
    components: {
        KimonoPoiCategory,
    },
    mixins: [
        i18nMixin({
            prefix: 'PoisInterface.',
            keys: [
                'pois',
                'networks',
            ],
        }),
    ],
    props: {
        map: {
            type: Object,
            default: null,
        },
        enablePoiAgencies: {
            type: Boolean,
        },
        enableTransportLines: {
            type: Boolean,
        },
    },
    data() {
        return {
            dropdownOpen: false,
            isAgencyPoiHovered: false,
            isAgencyPoiClicked: false,
            poiCategories: [
                {
                    id: 'outing',
                    label: 'outings',
                    color: '#f07eb8',
                    classes: ['dog_park', 'campsite', 'tennis', 'zoo', 'music', 'theatre', 'cinema', 'monument',
                        'museum', 'library', 'stadium', 'golf', 'lodging', 'picnic_site', 'pitch', 'playground',
                        'attraction', 'park', 'outing', 'fast_food', 'restaurant', 'cafe', 'beer', 'art_gallery',
                        'bar', 'ice_cream', 'theatre', 'castle', 'place_of_worship', 'sports_centre',
                    ],
                    selected: true,
                },
                {
                    id: 'shop',
                    label: 'shopsAndServices',
                    color: '#fdb82a',
                    classes: ['shop', 'clothing_store', 'hairdresser', 'butcher', 'grocery', 'bakery',
                        'alcohol_shop', 'post', 'atm', 'bank', 'laundry',
                    ],
                    selected: true,
                },
                {
                    id: 'school',
                    label: 'schools',
                    color: '#a18565',
                    classes: ['school', 'college'],
                    selected: true,
                },
                {
                    id: 'health',
                    label: 'health',
                    color: '#43b36e',
                    classes: ['hospital', 'pharmacy', 'veterinary', 'dentist', 'doctors'],
                    selected: true,
                },
                {
                    id: 'transport',
                    label: 'transports',
                    color: '#0ea4cd',
                    classes: ['bus', 'entrance', 'car', 'harbor', 'railway', 'bicycle_rental',
                        'bicycle', 'parking', 'fuel', 'charging_station', 'car_rental',
                    ],
                    selected: true,
                },
                {
                    id: 'always',
                    classes: ['town_hall', 'police'],
                    selected: true,
                    hidden: true,
                },
            ],
            agencyPoi: {
                label: this.t('agencies'),
                color: '#495960',
                selected: true,
            },
            transportCategories: [
                {
                    id: 'bus',
                    label: 'bus',
                    class: 'BUS',
                },
                {
                    id: 'subway',
                    label: 'subway',
                    class: 'SUBWAY',
                },
                {
                    id: 'train',
                    label: 'train',
                    class: 'TRAIN',
                },
                {
                    id: 'tramway',
                    label: 'tramway',
                    class: 'TRAM',
                },
            ],
        };
    },
    computed: {
        poiClasses() {
            return _.map(
                _.filter(this.poiCategories, category => !category.hidden),
                category => ({...category, label: this.t(category.label)})
            );
        },
        transportLines() {
            return _.map(
                this.transportCategories,
                category => ({...category, label: this.t(category.label), selected: this.enableTransportLines})
            );
        },
    },
    mounted() {
        document.addEventListener('click', this.closeDropdownOnClickOutside);
        if (this.enablePoiAgencies) {
            this.agencyPoiHelper = AgencyPoisHelper.getInstance(this.map);
            this.agencyPoiHelper.displayAgencyPoisPerTile();
        }
        karto.agencyPoi = null;
        this.displayTransportLines();
        this.map.on('selection', (event) => {
            if (event.type == 'Marker3D' && !event.result.zoneByTimeMarker && !event.result.ad) {
                if (karto.agencyPoi) {
                    karto.agencyPoi.close();
                }
                karto.agencyPoi = event.result.getInfoWindow();
                this.isAgencyPoiHovered = false;
                this.isAgencyPoiClicked = true;
                if (karto.agencyPoi) {
                    this.setInfoWindowCloseButton(karto.agencyPoi, true);
                    karto.agencyPoi = this.displayAgencyPoi(event, karto.agencyPoi);
                    karto.agencyPoi.on('click_close', () => {
                        this.isAgencyPoiClicked = false;
                    });
                }
            }
        });
        this.map.on('mousein', (event) => {
            if (event.type == 'Marker3D' && (!karto.agencyPoi || !this.isAgencyPoiClicked)
                && !event.result.zoneByTimeMarker && !event.result.ad
            ) {
                karto.agencyPoi = event.result.getInfoWindow();
                this.isAgencyPoiHovered = true;
                this.isAgencyPoiClicked = false;
                if (karto.agencyPoi) {
                    this.setInfoWindowCloseButton(karto.agencyPoi, false);
                    karto.agencyPoi = this.displayAgencyPoi(event, karto.agencyPoi);
                }
            }
        });
        this.map.on('click', () => {
            if (karto.agencyPoi) {
                this.isAgencyPoiClicked = false;
                karto.agencyPoi.close();
            }
        });
        this.map.on('mouseout', () => {
            if (this.isAgencyPoiHovered && karto.agencyPoi) {
                karto.agencyPoi.close();
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
        if (karto.agencyPoi) {
            karto.agencyPoi.close();
            karto.agencyPoi = null;
        }
        this.isAgencyPoiHovered = false;
        this.isAgencyPoiClicked = false;
    },
    methods: {
        onSelectChange({value, id, mainCategory, mainClass, classes}) {
            if (mainCategory === 'agencyPoi') {
                this.agencyPoi.selected = value;
            } else if (mainCategory === 'poi') {
                this.updateCategorySelection(this.poiClasses, id, value);
            } else if (mainCategory === 'transport') {
                this.updateCategorySelection(this.transportLines, id, value);
            }
            this.updateMap({value, mainCategory, mainClass, classes});
        },
        updateMap({value, mainCategory, mainClass, classes}) {
            if (value) {
                this.displayPOIs(mainCategory, mainClass, classes);
            } else {
                this.clearPOIs(mainCategory, mainClass, classes);
            }
        },
        updateCategorySelection(categories, id, value) {
            const index = _.findIndex(categories, {id});
            if (index !== -1) {
                _.assign(categories[index], {selected: value});
            }
        },
        displayPOIs(mainCategory, mainClass, classes) {
            if (mainCategory === 'transport') {
                this.map.getPublicTransportManager().show([kartoEngine.PublicTransportLayers[mainClass]]);
            } else if (mainCategory === 'agencyPoi') {
                this.agencyPoiHelper.displayAgencyPoisPerTile();
            } else {
                const poiManager = this.map.getPOIManager();
                poiManager.enableClasses(classes);
            }
        },
        clearPOIs(mainCategory, mainClass, classes) {
            if (mainCategory === 'transport') {
                this.map.getPublicTransportManager().hide([kartoEngine.PublicTransportLayers[mainClass]]);
            } else if (mainCategory === 'agencyPoi') {
                this.agencyPoiHelper.disableAgencyPois();
            } else {
                const poiManager = this.map.getPOIManager();
                poiManager.disableClasses(classes);
            }
        },
        toggleDropdown() {
            $('.karto-pois-interface').css('z-index', '');
            this.dropdownOpen = !this.dropdownOpen;
        },
        closeDropdownOnClickOutside(event) {
            if (this.dropdownOpen && !this.$refs.dropdown.contains(event.target)) {
                this.dropdownOpen = false;
            }
        },
        displayAgencyPoi(event, infowindow) {
            if (infowindow) {
                infowindow.setContent(agencyPoiContentTemplate());
                PoiInterface.openAgencyPoiInfo(event.result, infowindow, initializeAgencyMarker);
                return infowindow;
            }
        },
        displayTransportLines() {
            if (this.enableTransportLines) {
                _.forEach(this.transportLines, (line) => {
                    this.map.getPublicTransportManager().show([kartoEngine.PublicTransportLayers[line.class]]);
                });
            }
        },
        setInfoWindowCloseButton(infowindow, value) {
            infowindow.setCloseButton(value);
        },
    },
    template: template(),
};
