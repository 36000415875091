const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const FILE_UPLOADER_KEY = 'fileUploader.dropzone';
const DEFAULT_ERROR_WITH_STATUS_KEY = 'responseError';
const DEFAULT_ERROR_KEY = 'defaultErrorMessage';

module.exports = {
    getDefaultError,
    getTranslatedError,
};

function getDefaultError(translationKey, xhr) {
    let defaultValue = translate(`${FILE_UPLOADER_KEY}.${translationKey}.${DEFAULT_ERROR_KEY}`, {
        defaultValue: translate(`${FILE_UPLOADER_KEY}.default.${DEFAULT_ERROR_KEY}`),
    });

    if (_.get(xhr, 'status')) {
        defaultValue = translate(`${FILE_UPLOADER_KEY}.${translationKey}.${DEFAULT_ERROR_WITH_STATUS_KEY}`, {
            statusCode: xhr.status,
            defaultValue: translate(`${FILE_UPLOADER_KEY}.default.${DEFAULT_ERROR_WITH_STATUS_KEY}`),
        });
    }

    return defaultValue;
}

function getTranslatedError(translationKey, message, defaultValue) {
    return translate(`${FILE_UPLOADER_KEY}.${translationKey}.${message}`, {
        defaultValue,
    });
}
